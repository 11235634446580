import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'stepper-form-header',
  templateUrl: './stepper-form-header.component.html',
  styleUrls: ['./stepper-form-header.component.scss'],
})
export class StepperFormHeaderComponent implements OnInit {

  @Input() steps: string[];
  @Input() initialStep: number;
  @Input() currentStep: string;
  @Input() stepsValidated: boolean[];
  @Input() options: any = {
    mode: 0,
    subform: 0,
  };

  constructor() {}
  
  ngOnInit() {
  }

}
