import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Injector, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { BasePage } from "src/app/pages/base-page/base-page";

@Component({
  selector: "app-payment",
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ transform: "translateY(300%)", opacity: 0 }),
        animate(
          "1s ease-in",
          style({ transform: "translateY(0)", opacity: 1 })
        ),
      ]),
      transition(":leave", [
        style({ transform: "translateY(0)", opacity: 1 }),
        animate(
          "0.7s ease-in-out",
          style({ transform: "translateY(300%)", opacity: 0 })
        ),
      ]),
    ]),
  ],
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent extends BasePage implements OnInit {
  @Input() costo: any;

  enableMenuSwipe(): boolean {
    throw new Error("Method not implemented.");
  }

  constructor(injectable: Injector, public modalCtrl: ModalController) {
    super(injectable);
  }
  //Status notUploaded/uploaded/invalid
  public fileStatus = "notUploaded";
  public file;

  ngOnInit() {}

  uploadFile() {
    this.modalCtrl.dismiss({ file: this.file });
  }

  async onFileSelected($event: any): Promise<void> {
    if ($event.target.files.length > 0) {
      const file: File = $event.target.files[0];
      let compressedBase64: string | ArrayBuffer = '';
  
      if (
        file.type === "application/pdf" || // Si es un archivo PDF
        file.type === "image/png" ||        // Si es una imagen PNG
        file.type === "image/jpeg"          // Si es una imagen JPEG
      ) {
        // Comprimir la imagen si es una imagen (PNG o JPEG)
        if (file.type.startsWith('image/')) {
          compressedBase64 = await this.compressImage(file);
        } else {
          // Si es un archivo PDF, simplemente lo convertimos a base64
          compressedBase64 = await this.readFileAsBase64(file);
        }
  
        this.fileStatus = "uploaded";
        this.file = compressedBase64;
      } else {
        this.fileStatus = "invalid";
      }
    }
  }
  
  // Método para comprimir una imagen
  async compressImage(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result as string;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 600;
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
  
          ctx.drawImage(img, 0, 0, width, height);
  
          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.7); // 0.7 is the image quality
  
          resolve(compressedBase64);
        };
      };
  
      reader.readAsDataURL(file);
    });
  }
  
  // Método para leer un archivo como base64
  async readFileAsBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        resolve(reader.result as string);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(file);
    });
  }
}
