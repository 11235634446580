import {
  Component,
  OnInit,
  Injector,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { BasePage } from "src/app/pages/base-page/base-page";

@Component({
  selector: "one-file-upload",
  templateUrl: "./one-file-upload.component.html",
  styleUrls: ["./one-file-upload.component.scss"],
})
export class OneFileUploadComponent extends BasePage implements OnInit {
  @ViewChild("fileInput", { static: true }) input: ElementRef;
  @ViewChild("fileInput", { static: false })
  fileInput: ElementRef;
  @ViewChild("fileInputHidden", { static: false }) fileInputHidden: ElementRef;

  @Output() filesSelected = new EventEmitter<File>();

  public file;
  enableMenuSwipe(): boolean {
    return true;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {}

  public onClick() {
    this.input.nativeElement.click();
  }

  onFileInputInteraction(event: any): void {
    const data = event.target.files[0];

    this.filesSelected.emit(data);
    this.file = data;
  }

  removeFile(): void {
    this.file = "";
    this.filesSelected.emit(this.file);
  }
}
