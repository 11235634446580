import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, formatDate } from "@angular/common";
import {
  questions2,
  questions2FormGroup,
  step2Form,
} from "src/app/utils/statics/dl-expediente";
import { BasePage } from "src/app/pages/base-page/base-page";
@Component({
  selector: "step2",
  templateUrl: "./step2.component.html",
  styleUrls: ["./step2.component.scss"],
  providers: [DatePipe],
})
export class Step2Component extends BasePage implements OnInit {
  enableMenuSwipe(): boolean {
    throw new Error("Method not implemented.");
  }
  @Input() form: any;
  @Input() status: string;
  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onResize = new EventEmitter<any>();
  public step2Form: FormGroup;
  public currentDate = new Date();
  questions2 = questions2;
  questions2FormGroup = questions2FormGroup;

  constructor(
    injector: Injector,
    public fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    super(injector);
  }

  ngOnInit() {
    this.step2Form = this.fb.group({
      QuestionC_A: [false, [Validators.required]],
      QuestionC_B: [false, [Validators.required]],
      QuestionC_C: [false, [Validators.required]],
      QuestionC_D: [false, [Validators.required]],
      QuestionC_E: [false, [Validators.required]],
      QuestionC_F: [false, [Validators.required]],
      QuestionC_G: [false, [Validators.required]],
      QuestionC_H: [false, [Validators.required]],
      QuestionC_I: [false, [Validators.required]],
      QuestionC_J: [false, [Validators.required]],
      QuestionC_K: [false, [Validators.required]],
      QuestionC_L: [false, [Validators.required]],
      QuestionC_M: [false, [Validators.required]],
      QuestionC_N: [false, [Validators.required]],
      additional_details: [""],
      Question_E: ["", [Validators.required]],
      Question_E_Details: [""],
      Question_F: ["", [Validators.required]],
    });

    if (
      this.status == "inProgress" &&
      this.form?.attributes?.healthData?.step2
    ) {
      this.setStep2Form();
    }
  }

  public previousStep() {
    this.onPreviousStep.emit({
      index: 1,
    });
    this.onResize.emit();
  }

  public validateForm() {
    console.log(this.step2Form);
    this.step2Form.markAllAsTouched();
    this.onResize.emit();
    let validationResult = this.step2Form.valid;
    this.onValidation.emit({
      index: 1,
      validationResult: validationResult,
      form: this.step2Form.value,
    });
    this.onResize.emit();
  }

  public setStep2Form() {
    for (let j = 0; j < step2Form.length; j++) {
      this.step2Form
        .get([step2Form[j]])
        .setValue(this.form.attributes.healthData.step2[step2Form[j]]);
    }
  }

  public isFieldInvalid(field: string) {
    return (
      this.step2Form.get(field)?.touched && this.step2Form.get(field)?.invalid
    );
  }
}
