import { Injectable } from "@angular/core";
import * as Parse from "parse";

@Injectable({
  providedIn: "root",
})
export class User extends Parse.User {
  constructor() {
    super();
  }

  static getInstance() {
    return this;
  }

  load(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      const query = new Parse.Query(User);
      query.equalTo("status", "Active");
      query.ascending("sort");
      query.doesNotExist("deletedAt");
      query.find().then(
        (data: User[]) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  loadOne(params): Promise<User[]> {
    console.log(params.id);

    return new Promise((resolve, reject) => {
      let query = new Parse.Query(User);
      query.equalTo("status", "Active");
      query.ascending("sort");
      query.doesNotExist("deletedAt");
      query.equalTo("objectId", params.id);
      query.find().then(
        (data: User[]) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  checkUserDependencies(params) {
    return Parse.Cloud.run("checkUserDependencies", params);
  }

  changeUserStatus(params) {
    return Parse.Cloud.run("changeUserStatus", params);
  }

  updateUserSimulator(params) {
    return Parse.Cloud.run("updateUser", params);
  }

  updateUserProgress(params) {
    return Parse.Cloud.run("updateUserProgress", params);
  }

  delete(data) {
    return Parse.Cloud.run("destroyUser", data);
  }

  static getCurrent() {
    return User.current() as User;
  }

  getOneUser(params) {
    return Parse.Cloud.run("getOneUser", params);
  }

  getOneUserEntity(params: any = {}): Promise<Parse.User[]> {
    return Parse.Cloud.run("getOneUserEntity", params);
  }

  isLoggedInViaFacebook() {
    return this.authData && this.authData.facebook;
  }

  async create(data: any = {}): Promise<User> {
    let study = undefined;
    if (
      JSON.stringify(await Parse.Cloud.run("get1Study", { email: data.email }))
    ) {
      study = JSON.parse(
        JSON.stringify(
          await Parse.Cloud.run("get1Study", { email: data.email })
        )
      );
    }

    const user = new User();
    user.set("username", data.username);
    user.set("name", data.name);
    user.set("lastname1", data.lastname1);
    user.set("lastname2", data.lastname2);
    user.set("phone", data.phone);
    user.set("cellphone", data.cellphone);
    user.set("email", data.email);
    user.set("property_cost", data.property_cost);
    user.set("password", data.password);
    user.set("status", "Approved");
    user.set("type", "customer");
    user.set("profile", "customer");

    if (study && study.progress) user.set("progress", study.progress);
    else user.set("progress", data.progress);

    if (study && study.reference) user.set("reference", study.reference);
    else user.set("reference", data.reference);

    if (study && study.reference2) user.set("reference2", study.reference2);
    else user.set("reference2", data.reference2);

    if (study && study.entitySocio && study.entitySocio != "calculator")
      user.set("entity", study.entitySocio);
    else user.set("entity", data.entity);

    if (study && study.iddevelopment)
      user.set("iddevelopment", study.iddevelopment);
    else user.set("iddevelopment", data.iddevelopment);

    if (study && study.fileIntegrationStatus)
      user.set("fileIntegrationStatus", study.fileIntegrationStatus);
    else user.set("fileIntegrationStatus", data.fileIntegrationStatus);

    if (study && study.developmentName)
      user.set("developmentName", study.developmentName);
    else user.set("developmentName", data.developmentName);

    await user.signUp();
    await Parse.Cloud.run("updateStudyByEmail", {
      user: user.id,
      email: data.email,
    });

    return user;
  }

  createNew(data: any = {}): Promise<User> {
    return Parse.Cloud.run("createUser", data);
  }

  sendEmail(data: any = {}) {
    return Parse.Cloud.run("sendEmail", data);
  }

  sendEmailNewCustomerCreatedByVendor(data: any = {}) {
    console.log("is in sendEmailNewCustomerCreatedByVendor");

    return Parse.Cloud.run("sendEmailNewCustomerCreatedByVendor", data);
  }

  signIn(data: any = {}): Promise<User> {
    const user = new User();
    user.username = data.username;
    user.password = data.password;
    return user.logIn();
  }

  logout() {
    return User.logOut();
  }

  recoverPassword(email: string) {
    return User.requestPasswordReset(email);
  }

  recoveryPassByEmail(params: any) {
    return Parse.Cloud.run("requestPasswordResetEmail", params);
  }

  verifyEmailandUserName(params: any) {
    return Parse.Cloud.run("verifyEmailandUserName", params);
  }

  GetUserByUserByEmail(params: any) {
    return Parse.Cloud.run("GetUserByUserByEmail", params);
  }

  getSuper() {
    return Parse.Cloud.run("getSuper");
  }

  loginViaFacebook(): Promise<User> {
    return new Promise((resolve, reject) => {
      (Parse.FacebookUtils.logIn(null) as any).then(
        (user: User) => resolve(user),
        (err: any) => reject(err)
      );
    });
  }

  loginWith(
    provider: string,
    authData: any = {},
    extraData: any = {}
  ): Promise<User> {
    const user: any = new User();
    user.set(extraData);
    return user._linkWith(provider, authData);
  }

  isFacebookLinked(): boolean {
    return Parse.FacebookUtils.isLinked(Parse.User.current());
  }

  linkFacebook(authData: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Parse.FacebookUtils.link(Parse.User.current(), authData, {
        success: (res: any) => resolve(res),
        error: (err: any) => reject(err),
      });
    });
  }

  unlinkFacebook(): Promise<any> {
    return new Promise((resolve, reject) => {
      Parse.FacebookUtils.unlink(Parse.User.current(), {
        success: (res: any) => resolve(res),
        error: (err: any) => reject(err),
      });
    });
  }

  get name(): string {
    return this.get("name");
  }

  set name(val) {
    this.set("name", val);
  }

  get email(): string {
    return this.get("email");
  }

  set email(val) {
    this.set("email", val);
  }

  get username(): string {
    return this.get("username");
  }

  set username(val) {
    this.set("username", val);
  }

  get status(): string {
    return this.get("status");
  }

  set status(val) {
    this.set("status", val);
  }

  get type(): string {
    return this.get("type");
  }

  set type(val) {
    this.set("type", val);
  }

  get password(): string {
    return this.get("password");
  }

  set password(val) {
    this.set("password", val);
  }

  get reference(): any {
    return this.get("reference");
  }

  set reference(val) {
    this.set("reference", val);
  }

  get phone(): string {
    return this.get("phone");
  }

  set phone(val) {
    this.set("phone", val);
  }

  get photo(): any {
    return this.get("photo");
  }

  set photo(val) {
    this.set("photo", val);
  }

  get profile(): any {
    return this.get("profile");
  }

  set profile(val) {
    this.set("profile", val);
  }

  get entity(): any {
    return this.get("entity");
  }

  set entity(val) {
    this.set("entity", val);
  }

  get key(): any {
    return this.get("key");
  }

  set key(val) {
    this.set("key", val);
  }
  get lastname1(): any {
    return this.get("lastname1");
  }

  set lastname1(val) {
    this.set("lastname1", val);
  }

  get lastname2(): any {
    return this.get("lastname2");
  }

  set lastname2(val) {
    this.set("lastname2", val);
  }

  get fileIntegrationStatus(): any {
    return this.get("fileIntegrationStatus");
  }

  set fileIntegrationStatus(val) {
    this.set("fileIntegrationStatus", val);
  }

  get authData(): any {
    return this.get("authData");
  }

  set authData(val) {
    this.set("authData", val);
  }

  get iddevelopment(): any {
    return this.get("iddevelopment");
  }

  set iddevelopment(val) {
    this.set("iddevelopment", val);
  }

  get developmentName(): any {
    return this.get("developmentName");
  }

  set developmentName(val) {
    this.set("developmentName", val);
  }

  get businessname(): any {
    return this.get("businessname");
  }

  set businessname(val) {
    this.set("businessname", val);
  }

  get cellphone(): any {
    return this.get("cellphone");
  }

  set cellphone(val) {
    this.set("cellphone", val);
  }

  get tradename(): any {
    return this.get("tradename");
  }

  set tradename(val) {
    this.set("tradename", val);
  }

  get progress(): any {
    return this.get("progress");
  }

  set progress(val) {
    this.set("progress", val);
  }

  get property_cost(): any {
    return this.get("property_cost");
  }

  set property_cost(val) {
    this.set("property_cost", val);
  }

  get reference2(): any {
    return this.get("reference2");
  }

  set reference2(val) {
    this.set("reference2", val);
  }
}

Parse.Object.registerSubclass("_User", User);
