import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { Platform, ActionSheetController, AlertController } from '@ionic/angular';
import { CameraOptions, Camera } from '@ionic-native/camera/ngx';
import { ParseFile } from 'src/app/services/parse-file-service';
import { TranslateService } from '@ngx-translate/core';
import Swal from "sweetalert2";

@Component({
  selector: 'app-upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss'],
})
export class UploadDocComponent implements OnInit {

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  @Input() text: string;
  @Input() type: string;
  @Input() index: any;
  @Input() document: any;

  @Output('onFileUploaded')

  private eventFileUpload: EventEmitter<any> = new EventEmitter<any>();
  
  public parseFile: any;
  public id:any;
  public doc:any = {
    file: '',
    comment:'',
    status:'',
    type:'',
    name:'',
  }
  public isUploading: boolean = false;

  constructor(private platform: Platform,
    private camera: Camera,
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private translate: TranslateService) { }

  ngOnInit() {
    if(this.document){
      this.doc = {
        file: this.document.file,
        comment:this.document.comment,
        status:this.document.status,
        type:this.document.type,
        name:this.document.name,
      }
      this.parseFile = this.document.file;
    }
  }

  onBoxTouched() {
    if (this.platform.is('cordova')) {
      // this.presentActionSheet();
      this.fileInput.nativeElement.click();
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  async onRemove() {

    const trans = 'CONFIRM_DELETE_DOCUMENT';
    const message = await this.translate.get(trans).toPromise();

    const confirm = await this.showConfirm(message);

    if (confirm) {
      this.parseFile = null
      this.doc = {
        file:'',
        comment:'',
        status:'',
        type:'',
        name:'',
      }
      this.isUploading = false;
      this.eventFileUpload.emit(null);
    }
  }

  showConfirm(message: string): Promise<boolean> {

    return new Promise(async (resolve) => {

      const str = await this.translate.get(['OK', 'CANCEL'])
        .toPromise();

      const confirm = await this.alertCtrl.create({
        header: '',
        message: message,
        buttons: [{
          text: str.CANCEL,
          role: 'cancel',
          handler: () => resolve(false)
        }, {
          text: str.OK,
          handler: () => resolve(true)
        }],
        cssClass:'buttons-alert'
      });

      confirm.present();

    });

  }

  async onFileChanged(event: any = null) {
    if(event.target.files){
      const maxSize = 41943040;
      if (event.target.files[0].size > maxSize) {
        const trans = await this.getTrans(["OK", "FILE_MAX_SIZE"]);
        Swal.fire({
          title: "Error",
          text: trans.FILE_MAX_SIZE,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: trans.OK,
          heightAuto: false,
          customClass: {
            popup: "fade-in",
          },
        });
      } else {
        let array = event.target.files[0].type.split("/");
        let type = array[1];
        this.doUpload(event.target.files[0], false, type);
      }
    }
  }

  getTrans(key: string | string[], params: any = {}) {
    return this.translate.get(key, params).toPromise();
  }

  async chooseImage(sourceType: number) {

    try {
      const options: CameraOptions = {
        sourceType: sourceType,
        destinationType: this.camera.DestinationType.DATA_URL,
        targetWidth: 1000,
        targetHeight: 1000,
        quality: 70,
        mediaType: this.camera.MediaType.PICTURE,
        encodingType: this.camera.EncodingType.JPEG,
        correctOrientation: true
      }

      const imageData = await this.camera.getPicture(options);

      this.doUpload(imageData);

    } catch (error) {
      console.warn(error);
    }

  }

  async presentActionSheet() {

    const trans = await this.translate.get([
      'PHOTO_LIBRARY',
      'CAMERA',
      'CANCEL',
      'CHOOSE_AN_OPTION']
    ).toPromise();

    const actionSheet = await this.actionSheetCtrl.create({
      header: trans.CHOOSE_AN_OPTION,
      buttons: [{
        text: trans.PHOTO_LIBRARY,
        handler: () => {
          this.chooseImage(this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      }, {
        text: trans.CAMERA,
        handler: () => {
          this.chooseImage(this.camera.PictureSourceType.CAMERA);
        }
      },{
        text: trans.CANCEL,
        role: 'cancel'
      }]
    });

    return await actionSheet.present();

  }

  async doUpload(fileOrBase64: string,isBase64: boolean = true,type: string = "") {
    try {
      this.isUploading = true;
      if (type !== "") {
        if (type === "pdf") {
          this.parseFile = await ParseFile.uploadPDF(fileOrBase64, isBase64);
        } else {
          this.parseFile = await ParseFile.upload(fileOrBase64, isBase64);
        }
      } else {
        this.parseFile = await ParseFile.upload(fileOrBase64, isBase64);
      }

      this.doc.file = this.parseFile;
      this.doc.type = this.type;
      this.doc.status = 'Pending';

      this.isUploading = false;
      const send = {doc:this.doc,index:this.index};
      this.eventFileUpload.emit(send);
    } catch (error) {
      this.isUploading = false;
      console.warn(error.message);
    }
  }

  seeDoc(){
    window.open(this.parseFile?.url(),'_system', 'location=yes')
  }
}
