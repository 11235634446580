export const environment = {
  // production: false,
  // serverUrl: 'http://localhost:1337/api',
  // serverHost: 'http://localhost:1337',
  // appUrl: 'https://trynearme.app',
  // appImageUrl: 'https://trynearme.app/assets/img/nearme.png',
  // appId: 'T8C8jc98CS',
  // fbId: 'YOUR_FB_ID',

  production: false,
  urlBaseContrating: "https://api-contratacion.bprcasa.com/",
  appUrlDoc: "https://www.hipotk.com/",

  serverUrl: "https://adm.hipotk.com/api",
  appUrl: "https://www.hipotk.com",
  appImageUrl: "https://www.hipotk.com/assets/img/hipotk_logo.webp",
  appId: "T8C8jc98CS",
  fbId: "800613777248816",
  serverHost: "https://adm.hipotk.com",

  //hipotk api key    AIzaSyCOm4UNWiDTjXLcMDvc-6RxvyXf9twCaig
  googleMapsApiKey: "AIzaSyCOm4UNWiDTjXLcMDvc-6RxvyXf9twCaig",
  androidHeaderColor: "#008f8d",
  defaultUnit: "km",
  envIncode: {
    apiURL: "https://demo-api.incodesmile.com/",
    apiKey: "8cd28279729bc969e6fd1a49cf545567cc79a241",
  },
  defaultLang: "es",
  googleClientId:
    "908355473877-t5irrvvvb5pgi1b1a1mar9auqsp8g7sk.apps.googleusercontent.com",
  stripePublicKey: "YOUR_STRIPE_PUBLIC_KEY",
  oneSignal: {
    appId: "YOUR_ONESIGNAL_APP_ID",
    googleProjectNumber: "YOUR_GOOGLE_PROJECT_NUMBER",
  },
  currency: {
    code: "USD",
    display: "symbol",
    digitsInfo: "1.0-0",
  },
  admob: {
    banner: {
      android: "ca-app-pub-xxxxxxxxxxxxxxxxxxxx",
      ios: "ca-app-pub-xxxxxxxxxxxxxxxxxxxx",
    },
  },
  credencialesLogin: {
    username: "apicontratacion@hipotk.com",
    password: "k3hCV2#Nq0^ccYDJ",
  },
};
