import { Component, Injector, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { BasePage } from "src/app/pages/base-page/base-page";

@Component({
  selector: "app-categories-menu",
  templateUrl: "./categories-menu.component.html",
  styleUrls: ["./categories-menu.component.scss"],
})
export class CategoriesMenuComponent extends BasePage implements OnInit {
  @Input() categories: any[];
  public traslate;
  public ruta: any;

  constructor(
    public popoverController: PopoverController,

    injector: Injector
  ) {
    super(injector);
  }
  enableMenuSwipe() {
    return true;
  }
  close: boolean = true;
  handleTabClickTag(event: Event, title: any, id: any, slug: any) {
    console.log("Title:", title);
    console.log("ID:", id);
    console.log("Slug:", slug);
    this.dataLayer(id.toString(), title, "Header - Menú", "Click");
    // this.navigateTo(`${this.ruta}${slug}`);
  }
  ngOnInit() {
    console.log(localStorage.getItem("language"));
    this.traslate = localStorage.getItem("language");
    this.ruta = this.traslate == "en" ? "./en/" : "./";
    setTimeout(() => {
      if (this.close == true) this.cerrarMenuDesplegable();
    }, 350);
  }

  dontClose() {
    this.close = false;
    this.traslate = localStorage.getItem("language");
    this.ruta = this.traslate == "en" ? "./en/" : "./";
  }

  cerrarMenuDesplegable() {
    document.getElementById("menu").classList.add("hide");
    this.traslate = localStorage.getItem("language");
    this.ruta = this.traslate == "en" ? "./en/" : "./";
    setTimeout(() => {
      this.popoverController.dismiss();
    }, 100);
  }
}
