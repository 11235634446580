import { FormArray, FormGroup } from "@angular/forms";

export default abstract class FormUtils {
    public static isFieldInFormGroupInvalid( formGroup: FormGroup, field: string ) {
        return ( formGroup.get(field).touched || formGroup.get(field).dirty ) && formGroup.get(field).invalid;
    }

    public static sumFormArrayNumbers( formGroup: FormArray, fieldName: string ): number {
        let sum = 0;
    
        formGroup.controls.forEach(control => {
            sum += control.get(fieldName).value;
        });
    
        return sum;
    }

    public static sumFormGroupNumbers( formGroupControl: {[key: string]: any} ): number {
        let sum: number = 0;
        
        Object.keys( formGroupControl ).forEach(key => {
            sum += formGroupControl[key];
        });

        return sum;
    }
}