import { Component, OnInit, Input } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { Email } from "src/app/services/email-service";
import Swal from "sweetalert2";
import { User } from "src/app/services/user-service";
import { Users } from "src/app/services/users-service";
import { SocioEconomicStudy } from "../../services/estudio-se-service";
import { TranslateService } from "@ngx-translate/core";
import { Place } from "../../services/place-service";
@Component({
  selector: "app-contact",
  templateUrl: "./contact.page.html",
  styleUrls: ["./contact.page.scss"],
})
export class ContactPage implements OnInit {
  @Input() place: any;
  @Input() price: any;
  @Input() slug: any;
  @Input() category: any;
  @Input() placedata: any;
  @Input() idPlace: any;

  formContact: FormGroup;
  public user: any;
  public adminEmail: string;
  public superID: string;
  public superUser: string = "contact";
  private Suser: any;

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private email: Email,
    private modalController: ModalController,
    private userServices: User,
    private usersServices: Users,
    private socioEconomicStudy: SocioEconomicStudy,
    private placeService: Place
  ) {
    this.formContact = this.formBuilder.group({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "[a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}$"
        ),
      ]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
        Validators.maxLength(10),
      ]),
      cel: new FormControl("", [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
        Validators.maxLength(10),
      ]),
      postalCode: new FormControl("", [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{5}$"),
        Validators.maxLength(5),
      ]),
      last_name: new FormControl("", [Validators.required]),
      last_name2: new FormControl("", [Validators.required]),
    });
  }

  async ngOnInit() {
    console.log("async ngOnInit");
    await this.placeService.setCountInformation(this.idPlace);

    // console.log(this.idPlace);
    this.user = User.getCurrent();

    this.Suser = await this.userServices.getSuper();
    console.log(this.Suser);

    // console.log(this.placedata);
    // console.log(this.place);
    if (this.user) {
      if (this.user.profile != "customer") {
        Swal.fire({
          position: "center",
          icon: "info",
          title: this.translate.instant("ACTIVE_SESSION_ADMIN"),
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dismiss();
        });
      } else if (this.user.profile == "customer" && this.user.entity) {
        // console.log(this.user);
        // this.formContact.controls['name'] = this.user.name;
        // this.formContact.controls['email'] = this.user.email;
        this.superID = this.user.entity;
      } else {
        this.superID = this.placedata.entity;
      }

      let id = {
        id: this.superID,
      };

      const result = await this.userServices.getOneUserEntity(id);
      const userdata: any = result[0];

      if (userdata) {
        this.adminEmail = userdata.email;
      } else {
        this.adminEmail = "error desarrolladora no encontrada";
      }
    } else {
      let id = {
        id: this.placedata.entity,
      };

      this.superID = this.placedata.entity;

      const result = await this.userServices.getOneUserEntity(id);
      const userdata: any = result[0];
      //console.log(userdata);
      setTimeout(() => {
        this.adminEmail = userdata.email;
      }, 500);
    }
    //console.log(this.placedata.developmentName);
  }

  nombreValido(campo: string) {
    return (
      this.formContact.controls[campo]?.errors &&
      this.formContact.controls[campo]?.touched
    );
  }

  async onSubmit() {
    if (this.formContact.invalid) {
      return;
    } else {
      await this.placeService.setCountInformation(this.idPlace);
      const formData = Object.assign({}, this.formContact.value);

      formData.name = formData.name.trim();
      formData.email = formData.email.trim();
      formData.phone = formData.phone.trim();
      formData.postalCode = formData.postalCode.trim();
      formData.price = this.price;
      formData.place = this.place.trim();
      formData.slug = this.slug.trim();
      formData.emailAdmin = this.adminEmail.trim();
      formData.entity = this.superID;
      formData.property_cost = Number(this.price);
      formData.entitySocio = this.superID.trim();
      formData.client_name = formData.name.trim();
      formData.user = "Contacto";
      formData.last_name = formData.last_name.trim();
      formData.last_name2 = formData.last_name2.trim();
      formData.developmentName = this.placedata.developmentName.trim();
      formData.title = this.placedata.title.trim();
      formData.progress = "-1";
      formData.cel = formData.cel.trim();
      formData.cellphone = formData.cel.trim();
      formData.iddevelopment = this.placedata.iddevelopment.trim();
      formData.entitySocio = this.placedata.entity.trim();

      // console.log('formData', formData);
      //console.log(formData);

      const user = this.superID.trim();

      const params = Object.assign({});

      params.name = formData.name.trim();
      params.email = formData.email.trim();
      params.phone = formData.phone.trim();
      params.postalCode = formData.postalCode.trim();
      params.price = this.price;
      params.place = this.place.trim();
      params.slug = this.slug.trim();
      params.emailAdmin = this.Suser.email.trim();
      params.entity = this.superID;
      params.property_cost = Number(this.price);
      params.entitySocio = this.superID.trim();
      params.client_name = formData.name.trim();
      params.last_name = formData.last_name.trim();
      params.last_name2 = formData.last_name2.trim();
      params.developmentName = this.placedata.developmentName.trim();
      params.progress = "-1";
      params.title = this.placedata.title.trim();
      params.cel = formData.cel.trim();
      params.iddevelopment = this.placedata.iddevelopment.trim();
      params.entitySocio = this.placedata.entity.trim();

      try {
        // Variables con el usuario o el lead en el que se encuentra el correo si es que existen
        const userInUsers = JSON.parse(
          JSON.stringify(
            await this.usersServices.loadOne({ email: params.email })
          )
        )[0];
        const userInSocio =
          await this.socioEconomicStudy.verifySocioEconomicStudyEmail(formData);

        // Si el correo es de un usuario no cliente no se puede continuar
        if (userInUsers && userInUsers.profile != "customer") {
          Swal.fire({
            position: "center",
            icon: "info",
            title: this.translate.instant("EMAIL_NOT_ALLOWED"),
            text: this.translate.instant("CANNOT_USE_THIS_EMAIL"),
          });
          this.dismiss();
        }
        // Si el correo válido avanza
        else {
          if (userInUsers && userInSocio) {
            // Cuando el correo existe tanto en un usuario como en un lead

            if (userInSocio.progress <= "-1") {
              // Si el lead del correo tiene un progress de Contacto o de Nuevo avanza

              if (this.user && this.user.id == userInUsers.objectId) {
                // La sesión iniciada es la misma que la del correo ingresado

                Swal.fire({
                  title: this.translate.instant("YOU_SURE"),
                  text: this.translate.instant("YOUR_PROCESS_WILL_BE_MODIFIED"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#008f8d",
                  cancelButtonColor: "#d33",
                  confirmButtonText: this.translate.instant("OK"),
                  allowOutsideClick: false,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    formData.user = userInUsers.objectId;

                    await this.userServices.updateUserProgress({
                      id: userInUsers.objectId,
                      progress: "-1",
                      entity: formData.entitySocio,
                      iddevelopment: formData.iddevelopment,
                      developmentName: formData.developmentName,
                    });
                    await this.socioEconomicStudy.updateByUserInCloud(formData);
                    await this.email.emailContact(formData);
                    await this.email.emailContact(params);

                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: this.translate.instant(
                        "APPLICATION_SENT_SUCCESSFULLY"
                      ),
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    this.dismiss();
                  }
                });
              } else {
                // No hay sesión o la sesión iniciada es distinta a la del correo

                Swal.fire({
                  position: "center",
                  icon: "info",
                  title: this.translate.instant("ALREADY_HAVE_A_PROCESS"),
                  text: this.translate.instant("GO_LOGIN_TO_MODIFY"),
                });
                this.dismiss();
              }
            } else {
              // Cuando el lead del correo tiene un progress mayor al de Contacto

              Swal.fire({
                position: "center",
                icon: "info",
                title: this.translate.instant("EMAIL_NOT_ALLOWED"),
                text: this.translate.instant(
                  "ALREADY_HAVE_AN_ADVANCED_PROCESS"
                ),
              });
              this.dismiss();
            }
          } else if (userInUsers) {
            // Cuando el correo solo existe en un usuario pero no en un lead
            console.log("entre eb useInUsers");
            formData.user = userInUsers.objectId;

            await this.userServices.updateUserProgress({
              id: userInUsers.objectId,
              progress: "-1",
              entity: formData.entitySocio,
              iddevelopment: formData.iddevelopment,
              developmentName: formData.developmentName,
            });
            await this.socioEconomicStudy.createInCloud(formData);
            await this.email.emailContact(formData);
            await this.email.emailContact(params);

            Swal.fire({
              position: "center",
              icon: "success",
              title: this.translate.instant("APPLICATION_SENT_SUCCESSFULLY"),
              showConfirmButton: false,
              timer: 1500,
            });
            this.dismiss();
          } else if (userInSocio) {
            // Cuando el correo solo existe en un lead pero no en un usuario

            if (userInSocio.progress <= "-1") {
              await this.socioEconomicStudy.updateByEmailInCloud(formData);
              await this.email.emailContact(formData);
              await this.email.emailContact(params);

              Swal.fire({
                position: "center",
                icon: "success",
                title: this.translate.instant("APPLICATION_SENT_SUCCESSFULLY"),
                showConfirmButton: false,
                timer: 1500,
              });
              this.dismiss();
            } else {
              Swal.fire({
                position: "center",
                icon: "info",
                title: this.translate.instant(
                  "ALREADY_HAVE_AN_ADVANCED_PROCESS"
                ),
                text: this.translate.instant("WILL_CONTACT_YOU_SOON"),
              });
              this.dismiss();
            }
          } else {
            // Cuando el correo no existe ni en un lead ni en un usuario se crea el nuevo lead

            await this.socioEconomicStudy.createInCloud(formData);
            await this.email.emailContact(formData);
            await this.email.emailContact(params);

            Swal.fire({
              position: "center",
              icon: "success",
              title: this.translate.instant("APPLICATION_SENT_SUCCESSFULLY"),
              showConfirmButton: false,
              timer: 1500,
            });
            this.dismiss();
          }
        }
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.translate.instant("SOMETHING_WENT_WRONG"),
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(error);
      }
      console.log("direct dismisseed");
      this.dismiss();
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
