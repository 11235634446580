import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { CookiesPoliciesComponent } from "src/app/pages/cookies-policies/cookies-policies.component";
import { WalkthroughPage } from '../../pages/walkthrough/walkthrough';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.scss'],
})
export class CookiesBannerComponent implements OnInit {

  dataLayer = [];

  backgroudCookies: any
  botonAceptarCookies: any
  avisoCookies: any

  public language = "";
  private loader: any;

  constructor(
    public modalController: ModalController, 
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private loadingCtrl: LoadingController) { }

  ngOnInit() {
    // this.language = localStorage.getItem("language");
    
    // this.backgroudCookies = document.getElementById("fondo-aviso-cookies")
    // this.botonAceptarCookies  = document.getElementById('btn-aceptar-cookies');
    // this.avisoCookies = document.getElementById('aviso-cookies');

    // if(!localStorage.getItem('cookies-aceptadas')){
    //   this.avisoCookies.classList.add('activo');
    //   this.backgroudCookies.classList.add('activo');
    // } else {
    //   this.dataLayer.push({'event': 'cookies-aceptadas'});
    // }
  }


  acceptCookies(){
    this.avisoCookies.classList.remove('activo');
    this.backgroudCookies.classList.remove('activo');

    localStorage.setItem('cookies-aceptadas', "true");

    this.dataLayer.push({'event': 'cookies-aceptadas'});

    if(localStorage.getItem("Walkthrought") == "false") {
      this.presentWalkthroughModal();
    }

  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: CookiesPoliciesComponent
    });
    return await modal.present();
  }

  onChangeLang() {
    if (this.language == "es")
      this.language = "en";

    else if (this.language == "en")
      this.language = "es";

    localStorage.setItem("language", this.language)
    window.dispatchEvent(new CustomEvent("lang:change", { detail: this.language }));
  }

  async presentWalkthroughModal() {

    await this.showLoadingView();

    const modal = await this.modalCtrl.create({
      component: WalkthroughPage
    });

    await modal.present();

    this.dismissLoadingView();

    localStorage.removeItem("Walkthrought");
  }

  async showLoadingView() {

    const loadingText = await this.translate.get('LOADING').toPromise();

    this.loader = await this.loadingCtrl.create({
      message: loadingText
    });

    return await this.loader.present();
  }

  async dismissLoadingView() {

    if (!this.loader) return;

    try {
      await this.loader.dismiss()
    } catch (error) {
      console.log('ERROR: LoadingController dismiss', error);
    }
  }

}
