import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BasePage } from 'src/app/pages/base-page/base-page';

@Component({
  selector: 'account-statement-form',
  templateUrl: './account-statement-form.component.html',
  styleUrls: ['./account-statement-form.component.scss'],
})
export class AccountStatementFormComponent extends BasePage implements OnInit {

  @Input() formInProgress: any = null;

  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();

  public accountStatementForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private injector: Injector,
  ) {
    super(injector);
    this.buildForm();
  }

  ngOnInit() {
    // Load form's data when is provided
    if( this.formInProgress && this.formInProgress['accountStatement'] ) this.fillForm();
  }

  enableMenuSwipe(): boolean {
    return true;
  }

  private buildForm() {
    this.accountStatementForm = this.formBuilder.group({
      accountStatement:           [ '', [Validators.required] ],
      participationRemmants:      [ '', [Validators.required] ],
      hircasaInformation:         [ {value: true, disabled: true}, [Validators.required] ],
      advertising:                [ {value: true, disabled: true}, [Validators.required] ],
    });
  }

  private fillForm(): void {
    for( const [key, control] of Object.entries(this.accountStatementForm.controls) ) {
      // Normal fill
      control.setValue( this.formInProgress[key] );
    }
  }

  public validateForm() {
    this.accountStatementForm.markAllAsTouched();
    let validationResult = this.accountStatementForm.valid;

    this.onValidation.emit({
      'validationResult': validationResult,
      'form': this.accountStatementForm.getRawValue(),
    });
  }

  public previousStep() {
    this.onPreviousStep.emit();
  }

  public isFieldInvalid( field: string ) {
    return this.accountStatementForm.get(field)!.touched && this.accountStatementForm.get(field)!.invalid;
  }

}
