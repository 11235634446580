import { Injectable } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";

import * as Parse from "parse";

@Injectable({
  providedIn: "root",
})
export class Entity extends Parse.Object {
  public auxForm: any;
  constructor() {
    super("Entity");
  }

  /*
    async createIndividual(data: any): Promise<Entity> {
    return Parse.Cloud.run('createIndividual', data)
  }
*/

  async createForm(data: any): Promise<Entity> {
    return Parse.Cloud.run("createForm", data);
  }

  async setFormIdOnUser(userId: any): Promise<Entity> {
    return Parse.Cloud.run("setFormIdOnUser", userId);
  }

  async setImagesIndividual(
    images: any,
    idUser: any,
    session: any
  ): Promise<Entity> {
    return Parse.Cloud.run("setImagesIndividual", { images, session }, idUser);
  }

  async updateDocuments(images: any, idUser: any): Promise<Entity> {
    return Parse.Cloud.run("updateDocuments", images, idUser);
  }

  async getType(idUser: any): Promise<Entity> {
    return Parse.Cloud.run("getType", idUser);
  }

  async getFormData(idUser: any): Promise<Entity> {
    return Parse.Cloud.run("getFormData", idUser);
  }

  async returnDocuments(idUser: any): Promise<any> {
    return Parse.Cloud.run("returnDocuments", idUser);
  }

  async updateLastForm(data: any): Promise<Entity> {
    return Parse.Cloud.run("updateLastForm", data);
  }

  async updateFormIdPayment(data: any): Promise<Entity> {
    return Parse.Cloud.run("updateFormIdPayment", data);
  }

  async get1Study(email: string): Promise<Entity> {
    return Parse.Cloud.run("get1Study", email);
  }

  async updatePropetyCost(email: string, amount: number): Promise<Entity> {
    return Parse.Cloud.run("updatePropetyCost", { email, amount });
  }

  /*public async createLegalEntity(data: any): Promise<Entity>{
    return Parse.Cloud.run('createLegalEntity', data);
  }
  */
}

Parse.Object.registerSubclass("Entity", Entity);
