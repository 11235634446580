import {
  Component,
  OnInit,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  Injector,
} from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { BasePage } from "src/app/pages/base-page/base-page";
import { PopoverErrorComponent } from "src/app/components/popover-error/popover-error.component";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent extends BasePage implements OnInit {
  @ViewChild("fileInput", { static: true }) input: ElementRef;

  @Output() filesSelected = new EventEmitter<File[]>();
  @Output() filesRemoved = new EventEmitter<File[]>();
  @Input() datos: File[];
  @Input() isInputVisible: boolean;
  @ViewChild("fileInput", { static: false })
  fileInput: ElementRef;
  @ViewChild("fileInputHidden", { static: false }) fileInputHidden: ElementRef;

  public uploadedFiles: File[];
  allowedFileTypes: string = "application/pdf, image/*";

  constructor(private popoverController: PopoverController, inject: Injector) {
    super(inject);
  }

  enableMenuSwipe(): boolean {
    return false;
  }

  onFileInputInteraction(event: any): void {
    const files = event.target.files;
    const newFiles: File[] = Array.from(files);

    this.uploadedFiles = this.uploadedFiles.concat(newFiles);
    this.filesSelected.emit(this.uploadedFiles);

    // Clear the selected files from the input
    // this.fileInputHidden.nativeElement.value = null;
  }

  removeFile(file: File): void {
    this.uploadedFiles = this.uploadedFiles.filter((f) => f !== file);
    this.filesRemoved.emit([file]);
    this.filesSelected.emit(this.uploadedFiles);
  }
  public onClick() {
    this.input.nativeElement.click();
  }
  ngOnInit(): void {
    this.uploadedFiles = this.datos ? [...this.datos] : [];
  }

  getFileName(file: any): string {
    if (file.parseFile == undefined) {
      if (file && typeof file.name === 'string') {
        return file.name;
      } else if (file && typeof file.name === 'function') {
        return file.name();
      } else {
        return 'Nombre Desconocido';
      }
    } else {
      if (file.parseFile && typeof file.parseFile.name === 'string') {
        return file.name;
      } else if (file.parseFile && typeof file.parseFile.name === 'function') {
        return file.parseFile.name();
      } else {
        return 'Nombre Desconocido';
      }
    }
  }

  async presentPopover(ev: any, comment: string) {
    const popover = await this.popoverController.create({
      component: PopoverErrorComponent,
      event: ev,
      translucent: true,
      keyboardClose: true,
      componentProps: {
        title: this.translate.instant("WHY_DOCUMENT_REJECTED"),
        comment: comment,
      },
    });
    return await popover.present();
  }
}
