import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Injector,
} from "@angular/core";
import { Place } from "src/app/services/place-service";
import { Observable } from "rxjs";
import { GeolocationService } from "src/app/services/geolocation.service";
import { Router } from "@angular/router";
import { User } from "src/app/services/user-service";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { GlobalFunctios } from "../../services/global-functions";
import { BasePage } from "src/app/pages/base-page/base-page";

@Component({
  selector: "app-place-card",
  templateUrl: "./place-card.component.html",
  styleUrls: ["./place-card.component.scss"],
})
export class PlaceCardComponent extends BasePage implements OnInit {
  @Input() place: Place;
  @Input() customObservable: Observable<any>;
  @Input() extraParams: any = {};
  @Input() color = "white";
  @Input() showStatus: boolean;
  @Input() showPromoteButton: boolean;
  @Input() places: any[];

  public dataresponseAddress: any;
  public placesColony: any;
  public placesMunicipality: any;
  public primerCuota: any;
  public currentRoute: string;
  public isLiked: boolean = false;

  public user: any;
  public enableCustomer: boolean = true;
  public languaje;
  public ruta: any;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onPromoteButtonTouched: EventEmitter<Place> =
    new EventEmitter<Place>();

  constructor(
    injector: Injector,
    private geolocationService: GeolocationService,
    private placeService: Place,
    public globalFunctios: GlobalFunctios,
    private route: Router
  ) {
    super(injector);
  }
  enableMenuSwipe() {
    return true;
  }
  ngOnInit() {
    // console.log(this.extraParams.slug);
    this.languaje = localStorage.getItem("language");
    this.ruta = this.languaje == "en" ? "../en/" : "../";
    this.geolocationPlace();
    this.currentRoute = this.route.url;
    this.primerCuota = parseFloat(
      String(
        ((parseFloat(this.place.price + "") / 180) * 1.3823200152928).toFixed(2)
      )
    );
    this.loadCurrentUser();
  }

  onPromoteButtonTouchedFn() {
    this.onPromoteButtonTouched.emit(this.place);
  }

  getBagColor(color: string) {
    return color;
  }

  loadCurrentUser() {
    this.user = User.getCurrent();
    if (this.user) {
      // console.log(this.user.profile);
      if (
        this.user.profile == "re_manager" ||
        this.user.profile == "manager" ||
        this.user.profile == "vendor" ||
        this.user.profile == "commercial"
      ) {
        this.enableCustomer = false;
      }
    }
  }

  getStatusColor(status: string) {
    if (status === "Pending") {
      return "warning";
    } else if (status === "Approved") {
      return "success";
    } else if (status === "Rejected") {
      return "danger";
    }
  }

  geolocationPlace() {
    // console.log(this.place);
    this.geolocationService

      .geoToString(
        this.place.location._latitude,
        this.place.location._longitude
      )
      .then((resp) => {
        setTimeout(() => {
          this.placesColony = resp.results[4].address_components[0].short_name;

          if (resp.results[7]) {
            this.placesMunicipality = resp.results[7].address_components[0]
              .short_name
              ? resp.results[7].address_components[0].short_name
              : false;
          } else {
            this.placesMunicipality = false;
          }

          this.dataresponseAddress =
            resp.results[0].formatted_address.substring(
              resp.results[0].formatted_address.indexOf(" ") + 1
            )
              ? resp.results[0].formatted_address.substring(
                  resp.results[0].formatted_address.indexOf(" ") + 1
                )
              : false;
        }, 500);
      });
  }

  onLike(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (User.getCurrent()) {
      this.isLiked = !this.isLiked;
      this.placeService.like(this.place);
    }
  }

  async onDirectionsButtonTouched() {
    const lat = this.place.location._latitude;
    const lng = this.place.location._longitude;
    const add = this.place.address;

    let url;

    if (this.globalFunctios.getOS() == "Mac") {
      url = `https://maps.apple.com/?q=${add}&address=${lat},${lng}`;
      window.location.href = encodeURI(url);
    } else {
      url = `https://maps.google.com/maps?q=${lat},${lng}`;
      window.open(url);
    }
  }

  async preventErrornavigate() {
    if (this.extraParams.slug == undefined || this.extraParams.slug == null) {
      const data = await this.placeService.getCtageori(this.place.id);
      this.extraParams.slug = data.slugSale;
      console.log(this.extraParams.slug);
    }
    console.log();
    const queryParams = {
      id: this.place.id,
    };

    this.dataLayer(
      "18",
      `Calcular ${this.place.slug}`,
      "Modelos destacados",
      "Click"
    );

    this.route.navigate(
      [
        `${this.ruta}${this.extraParams.slug}/${this.place.stateMxSlug}/${this.place.slug}`,
      ],
      { queryParams }
    );
  }

  placeDetails() {
    // this.route.navigate([`./my-credit/calculator/${this.place.id}/${this.place.price}`]);
    // [routerLink]="['/my-credit/calculator/' + place.id + '/' + place.price + '/' + place.developmentName]"

    if (this.enableCustomer)
      this.route.navigate(
        [
          `${this.ruta}mi-credito/calculadora/${this.place.id}/${this.place.price}`,
        ],
        {}
      );
    else this.route.navigate([`${this.ruta}simulator/${this.place.id}`], {});
  }
}
