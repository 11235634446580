import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})

export class Email {

  contactHomeFooterEmail(data: any = {}) {
    return Parse.Cloud.run('contactHomeFooterEmail', data);
  }

  mailSimulatorCustomer(data: any = {}){
    return Parse.Cloud.run('sendEmailSimuladorCustomer', data);
  }

  mailSimulatorDeveloper(data: any = {}){
    return Parse.Cloud.run('sendEmailSimuladorDeveloper', data);
  }

  mailSimulatorDeveloperEject(data: any = {}){
    return Parse.Cloud.run('mailSimulatorDeveloperEject', data);
  }


  mailSimulatorContact(data: any = {}){
    return Parse.Cloud.run('send', data);
  }

  emailContact(data: any = {}){
    console.log(data);
    return Parse.Cloud.run('emailContact', data);
  }

  sendEmailRequestAccountDeleting(data: any = {}){
    console.log(data);
    return Parse.Cloud.run('sendEmailRequestAccountDeleting', data);
  }



  testEmail(data: any = {}){
    return Parse.Cloud.run('testEmail', data);
  }


  sendEmailFirstContact(params: any = {}){
    return Parse.Cloud.run('firstEmail', params);
  }

}
