import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { EmptyViewModule } from "./components/empty-view/empty-view.module";
import { ImgFallbackModule } from "ngx-img-fallback";
import {
  LazyLoadImageModule,
  LAZYLOAD_IMAGE_HOOKS,
  ScrollHooks,
} from "ng-lazyload-image";
import { ComponentsModule } from "./components/components.module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { BarRatingModule } from "ngx-bar-rating";
import { PipesModule } from "./pipes/pipes.module";
import { FooterComponent } from "./pages/footer/footer.component";
import { CategoriesMenuComponent } from "./components/categories-menu/categories-menu.component";
import { RouterModule } from "@angular/router";
@NgModule({
  declarations: [FooterComponent, CategoriesMenuComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    EmptyViewModule,
    ImgFallbackModule,
    LazyLoadImageModule,
    ComponentsModule,
    NgxSkeletonLoaderModule,
    BarRatingModule,
    PipesModule,
    RouterModule,
  ],
  exports: [
    FooterComponent,
    CommonModule,
    IonicModule,
    TranslateModule,
    EmptyViewModule,
    ImgFallbackModule,
    LazyLoadImageModule,
    ComponentsModule,
    NgxSkeletonLoaderModule,
    BarRatingModule,
    PipesModule,
  ],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }],
})
export class SharedModule {}
