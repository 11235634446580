import { Component, Injector, Input, NgZone, ViewChild, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BasePage } from '../base-page/base-page';

@Component({
  selector: 'app-cookies-policies',
  templateUrl: './cookies-policies.component.html',
  styleUrls: ['./cookies-policies.component.scss'],
})
export class CookiesPoliciesComponent extends BasePage {
  enableMenuSwipe(): boolean {
    throw new Error('Method not implemented.');
  }

  constructor( injector: Injector, zone: NgZone, modalCtrl: ModalController) { super(injector);}

  ngOnInit( ) {}

  onDismiss() {
    this.modalCtrl.dismiss();
  }

}
