import { Injectable } from "@angular/core";
import * as Parse from "parse";

@Injectable({
  providedIn: "root",
})
export class ParseFile {
  constructor() {}

  static upload(data: any, isBase64: boolean = true) {
    console.log(isBase64);
    const file = isBase64 ? { base64: data } : data;
    return new Parse.File("image.jpg", file).save();
  }

  static uploadPDF(data: any, isBase64: boolean = true) {
    const file = isBase64 ? { base64: data } : data;
    return new Parse.File("archive.pdf", file).save();
  }
  static uploadRar(data: any, isBase64: boolean = true) {
    const file = isBase64 ? { base64: data } : data;
    return new Parse.File("archive.rar", file).save();
  }
  static uploadAnyFile(data: any, isBase64: boolean = true) {
    const file = isBase64 ? { base64: data } : data;
    if (file.type.includes("png")) {
      console.log(file.name);
      return new Parse.File("image.png", file).save();
    } else if (file.type.includes("pdf")) {
      return new Parse.File("doc.pdf", file).save();
    } else if (file.type.includes("jpeg")) {
      return new Parse.File("img.jpeg", file).save();
    } else if (file.type.includes("webp")) {
      return new Parse.File("img.webp", file).save();
    }
  }
}
