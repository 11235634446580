import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { InfoWindowComponent } from "./info-window/info-window";
import { TranslateModule } from "@ngx-translate/core";
import { UploadBoxComponent } from "./upload-box/upload-box.component";
import { UploadDocComponent } from "./upload-doc/upload-doc.component";
import { UploadFileComponent } from "./upload-file/upload-file.component";
import { PlaceCardComponent } from "./place-card/place-card.component";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { BarRatingModule } from "ngx-bar-rating";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SwiperModule } from "swiper/angular";
import { LoadingComponent } from "./loading/loading.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
/* Personal moral | Forms */
import { GeneralDataComponent } from "./p-moral/general-data/general-data.component";
import { AditionalInfoFormComponent } from "./p-moral/aditional-info-form/aditional-info-form.component";
import { AccountStatementFormComponent } from "./p-moral/account-statement-form/account-statement-form.component";
import { PreanalysisFormComponent } from "./p-moral/preanalysis-form/preanalysis-form.component";
import { LegalRepresentativeComponent } from "./p-moral/legal-representative/legal-representative-form.component";
import { DocumentationFormComponent } from "./documentation-form/documentation-form.component";
import { StepperFormHeaderComponent } from "./stepper-form-header/stepper-form-header.component";
/*Persona fisica*/
import { PFGeneralDataComponent } from "./p-fisica/general-data/general-data.component";
import { IncomeProfileFormComponent } from "./p-fisica/income-profile-form/income-profile-form.component";
import { BeneficiariesComponent } from "./p-fisica/beneficiaries/beneficiaries.component";
import { ReferencesComponent } from "./p-fisica/references/references.component";
import { AdditionalDataComponent } from "./p-fisica/additional-data/additional-data.component";
import { HealthDataComponent } from "./p-fisica/health-data/health-data.component";
import { DocumentsComponent } from "./p-fisica/documents/documents.component";
/*Health Modules */
import { Step1Component } from "./p-fisica/health-data/health-components/step1/step1.component";
import { Step2Component } from "./p-fisica/health-data/health-components/step2/step2.component";
import { Step3Component } from "./p-fisica/health-data/health-components/step3/step3.component";
/*Incode*/
import { IncodeComponent } from "./incode/incode.component";
/*Payment component*/
import { PaymentComponent } from "./payment/payment.component";
/* Contracting component*/
import { PdfComponent } from "./pdf/pdf.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { OneFileUploadComponent } from "./one-file-upload/one-file-upload.component";
import { CategoryCardComponent } from "./category-card/category-card.component";
import { ExploreModalComponent } from "./explore-modal/explore-modal.component";
@NgModule({
  declarations: [
    ExploreModalComponent,
    CategoryCardComponent,
    FileUploadComponent,
    InfoWindowComponent,
    UploadBoxComponent,
    UploadDocComponent,
    UploadFileComponent,
    PlaceCardComponent,
    GeneralDataComponent,
    AditionalInfoFormComponent,
    AccountStatementFormComponent,
    PreanalysisFormComponent,
    LegalRepresentativeComponent,
    DocumentationFormComponent,
    PFGeneralDataComponent,
    IncomeProfileFormComponent,
    BeneficiariesComponent,
    ReferencesComponent,
    AdditionalDataComponent,
    DocumentsComponent,
    HealthDataComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    StepperFormHeaderComponent,
    IncodeComponent,
    LoadingComponent,
    PaymentComponent,
    PdfComponent,
    OneFileUploadComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    LazyLoadImageModule,
    BarRatingModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    PdfViewerModule,
  ],
  exports: [
    ExploreModalComponent,
    CategoryCardComponent,
    InfoWindowComponent,
    UploadBoxComponent,
    UploadDocComponent,
    UploadFileComponent,
    FileUploadComponent,
    PlaceCardComponent,
    GeneralDataComponent,
    AditionalInfoFormComponent,
    AccountStatementFormComponent,
    PreanalysisFormComponent,
    LegalRepresentativeComponent,
    DocumentationFormComponent,
    PFGeneralDataComponent,
    IncomeProfileFormComponent,
    BeneficiariesComponent,
    ReferencesComponent,
    AdditionalDataComponent,
    HealthDataComponent,
    DocumentsComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    StepperFormHeaderComponent,
    IncodeComponent,
    LoadingComponent,
    PaymentComponent,
    PdfComponent,
    OneFileUploadComponent,
  ],
})
export class ComponentsModule {}
