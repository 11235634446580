import { Injector } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import {
  LoadingController,
  ToastController,
  AlertController,
  IonInfiniteScroll,
  IonRefresher,
  Platform,
  ModalController,
  NavController,
} from "@ionic/angular";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { environment } from "src/environments/environment";
import { Preference } from "src/app/services/preference";
import { SafariViewController } from "@ionic-native/safari-view-controller/ngx";
import Swal from "sweetalert2";
import { formatCurrency } from "@angular/common";
import { GoogleTagManagerService } from "angular-google-tag-manager";

export abstract class BasePage {
  public isErrorViewVisible: boolean;
  public isEmptyViewVisible: boolean;
  public isContentViewVisible: boolean;
  public isLoadingViewVisible: boolean;

  public preference: Preference;
  protected refresher: IonRefresher;
  protected infiniteScroll: IonInfiniteScroll;
  protected navParams: ActivatedRoute;
  protected translate: TranslateService;
  protected router: Router;
  protected modalCtrl: ModalController;

  private loader: any;
  private toastCtrl: ToastController;
  private loadingCtrl: LoadingController;
  private alertCtrl: AlertController;
  private navCtrl: NavController;
  public platform: Platform;

  protected activatedRoute: ActivatedRoute;
  private meta: Meta;
  private title: Title;

  private inAppBrowser: InAppBrowser;
  private safariViewController: SafariViewController;
  private gtmService: GoogleTagManagerService;

  constructor(injector: Injector) {
    this.loadingCtrl = injector.get(LoadingController);
    this.toastCtrl = injector.get(ToastController);
    this.alertCtrl = injector.get(AlertController);
    this.navParams = injector.get(ActivatedRoute);
    this.translate = injector.get(TranslateService);
    this.modalCtrl = injector.get(ModalController);
    this.platform = injector.get(Platform);
    this.preference = injector.get(Preference);
    this.navCtrl = injector.get(NavController);

    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.meta = injector.get(Meta);
    this.title = injector.get(Title);

    this.inAppBrowser = injector.get(InAppBrowser);
    this.safariViewController = injector.get(SafariViewController);
    this.gtmService = injector.get(GoogleTagManagerService);
  }

  abstract enableMenuSwipe(): boolean;

  public get isCordova(): boolean {
    return this.platform.is("cordova");
  }

  public get isPwa(): boolean {
    return this.platform.is("pwa");
  }

  public get isDesktop(): boolean {
    return this.platform.is("desktop");
  }

  public get isMobile(): boolean {
    return this.platform.is("mobile");
  }

  public get isAndroid(): boolean {
    return this.platform.is("android");
  }

  public get isApple(): boolean {
    return this.platform.is("ios");
  }

  public get appUrl(): string {
    return environment.appUrl;
  }

  public get appImageUrl(): string {
    return environment.appImageUrl;
  }

  public setPageTitle(title: string): void {
    this.title.setTitle(title);
  }

  public async setMetaTags(config1: {
    title?: string;
    description?: string;
    image?: string;
    slug?: string;
  }) {
    const str = await this.getTrans(["APP_NAME", "APP_DESCRIPTION"]);

    const config = {
      title: str.APP_NAME,
      description: str.APP_DESCRIPTION,
      image: this.appImageUrl,
      ...config1,
    };

    let url = this.appUrl + this.router.url;

    if (config.slug) {
      url = this.appUrl + "/" + config.slug;
    }
    console.log("descripciom", config.description);
    this.meta.updateTag({
      name: "description",
      content: config.description,
    });
    this.meta.updateTag({
      property: "og:title",
      content: config.title,
    });
    this.meta.updateTag({
      property: "og:description",
      content: config.description,
    });

    this.meta.updateTag({
      property: "og:image",
      content: config.image,
    });

    this.meta.updateTag({
      property: "og:image:alt",
      content: config.title,
    });

    this.meta.updateTag({
      property: "og:url",
      content: url,
    });

    this.meta.updateTag({
      name: "twitter:card",
      content: "summary_large_image",
    });

    this.meta.updateTag({
      name: "twitter:title",
      content: config.title,
    });

    this.meta.updateTag({
      name: "twitter:text:title",
      content: config.title,
    });

    this.meta.updateTag({
      name: "twitter:description",
      content: config.description,
    });

    this.meta.updateTag({
      name: "twitter:image",
      content: config.image,
    });

    this.meta.updateTag({
      name: "twitter:image:alt",
      content: config.title,
    });
  }

  public dataLayer(event: any, title: any, category: any, action: any) {
    const gtmTag = {
      event: event,
      category: category,
      action: action,
      label: title, // Usar tabName como label
    };
    console.log("dataLayer", gtmTag);

    this.gtmService.pushTag(gtmTag);
  }

  public dataLayerForms(
    event: any,
    category: any,
    label: any,
    virtualPageURL: any,
    virtualPageTitle: any
  ) {
    const gtmTag = {
      event,
      category,
      virtualPageURL,
      label,
      virtualPageTitle,
    };
    console.log("dataLayer", gtmTag);

    this.gtmService.pushTag(gtmTag);
  }

  public addCanonicalLink(href: string) {
    // Buscar el elemento link canónico existente
    const existingCanonicalLink = document.querySelector(
      "link[rel='canonical']"
    );

    if (existingCanonicalLink) {
      // Si existe, actualizar el atributo href
      existingCanonicalLink.setAttribute("href", href);
    } else {
      // Si no existe, crear un nuevo elemento link
      const canonicalLinkElement = document.createElement("link");

      // Establecer atributos del enlace canónico
      canonicalLinkElement.setAttribute("rel", "canonical");
      canonicalLinkElement.setAttribute("href", href);

      // Agregar el enlace canónico al head del documento
      document.head.appendChild(canonicalLinkElement);
    }
  }

  public getShareUrl(slug: string) {
    return this.appUrl + "/" + slug;
  }

  async showLoadingView(params: { showOverlay: boolean }) {
    if (params.showOverlay) {
      const loadingText = await this.getTrans("LOADING");

      this.loader = await this.loadingCtrl.create({
        message: loadingText,
        backdropDismiss: true,
      });

      return await this.loader.present();
    } else {
      this.isErrorViewVisible = false;
      this.isEmptyViewVisible = false;
      this.isContentViewVisible = false;
      this.isLoadingViewVisible = true;
    }

    return true;
  }

  async dismissLoadingView() {
    if (!this.loader) return;

    return await this.loader.dismiss();
  }

  showContentView() {
    this.isErrorViewVisible = false;
    this.isEmptyViewVisible = false;
    this.isLoadingViewVisible = false;
    this.isContentViewVisible = true;

    this.dismissLoadingView();
  }

  showEmptyView() {
    this.isErrorViewVisible = false;
    this.isLoadingViewVisible = false;
    this.isContentViewVisible = false;
    this.isEmptyViewVisible = true;

    this.dismissLoadingView();
  }

  showErrorView() {
    this.isLoadingViewVisible = false;
    this.isContentViewVisible = false;
    this.isEmptyViewVisible = false;
    this.isErrorViewVisible = true;

    this.dismissLoadingView();
  }

  onRefreshComplete(data = null) {
    if (this.refresher) {
      this.refresher.disabled = true;
      this.refresher.complete();
      setTimeout(() => {
        this.refresher.disabled = false;
      }, 100);
    }

    if (this.infiniteScroll) {
      this.infiniteScroll.complete();

      if (data && data.length === 0) {
        this.infiniteScroll.disabled = true;
      } else {
        this.infiniteScroll.disabled = false;
      }
    }
  }

  async showToast(message: string) {
    const closeText = await this.getTrans("CLOSE");

    const toast = await this.toastCtrl.create({
      message: message,
      color: "dark",
      position: "bottom",
      cssClass: "tabs-bottom",
      duration: 3000,
      buttons: [
        {
          text: closeText,
          role: "cancel",
        },
      ],
    });

    return await toast.present();
  }

  async showAlert(message: string) {
    const okText = await this.getTrans("OK");

    const alert = await this.alertCtrl.create({
      header: "",
      message: message,
      buttons: [
        {
          text: okText,
          role: "",
        },
      ],
    });

    return await alert.present();
  }

  async showConfirm(message: string) {
    const trans = await this.getTrans(["OK", "CANCEL"]);

    const confirm = await this.alertCtrl.create({
      header: "",
      message: message,
      buttons: [
        {
          text: trans.CANCEL,
          role: "cancel",
        },
        {
          text: trans.OK,
          role: "",
        },
      ],
    });

    return await confirm.present();
  }

  async showSweetRadio(
    title: string,
    confirmText: string,
    cancelText: string,
    options: any
  ): Promise<any> {
    return Swal.fire({
      title: title,
      input: "radio",
      inputOptions: options,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      showCancelButton: true,
      heightAuto: false,
      reverseButtons: true,
      showClass: {
        popup: "animated fade-in",
      },
      position: "center",
    });
  }

  async showSweetTextArea(
    text: string,
    placeholderText: string,
    confirmText: string,
    cancelText: string
  ) {
    return await Swal.fire({
      title: "",
      text: text,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      showCancelButton: true,
      reverseButtons: true,
      input: "textarea",
      inputPlaceholder: placeholderText,
      heightAuto: false,
      onOpen: () => {
        const confirmBtn = Swal.getConfirmButton();
        confirmBtn.setAttribute("disabled", "");
        Swal.getInput().addEventListener("keyup", (e: any) => {
          if (e.target.value) {
            confirmBtn.removeAttribute("disabled");
          } else {
            confirmBtn.setAttribute("disabled", "");
          }
        });
      },
      showClass: {
        popup: "animated fade-in",
      },
      position: "center",
    });
  }

  async openUrl(url: string) {
    if (!url) return;

    if (this.isCordova) {
      try {
        const isAvailable = await this.safariViewController.isAvailable();

        if (isAvailable) {
          await this.safariViewController
            .show({
              url: url,
              toolbarColor: environment.androidHeaderColor,
            })
            .toPromise();
        } else {
          this.inAppBrowser.create(url, "_system");
        }
      } catch (error) {
        console.log(error);
      }
    } else if (this.isPwa) {
      this.inAppBrowser.create(url, "_blank");
    } else {
      this.inAppBrowser.create(url, "_system");
    }
  }

  openSimpleUrl(url: string) {
    this.inAppBrowser.create(url, "_system");
  }

  navigateTo(page: any, queryParams: any = {}) {
    return this.router.navigate([page], { queryParams: queryParams });
  }

  navigateToRelative(page: any, queryParams: any = null) {
    return this.router.navigate([page], {
      queryParams: queryParams,
      relativeTo: this.activatedRoute,
      queryParamsHandling: queryParams ? "merge" : "",
    });
  }

  createUrlTree(queryParams: any = {}) {
    return this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  setRoot(page: string) {
    return this.navCtrl.navigateRoot(page);
  }
  getRoute() {
    return this.activatedRoute.snapshot.parent.url[0].path;
  }
  getParams() {
    return this.activatedRoute.snapshot.params;
  }

  getQueryParams() {
    return this.activatedRoute.snapshot.queryParams;
  }

  getTrans(key: string | string[], params: any = {}) {
    return this.translate.get(key, params).toPromise();
  }

  public changeInputFormatToMoneyFormat($event) {
    let amount = $event.target.value.replace(/[^0-9.]/g, "");
    $event.target.value = "$" + Number(amount).toLocaleString("es-MX");
  }

  public changeInputFormatToMoneyFormatDecimal($event: any): void {
    let input = $event.target;
    let value = input.value.trim();

    value = value.replace(/[^\d.]/g, "");

    // se asegura que el primer carácter no sea "0" o "."
    if (value.length > 0 && (value[0] === "0" || value[0] === ".")) {
      value = value.substring(1);
    }

    const parts = value.split(".");
    let dollars = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    let cents = "";
    if (parts.length > 1) {
      cents = "." + parts[1].substring(0, 2);
    }

    input.value = "$" + dollars + cents;
  }

  public changeCurrencyInput(money: string): string {
    let moneyNumber = money.replace("$", "").replace(/,/g, "");
    let valor = parseFloat(moneyNumber);

    // Verificar si el valor es un número válido
    if (isNaN(valor)) {
      return "$0.00";
    } else if (valor <= 0) {
      return "$0.00";
    }

    // Redondear a dos decimales
    let newValor = valor.toFixed(2);

    // Separar parte entera y decimal
    const partes = newValor.split(".");
    let parteEntera = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Asegurar que la parte decimal tenga dos dígitos
    let parteDecimal = partes[1] || "00";
    parteDecimal =
      parteDecimal.length === 1 ? parteDecimal + "0" : parteDecimal;

    return "$" + parteEntera + "." + parteDecimal;
  }

  public changeInputFormatToMoneyFormatString(string: string) {
    let amount = string.replace(/[^0-9.]/g, "");
    string = "$" + Number(amount).toLocaleString("es-MX");
    return string;
  }

  public changeInputFormatToZipCodeFormat($event) {
    let zipCode: string = $event.target.value.replace(/[^0-9.]/g, "");

    if (zipCode.length > 5) zipCode = zipCode.slice(0, 5);

    $event.target.value = zipCode;
  }

  public changeInputFormatToPhoneFormat($event) {
    let numbers: string = $event.target.value.replace(/[^0-9.]/g, "");

    if (numbers.length > 10) numbers = numbers.slice(0, 10);

    $event.target.value = numbers;
  }

  //this function deltete all characters not numerics and chars (- , e)
  public positiveNumber($event: any) {
    let numbers: string = $event.target.value.replace(/[^0-9.]/g, "");
    $event.target.value = numbers;
  }

  public onTabKey(event: KeyboardEvent) {
    event.preventDefault();
  }
  public primeraLetraMayuscula(cadena: string) {
    return cadena.charAt(0).toUpperCase() + cadena.slice(1);
  }
}
