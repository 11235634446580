const regimenArrayName: string[] = [
  "LEASING",
  "OTHER_INCOME",
  "TAX_INCORPORATION",
  "DIVIDEND_INCOME",
  "INTEREST_INCOME",
  "INDIVIDUALS_WITH_BUSINESS",
  "RESIDENTS_ABROAD",
  "REGIME_OF_ALIENATION",
  "REGIME_FOR_INCOME",
  "TRUST_REGIME",
  "NO_TAX_OBLIGATIONS",
  "WAGES_AND_SALARIES",
];

const studiesArray: string[] = [
  "PRIMARIA",
  "SECUNDARIA",
  "MEDIO_SUPERIOR",
  "TÉCNICO",
  "LICENCIATURA_POSGRADO",
  "NINGUNA_DE_LAS_ANTERIORES",
];

const profesionesArray: string[] = [
  "HEAD_OF_OFFICE",
  "LAWYER",
  "ACTOR",
  "ACTUARY",
  "ACUPUNCTURIST",
  "DOG_TRAINER",
  "ADMINISTRATOR",
  "CALL_CENTER_AGENT",
  "INSURANCE_AGENT",
  "SALES_AGENT",
  "TRAVEL_AGENT",
  "HOUSEKEEPER",
  "ANALYST",
  "ADVERTISING_ANALYST",
  "ARCHITECT",
  "LESSEE",
  "QUALITY_UNDERWRITER",
  "CONSULTANT",
  "MANAGEMENT_ASSISTANT",
  "DGA_ASSISTANT",
  "DOMESTIC_ASSISTANT",
  "EDUCATIONAL_ASSISTANT",
  "AUDITOR",
  "ADMINISTRATIVE_ASSISTANT",
  "ACCOUNTING_ASSISTANT",
  "ELECTRONICS_ASSISTANT",
  "LIBRARIAN",
  "BIOLOGIST",
  "BIOCHEMIST",
  "CASHIER",
  "CAMERAMAN",
  "SINGER",
  "OPERATIONS_TRAINER",
  "CAPTURIST",
  "CHEF",
  "DRIVER",
  "COOK",
  "TRADER",
  "INTERNATIONAL_TRADE",
  "COMMISSION_MERCHANT",
  "COMPENSATIONS_AND_CLARIFICATIONS",
  "COMMUNICOLOGIST",
  "CONSIERGE",
  "BUILDER",
  "CONSULTANT",
  "ACCOUNTANT",
  "CONTRACTOR",
  "COORDINATOR",
  "COREOGRAFO",
  "BROKER",
  "DECORATOR",
  "HOME_DECORATOR",
  "DIRECTOR",
  "DESIGNER",
  "INDUSTRIAL_DESIGNER",
  "DISTRIBUTOR",
  "TEACHER",
  "ECONOMIST",
  "EDECAN",
  "PUBLISHER",
  "EDUCATOR",
  "ACCOUNT_EXECUTIVE",
  "SALES_EXECUTIVE",
  "FEDERAL_EMPLOYEE",
  "EMPLOYEE",
  "ENTREPRENEUR",
  "BUSINESS_MANAGER",
  "NURSE",
  "TRAINER",
  "SP",
  "STENOGRAPHER",
  "STYLIST",
  "EXPORTER",
  "FISCALIST",
  "PHYSIOTHERAPIST",
  "CIVIL_SERVANT",
  "MANAGER",
  "TOURIST_GUIDE",
  "SCRIPTWRITER",
  "HISTORIAN",
  "HOTEL_AND_TOURISM",
  "INDEPENDENT",
  "COMPUTER_SCIENTIST",
  "ENGINEER",
  "RESEARCHER",
  "LOGISTICS_MANAGER",
  "SITE_MANAGER",
  "HIFT_MANAGER",
  "RETIRED",
  "GRADUATE",
  "BACHELOR_IN_SYSTEMS",
  "BROADCASTER",
  "TEACHER",
  "MECHANIC",
  "DOCTOR",
  "MESSENGER",
  "MARKETER",
  "WAITER",
  "MODEL",
  "DRESSMAKER",
  "MUSEOGRAPHER",
  "MUSICIAN",
  "N",
  "NUTRITIONIST",
  "ODONTOLOGIST",
  "OPERATOR",
  "PIPE_OPERATOR",
  "OPTOMETRIST",
  "GOLDSMITH",
  "OTHER",
  "PEDAGOGUE",
  "JOURNALIST",
  "EXPERT",
  "EXPERT_APPRAISER",
  "PILOT_AVIATOR",
  "MILITARY_AVIATOR_PILOT",
  "POLICEMAN",
  "FILM_PRODUCER",
  "EVENT_PRODUCER",
  "AUDIOVISUAL_PRODUCER",
  "TEACHER",
  "COMPUTER_PROGRAMMER",
  "PROMOTER",
  "HEALTH_PROMOTER",
  "PSYCHOLOGIST",
  "PSYCHIATRIST",
  "PUBLICIST",
  "CHEMIST",
  "COLLECTOR",
  "INTERNATIONAL_RELATIONS",
  "SALES_REPRESENTATIVE",
  "LEGAL_REPRESENTATIVE",
  "MEDICAL_REPRESENTATIVE",
  "SECRETARY",
  "CUSTOMER_SERVICE",
  "NO_DATA",
  "FLIGHT_ATTENDANT",
  "SOCIOLOGIST",
  "TECHNICAL_SUPPORT",
  "ASSISTANT_MANAGER",
  "COMMERCIAL_ASSISTANT_MANAGER",
  "SITE_SUPERINTENDENT",
  "SUPERVISOR",
  "TECHNICIAN",
  "ADMINISTRATION_TECHNICIAN",
  "CAMERAMAN_TECHNICIAN",
  "ELECTRICAL_TECHNICIAN",
  "FOUNDRY_TECHNICIAN",
  "MACHINE_AND_TOOL_TECHNICIAN",
];

const typeHomeArray: string[] = [
  "FAMILIAR",
  "PROPIA_HIPOTECADA",
  "PROPIA",
  "RENTADA",
];

const commercialActivityArray: string[] = [
  "TRADE",
  "FAMILY_BUSINESS",
  "PRIVATE_ENTERPRISE",
  "GOVERNMENT",
  "INDEPENDENT_PROFESSIONAL",
  "PARTNER",
];
const incomeTypeArray: string[] = ["COMPROBABLE", "NO_COMPROBABLE "];
const seguro: string[] = ["SEGURO_INDIVIDUAL", "SEGURO_MULTIPLE_O_CONYUGAL "];
const TypeDocuments: string[] = [
  "INE",
  "PASAPORTE",
  "CÉDULA_PROFESIONAL",
  "LICENCIA_CONDUCIR",
  "SEPOMEX",
  "DOCUMENTO_EXTRANJERO",
  "OTROS_NO_AUTORIZADOS_POR_LA_EMPRESA",
];

const actual_chargeArray: string[] = [
  "ADMINISTRATOR",
  "ANALYST",
  "LESSOR",
  "ADVISOR",
  "ASSISTANT",
  "TRADER",
  "CONSULTANT",
  "COORDINATOR",
  "DIRECTOR",
  "TEACHER",
  "OWNER",
  "ENTREPRENEUR",
  "MANAGER",
  "INDEPENDENT",
  "BOSS",
  "RETIRED",
  "OTHER",
  "PARTNER",
  "ASSISTANT_MANAGER",
  "SALES",
];

const company_typeArray: string[] = [
  "AGRICULTURAL",
  "FOOD",
  "WHOLESALE",
  "RETAIL",
  "CONSTRUCTION",
  "CORPORATE",
  "CULTURAL",
  "EDUCATION",
  "ENERGY",
  "FINANCE",
  "REAL",
  "LEGISLATIVE",
  "MANUFACTURING",
  "MASS",
  "MINING",
  "OTHER",
  "HEALTH",
  "BUSINESS",
  "TRANSPORTATION",
];

const questions1: string[] = [
  "CURRENTLY_DISEASE",
  "CONSULTED_DOCTOR",
  "HAD_SURGERY",
  "SUFFER_HEARTH",
  "SUFFER_DIABETES",
  "POSITIVE_FOR_AIDS",
  "CURRENTLY_MEDICAL_TREATMENT",
  "SUFFER_CANCER_OR_TUMORS",
  "ARTHRITIS",
  "HERNIATED_DISCS",
];
const questions1FormGroups: string[] = [
  "Question_B",
  "Question_C",
  "Question_D",
  "Question_E",
  "Question_F",
  "Question_G",
  "Question_H",
  "Question_I",
  "Question_J",
  "Question_K",
];

const questions2: string[] = [
  "TASKS_HEAVY_GOODS",
  "TASKS_UNDERGROUND_HEIGHTS",
  "TASKS_CORROSIVE_SUBSTANCES",
  "TASKS_RADIOACTIVE_SUBSTANCES",
  "TASKS_TRAVELING",
  "TASKS_IRREGULAR_SHIFTS",
  "TASKS_EXPLOSIVE_MATERIALS",
  "TASKS_HIGH_VOLTAGE",
  "TASKS_CARRYING_WEAPONS",
  "TASKS_TRANSPORTING_MONEY",
  "TASKS_BODYGUARD",
  "TASKS_POLICE_INVESTIGATION",
  "TASKS_AIRPLANES",
  "TASKS_OTHER_DANGEROUS",
];

const questions2FormGroup: string[] = [
  "QuestionC_A",
  "QuestionC_B",
  "QuestionC_C",
  "QuestionC_D",
  "QuestionC_E",
  "QuestionC_F",
  "QuestionC_G",
  "QuestionC_H",
  "QuestionC_I",
  "QuestionC_J",
  "QuestionC_K",
  "QuestionC_L",
  "QuestionC_M",
  "QuestionC_N",
];

const questions3: string[] = [
  "PURPOSE_OF_THE_LOAN",
  "AMOUNT_OF_THE_LOAN",
  "DURATION_AND_REPAYMENT_TERMS",
  "NAME_OF_LENDER",
  "NAME_OF_BORROWER",
  "CONTRACTING_INDISPENSABLE",
  "AMOUNT_OF_THE_RESPECTIVE_LOANS",
];
const questions3Formgroup: string[] = [
  "Question_I_A",
  "Question_I_B",
  "Question_I_C",
  "Question_I_D",
  "Question_I_E",
  "Question_I_F",
  "Question_I_G",
];

const heritage: string[] = [
  "COMPOSITION_OF_SHAREHOLDERS_EQUITY",
  "ASSETS_LIABILITIES",
  "PROPERTY",
  "MORTGAGES",
  "INVESTMENTS",
  "LOANS",
  "OTHER",
];
const heritageMoney: string[] = [
  "heri_A",
  "heri_B",
  "heri_C",
  "heri_D",
  "heri_E",
  "heri_F",
  "heri_G",
];

// const relationships:string[] =[
//   "WIFE/HUSBAND",
//   "SON/DAUGHTER",
//   "FATHER",
//   "MOTHER",
//   "NIECE",
//   "BROTHER/SISTER",
//   "COUSIN",
//   "GRANDPARENT",
//   "UNCLE/AUNT",
//   "FRIEND",
//   "OTHER",
// ]
const relationships: string[] = [
  "CÓNYUGE",
  "CONCUBINO_A_",
  "MADRE",
  "PADRE",
  "HERMANO_A_",
  "HIJO_A_",
  "ABUELO_A_",
  "NIETO_A_",
  "TÍO_A_",
  "SOBRINO_A_",
  "PRIMO_A_",
  "OTRO",
  "AMIGO_A",
];

const formControl: string[] = [
  "income_profile",
  "beneficiaries",
  "references",
  "additionalData",
  "healthData",
  "documents",
];

const incomeProfileAddress: string[] = [
  "job_type",
  "income_type",
  "company",
  "actual_charge",
  "antique",
  "department",
  "company_type",
  "boss_name",
  "ingresos",
  "company_tel",
  "company_switch",
  "company_email",
  "company_cel",
];

const beneficiariesForm: string[] = [
  "beneficiaries_name",
  "last_name_p_beneficiaries",
  "last_name_m_beneficiaries",
  "beneficiary_relationship",
  "beneficiary_percent",
  "beneficiary_tel",
];

const step1Form: string[] = [
  "Question_A",
  "Question_A_E",
  "Question_B",
  "Question_C",
  "Question_D",
  "Question_E",
  "Question_F",
  "Question_G",
  "Question_H",
  "Question_I",
  "Question_J",
  "Question_K",
  "currentDisease",
  "currentDateDisease",
  "currentMedicine",
  "currentDose",
  "otherDisease",
  "dateDisease",
  "medicine",
  "dose",
  "height",
  "weight",
  "Question_L",
  "kg_less",
  "kg_max",
  "cause",
  "Question_M",
  "Question_O",
  "Question_P",
  "Employment",
  "Question_Q",
  "job",
  "job_description",
  "high_risk_activity",
  "insurance",
  "insurance_name",
  "alcoholic_drinks",
  "alcoholic_type",
  "alcoholic_quantity",
  "frecuency",
  "smoke",
  "smoke_type",
  "smoke_Quantity",
  "smoke_Frecuency",
  "smoke_Quit_Date",
  "smoke_Quit_Reason",
  "travel",
  "travel_Frecuency",
];

const step1FormExtraQuestions: string[] = [
  "question_N",
  "question_Name",
  "question_date",
  "question_duration",
  "question_currentState",
];

const step2Form: string[] = [
  "QuestionC_A",
  "QuestionC_B",
  "QuestionC_C",
  "QuestionC_D",
  "QuestionC_E",
  "QuestionC_F",
  "QuestionC_G",
  "QuestionC_H",
  "QuestionC_I",
  "QuestionC_J",
  "QuestionC_K",
  "QuestionC_L",
  "QuestionC_M",
  "QuestionC_N",
  "additional_details",
  "Question_E",
  "Question_E_Details",
  "Question_F",
];

const step3Form: string[] = [
  "Question_A",
  "Question_B",
  "Question_C",
  "Question_D",
  "Question_E",
  "Question_F",
  "Question_G",
  "Question_H",
  "Question_I",
  "Question_I_A",
  "Question_I_B",
  "Question_I_C",
  "Question_I_D",
  "Question_I_E",
  "Question_I_F",
  "Question_I_G",
  "heri_A",
  "heri_B",
  "heri_C",
  "heri_D",
  "heri_E",
  "heri_F",
  "heri_G",
  "total",
];

export {
  step3Form,
  step2Form,
  beneficiariesForm,
  incomeProfileAddress,
  regimenArrayName,
  profesionesArray,
  studiesArray,
  typeHomeArray,
  commercialActivityArray,
  incomeTypeArray,
  actual_chargeArray,
  company_typeArray,
  questions1,
  questions1FormGroups,
  questions2,
  questions2FormGroup,
  questions3,
  questions3Formgroup,
  heritage,
  heritageMoney,
  relationships,
  formControl,
  step1Form,
  step1FormExtraQuestions,
  TypeDocuments,
  seguro,
};
