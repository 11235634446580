
import { Component, Injector, ViewChild } from '@angular/core';
import { LocalStorage } from '../../services/local-storage';
import { BasePage } from '../base-page/base-page';
import { SlideIntro } from 'src/app/services/slide-intro.service';
import { environment } from 'src/environments/environment';
import { HeaderColor } from '@ionic-native/header-color/ngx';
import { IonSlides, Platform } from '@ionic/angular';

@Component({
  selector: 'page-walkthrough',
  templateUrl: './walkthrough.html',
  styleUrls: ['./walkthrough.scss']
})
export class WalkthroughPage extends BasePage {

  @ViewChild(IonSlides) ionSlides: IonSlides;

  public slidesOptions = {
    grabCursor: true,
    touchStartPreventDefault: false,
    zoom: false,
    
  };

  public language: string = "";
  public slides: any[] = [];
  index: number = 0;

  constructor(injector: Injector,
    private headerColor: HeaderColor,
    private slideIntroService: SlideIntro,
    private storage: LocalStorage) {
    super(injector);
  }

  enableMenuSwipe(): boolean {
    return false;
  }

  ngOnInit() {
    this.language = localStorage.getItem("language");
    this.showLoadingView({ showOverlay: false });
  }

  async ionViewDidEnter() {
    await this.loadData();
  }

  async loadData() {
    try {
      
      if(this.language == "es") {
        if(this.platform.is("desktop")) {
          this.slides = [
            {imgUrl: "../../../assets/img/pop-ups/esp/pp1-esp.webp"},
            {imgUrl: "../../../assets/img/pop-ups/esp/pp2-esp.webp"},
            {imgUrl: "../../../assets/img/pop-ups/esp/pp3-esp.webp"}
          ];
        }else {
          this.slides = [
            {imgUrl: "../../../assets/img/pop-ups/esp/pp1-esp-m.webp"},
            {imgUrl: "../../../assets/img/pop-ups/esp/pp2-esp-m.webp"},
            {imgUrl: "../../../assets/img/pop-ups/esp/pp3-esp-m.webp"}
          ];
        }
      }else {
        if(this.platform.is("desktop")) {
          this.slides = [
            {imgUrl: "../../../assets/img/pop-ups/eng/pp1-eng.webp"},
            {imgUrl: "../../../assets/img/pop-ups/eng/pp2-eng.webp"},
            {imgUrl: "../../../assets/img/pop-ups/eng/pp3-eng.webp"}
          ];
        }else {
          this.slides = [
            {imgUrl: "../../../assets/img/pop-ups/eng/pp1-eng-m.webp"},
            {imgUrl: "../../../assets/img/pop-ups/eng/pp2-eng-m.webp"},
            {imgUrl: "../../../assets/img/pop-ups/eng/pp3-eng-m.webp"}
          ];
        }
      }

      this.showContentView();
      
    } catch (err) {
      this.translate.get('ERROR_NETWORK')
        .subscribe(str => this.showToast(str));
      this.showErrorView();
    }
  }

  playVideoWalkthrough() {
    if(!this.platform.is("desktop")) {
      setTimeout(() => {
        var videoWalkthrough = document.getElementById("graficoVideoWalkthrough") as HTMLVideoElement;

        videoWalkthrough.muted = true;
        videoWalkthrough.loop = true;
        videoWalkthrough.play();
      }, 1000);
    }else {
      var videoWalkthrough = document.getElementById("graficoVideoWalkthrough") as HTMLVideoElement;

      videoWalkthrough.muted = true;
      videoWalkthrough.loop = true;
      videoWalkthrough.play();
    }
  }

  async onSlideDidChange() {
    const index = await this.ionSlides.getActiveIndex();
    this.index = index;
  }

  async onSlideNextStart(){
    let currentIndex: any;
    await this.ionSlides.getActiveIndex().then(i => {currentIndex = i+1});
    let lenghtSlides = this.slides.length+1;
    if(currentIndex === lenghtSlides){
      this.skip();
    }
  }

  async nextSlide() {
    let currentIndex: any = await this.ionSlides.getActiveIndex();
    
    if(currentIndex === this.slides.length-1) this.skip();
    else this.ionSlides.slideNext();
  }

  async skip() {
    try {
      await this.storage.setSkipIntroPage(true);
    } catch (error) {
      console.log(error.message);
    }

    this.modalCtrl.dismiss();
  }

}
