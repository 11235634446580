import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-error',
  templateUrl: './popover-error.component.html',
  styleUrls: ['./popover-error.component.scss'],
})
export class PopoverErrorComponent implements OnInit {

  constructor(
    private popoverController: PopoverController,
  ) { }

  ngOnInit() {}

  public close() {
    this.popoverController.dismiss();
  }

}
