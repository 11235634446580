import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PostalCodeResponse } from '../models/postal-code.model';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostalCodeService {




  constructor(
    private httpClient: HttpClient,
  ) { }

  getPostalCodeInformation( postalCode: string ): Observable<any> {
    return this.httpClient.post<any>( `${environment.serverHost}/externalRequest/postal-code-info/`, {postalCode});
  }
}
