import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BasePage } from "src/app/pages/base-page/base-page";
import { regimenArrayName } from "src/app/utils/statics/dl-expediente";
import { PostalCodeService } from "src/app/services/postal-code.service";
import { Entity } from "src/app/services/entity_type.service";
import { HirDataService } from "src/app/services/hir-data.service";
import CustomValidators from "src/app/utils/validators/custom-validators";
@Component({
  selector: "additional-data",
  templateUrl: "./additional-data.component.html",
  styleUrls: ["./additional-data.component.scss"],
})
export class AdditionalDataComponent
  extends BasePage
  implements OnInit, OnChanges {
  enableMenuSwipe(): boolean {
    throw new Error("Method not implemented.");
  }
  public additionalDataForm: FormGroup;
  @Input() form: FormGroup;
  @Input() status: string;
  @Input() fullform: any;
  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onResize = new EventEmitter<any>();
  display: boolean = false;
  public suburbs: any[];
  public showSuburbsForm: boolean = false;
  private rfcValidator = Validators.pattern(
    /^([A-Z]{4})([0-9]{6})([A-Z0-9]{3})$/
  );
  public regimenList = "";
  public residenceForm: any;
  public opcionSeleccionada: string;

  constructor(
    injector: Injector,
    public fb: FormBuilder,
    private postalCodeService: PostalCodeService,
    private HirDataService: HirDataService
  ) {
    super(injector);
  }

  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.fullform) {
      this.fullform = changes.fullform.currentValue;
      console.log("Items han cambiado en el componente hijo:", this.fullform);
      this.buildForm();

      this.additionalDataForm.get('mailment').get('bill').valueChanges!.subscribe((newValue: string) => {
        if (newValue == "no") {
          this.cleanFiscalInformation();
          this.onResize.emit();
        } else {
          this.setValidatorsFiscalInformation();
          this.onResize.emit();
        }
      });

      this.HirDataService.LogIn().subscribe((response) => {
        localStorage.setItem("token", response);
      });
      this.HirDataService.GetRegimen(0).subscribe((data) => {
        console.log(data);
        this.regimenList = data;
      });
      this.fullform?.attributes?.rfc == undefined
        ? ""
        : this.additionalDataForm
          .get("mailment.rfc")!
          .setValue(this.fullform?.attributes?.rfc);
      if (
        this.status == "inProgress" &&
        this.fullform?.attributes?.additionalData
      ) {
        this.setAdditionalDataForm();
      }
    }
  }
  public buildForm() {
    console.log("formulario build", this.fullform?.attributes?.rfc);
    this.additionalDataForm = this.fb.group({
      residence: ["", Validators.required],
      mailment: this.fb.group({
        account_state: ["", Validators.required],
        medio_contacto_publicidad: ["", Validators.required],
        bill: ["", Validators.required],
        rfc:
          this.fullform && this.fullform?.attributes?.type == 4
            ? ["", [this.rfcValidator]]
            : ["", [Validators.required, this.rfcValidator]],
        regimen: ["", Validators.required],
        name_f:
          this.fullform && this.fullform?.attributes?.type == 4
            ? [""]
            : ["", [Validators.required, Validators.minLength(3), CustomValidators.namesValidator]],
        last_name_pf:
          this.fullform && this.fullform?.attributes?.type == 4
            ? [""]
            : ["", [Validators.required, Validators.minLength(3), CustomValidators.namesValidator]],
        last_name_mf:
          this.fullform && this.fullform?.attributes?.type == 4
            ? [""]
            : ["", [Validators.required, Validators.minLength(3), CustomValidators.namesValidator]],
      }),
      agreements: this.fb.group({
        remanents: ["no_remanent", Validators.required],
        publicity_checkBox: [false, Validators.required],
        information_checkBox: [false, Validators.required],
        contracts: ["yes_contracts", Validators.required],
        payment_type: ["", Validators.required],
      }),
      taxDomicile: this.fb.group([]),
    });
    this.residenceForm = this.additionalDataForm.get(
      "taxDomicile"
    ) as FormArray;
  }

  setValidatorsFiscalInformation(): void {
    this.additionalDataForm!.get('mailment')!.get('rfc')!.setValidators(
      this.fullform && this.fullform?.attributes?.type == 4
        ? [this.rfcValidator]
        : [Validators.required, this.rfcValidator]);

    this.additionalDataForm!.get('mailment')!.get('last_name_pf')!.setValidators(
      this.fullform && this.fullform?.attributes?.type == 4
        ? []
        : [Validators.required, Validators.minLength(3)]
    );

    this.additionalDataForm!.get('mailment')!.get('last_name_mf')!.setValidators(
      this.fullform && this.fullform?.attributes?.type == 4
        ? []
        : [Validators.required, Validators.minLength(3)]
    );

    this.additionalDataForm!.get('mailment')!.get('name_f')!.setValidators(
      this.fullform && this.fullform?.attributes?.type == 4
        ? []
        : [Validators.required, Validators.minLength(3)]
    );

    this.additionalDataForm!.get('residence').setValidators([Validators.required]);

    this.setValidationsResidenceForm();
  }

  cleanFiscalInformation(): void {
    this.additionalDataForm!.get('mailment')!.get('rfc')!.clearValidators();
    this.additionalDataForm!.get('mailment')!.get('rfc')!.updateValueAndValidity();
    this.additionalDataForm!.get('mailment')!.get('rfc')!.setValue("");

    this.additionalDataForm!.get('mailment')!.get('last_name_pf')!.clearValidators();
    this.additionalDataForm!.get('mailment')!.get('last_name_pf')!.setValue(null);
    this.additionalDataForm!.get('mailment')!.get('last_name_pf')!.updateValueAndValidity();

    this.additionalDataForm!.get('mailment')!.get('last_name_mf')!.clearValidators();
    this.additionalDataForm!.get('mailment')!.get('last_name_mf')!.setValue(null);
    this.additionalDataForm!.get('mailment')!.get('last_name_mf')!.updateValueAndValidity();

    this.additionalDataForm!.get('mailment')!.get('name_f')!.clearValidators();
    this.additionalDataForm!.get('mailment')!.get('name_f')!.setValue(null);
    this.additionalDataForm!.get('mailment')!.get('name_f')!.updateValueAndValidity();

    this.additionalDataForm!.get('mailment')!.get('regimen')!.setValue(10);

    this.additionalDataForm!.get('residence').clearValidators();
    this.additionalDataForm!.get('residence').setValue("");
    this.additionalDataForm!.get('residence').updateValueAndValidity();

    this.clearValidationsResidenceForm();
  }

  public setEmptyFiscalInformation(): void {
    if (this.fullform.attributes.type == 4) {
      if (this.additionalDataForm.controls.mailment.get("regimen")!.value == "") {
        this.additionalDataForm.controls.mailment.get("regimen")!.setValue(10);
      }

      if (this.additionalDataForm.controls.mailment.get("last_name_mf")!.value == "") {
        this.additionalDataForm.controls.mailment.get("last_name_mf")!.setValue(null);
      }

      if (this.additionalDataForm.controls.mailment.get("last_name_pf")!.value == "") {
        this.additionalDataForm.controls.mailment.get("last_name_pf")!.setValue(null);
      }

      if (this.additionalDataForm.controls.mailment.get("name_f")!.value == "") {
        this.additionalDataForm.controls.mailment.get("name_f")!.setValue(null);
      }

      if (this.additionalDataForm.controls.mailment.get("bill")!.value == "") {
        this.additionalDataForm.controls.mailment.get("bill")!.setValue("no");
      }

      if (this.additionalDataForm.controls.mailment.get("rfc")!.value == "") {
        this.additionalDataForm.controls.mailment.get("rfc")!.setValue("");
      }
    }
  }

  public validateForm() {
    this.setEmptyFiscalInformation();
    console.log(this.additionalDataForm);
    this.additionalDataForm.markAllAsTouched();
    this.onResize.emit();
    let validationResult = this.additionalDataForm.valid;

    this.onValidation.emit({
      index: 4,
      validationResult: validationResult,
      form: this.additionalDataForm.getRawValue(),
    });
  }

  public addResidenceForm($event) {
    const value = $event.target.value;
    if (value != "other") {
      this.display = true;
      this.addResidenceFormsElements();

      this.residenceForm.get("zip").setValue(this.form.get("zip")!.value);
      this.getPostalCodeInformation(this.form.get("zip")!.value);
      this.residenceForm.get("street").setValue(this.form.get("street")!.value);
      this.residenceForm
        .get("streetNumber")
        .setValue(this.form.get("streetNumber")!.value);
      this.residenceForm.get("colony").setValue(this.form.get("colony")!.value);
    } else {
      this.display = true;

      this.addResidenceFormsElements();
      this.emptyValues();
    }
    this.onResize.emit();
  }

  clearValidationsResidenceForm(): void {
    if (this.residenceForm.get("street")) {
      this.residenceForm.get("street")?.clearValidators();
      this.residenceForm.get("street")?.setValue("");
      this.residenceForm.get("street")?.updateValueAndValidity();
    }

    if (this.residenceForm.get("streetNumber")) {
      this.residenceForm.get("streetNumber")?.clearValidators();
      this.residenceForm.get("streetNumber")?.setValue("");
      this.residenceForm.get("streetNumber")?.updateValueAndValidity();
    }

    if (this.residenceForm.get("state_city")) {
      this.residenceForm.get("state_city")?.clearValidators();
      this.residenceForm.get("state_city")?.setValue("");
      this.residenceForm.get("state_city")?.updateValueAndValidity();
    }

    if (this.residenceForm.get("colony")) {
      this.residenceForm.get("colony").clearValidators();
      this.residenceForm.get("colony")?.setValue("");
      this.residenceForm.get("colony").updateValueAndValidity();
    }

    if (this.residenceForm.get("zip")) {
      this.residenceForm.get("zip").clearValidators();
      this.residenceForm.get("zip").setValue("");
      this.residenceForm.get("zip").updateValueAndValidity();
    }

    if (this.residenceForm.get("municipality")) {
      this.residenceForm.get("municipality").clearValidators();
      this.residenceForm.get("municipality")?.setValue("");
      this.residenceForm.get("municipality").updateValueAndValidity();
    }
  }

  setValidationsResidenceForm(): void {
    if (this.residenceForm.get("street")) {
      this.residenceForm.get("street").setValidators([Validators.required]);
      this.residenceForm.get("street").updateValueAndValidity();
    }

    if (this.residenceForm.get("streetNumber")) {
      this.residenceForm.get("streetNumber").setValidators([Validators.required]);
      this.residenceForm.get("streetNumber").updateValueAndValidity();
    }

    if (this.residenceForm.get("state_city")) {
      this.residenceForm.get("state_city").setValidators([Validators.required]);
      this.residenceForm.get("state_city").updateValueAndValidity();
    }

    if (this.residenceForm.get("colony")) {
      this.residenceForm.get("colony").setValidators([Validators.required]);
      this.residenceForm.get("colony").updateValueAndValidity();
    }

    if (this.residenceForm.get("zip")) {
      this.residenceForm.get("zip").setValidators([Validators.required]);
      this.residenceForm.get("zip").updateValueAndValidity();
    }

    if (this.residenceForm.get("municipality")) {
      this.residenceForm.get("municipality").setValidators([Validators.required]);
      this.residenceForm.get("municipality").updateValueAndValidity();
    }
  }

  public addResidenceFormsElements() {
    (this.additionalDataForm.get("taxDomicile") as FormGroup).addControl(
      "street",
      new FormControl("", [Validators.required])
    ),
      (this.additionalDataForm.get("taxDomicile") as FormGroup).addControl(
        "streetNumber",
        new FormControl("", [Validators.required])
      ),
      (this.additionalDataForm.get("taxDomicile") as FormGroup).addControl(
        "state_city",
        new FormControl({ value: "", disabled: true }, [Validators.required])
      ),
      (this.additionalDataForm.get("taxDomicile") as FormGroup).addControl(
        "colony",
        new FormControl("", [Validators.required])
      ),
      (this.additionalDataForm.get("taxDomicile") as FormGroup).addControl(
        "zip",
        new FormControl("", [
          Validators.required,
          Validators.pattern(/^([0-9][0-9][0-9][0-9][0-9])$/),
        ])
      ),
      (this.additionalDataForm.get("taxDomicile") as FormGroup).addControl(
        "municipality",
        new FormControl({ value: "", disabled: true }, [Validators.required])
      );

    if (this.additionalDataForm.get('mailment').get('bill').value == "no") {
      this.clearValidationsResidenceForm();
    } else {
      this.setValidationsResidenceForm();
    }
  }

  public emptyValues() {
    this.residenceForm.get("street").setValue("");
    this.residenceForm.get("streetNumber").setValue("");
    this.residenceForm.get("state_city").setValue("");
    this.residenceForm.get("colony").setValue("");
    this.residenceForm.get("zip").setValue("");
    this.residenceForm.get("municipality").setValue("");
  }

  public previousStep() {
    this.onPreviousStep.emit();
  }
  public isFieldInvalid(field: string) {
    return (
      this.additionalDataForm.get(field)?.touched &&
      this.additionalDataForm.get(field).invalid
    );
  }
  public isFieldInvalidResidence(field: string) {
    return (
      this.residenceForm.get(field)?.touched &&
      this.residenceForm.get(field).invalid
    );
  }

  public setAdditionalDataForm() {
    this.additionalDataForm.controls.agreements
      .get("remanents")
      .setValue(this.fullform.attributes.additionalData.agreements.remanents);
    this.additionalDataForm.controls.agreements
      .get("publicity_checkBox")
      .setValue(
        this.fullform.attributes.additionalData.agreements.publicity_checkBox
      );
    this.additionalDataForm.controls.agreements
      .get("information_checkBox")
      .setValue(
        this.fullform.attributes.additionalData.agreements.information_checkBox
      );
    this.additionalDataForm.controls.agreements
      .get("contracts")
      .setValue(this.fullform.attributes.additionalData.agreements.contracts);
    this.additionalDataForm.controls.agreements
      .get("payment_type")
      .setValue(
        this.fullform.attributes.additionalData.agreements.payment_type
      );
    this.additionalDataForm.controls.mailment
      .get("account_state")
      .setValue(this.fullform.attributes.additionalData.mailment.account_state);

    this.additionalDataForm.controls.mailment
      .get("medio_contacto_publicidad")
      .setValue(
        this.fullform.attributes.additionalData.mailment
          .medio_contacto_publicidad
      );
    this.additionalDataForm.controls.mailment
      .get("bill")
      .setValue(this.fullform.attributes.additionalData.mailment.bill);
    this.additionalDataForm.controls.mailment
      .get("rfc")
      .setValue(this.fullform.attributes.additionalData.mailment.rfc);
    this.additionalDataForm.controls.mailment
      .get("regimen")
      .setValue(this.fullform.attributes.additionalData.mailment.regimen);
    this.additionalDataForm.controls.mailment
      .get("name_f")
      .setValue(this.fullform.attributes.additionalData.mailment.name_f);
    this.additionalDataForm.controls.mailment
      .get("last_name_pf")
      .setValue(this.fullform.attributes.additionalData.mailment.last_name_pf);
    this.additionalDataForm.controls.mailment
      .get("last_name_mf")
      .setValue(this.fullform.attributes.additionalData.mailment.last_name_mf);

    this.additionalDataForm.controls.residence.setValue(
      this.fullform.attributes.additionalData.residence
    );

    this.display = true;
    this.addResidenceFormsElements();
    if (this.fullform.attributes.additionalData.residence != "other") {
      let taxDomicile = this.fullform.attributes.additionalData.taxDomicile;
      console.log(taxDomicile["colony"]);
      this.residenceForm.get("zip").setValue(taxDomicile["zip"]);
      this.residenceForm.get("street").setValue(taxDomicile["street"]);
      this.residenceForm
        .get("streetNumber")
        .setValue(taxDomicile["streetNumber"]);
      this.residenceForm.get("state_city").setValue(taxDomicile["state_city"]);
      this.residenceForm.get("colony").setValue(taxDomicile["colony"]);
      this.getPostalCodeInformation(taxDomicile["zip"]);
      this.residenceForm
        .get("municipality")
        .setValue(taxDomicile["municipality"]);
    } else {
      this.emptyValues();
    }
    this.onResize.emit();
  }

  public getPostalCodeInformation(zipCode: string | number) {
    console.log("entreblur");
    // Clean options and selected option
    this.suburbs = [];
    // this.residenceForm.get("colony").setValue("");

    this.residenceForm.get("zip").setErrors({
      mexicanzip: null,
    });

    this.residenceForm.get("zip").updateValueAndValidity();
    if (this.residenceForm.get("zip").valid) {
      return this.HirDataService.Postal(zipCode).subscribe({
        next: (result) => {
          console.log("result", result[0]);
          // if (result.mensaje != "OK") {
          //   this.residenceForm.get("zip").setErrors({
          //     mexicanzip: true,
          //   });
          //   console.log(this.residenceForm.get("zip"));
          //   return;
          // }
          this.suburbs = result[0].colonias;

          this.residenceForm.get("municipality").setValue(result[0].ciudad);
          this.residenceForm.get("state_city").setValue(result[0].estado);
          this.showSuburbsForm = true;
          this.onResize.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }
}
