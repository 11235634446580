import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SwiperComponent } from 'swiper/angular';

import { BasePage } from 'src/app/pages/base-page/base-page';

import { DocumentModel } from 'src/app/models/document.model';

@Component({
  selector: 'legal-representative-form',
  templateUrl: './legal-representative-form.component.html',
  styleUrls: ['./legal-representative-form.component.scss'],
})
export class LegalRepresentativeComponent extends BasePage implements OnInit {

  @ViewChild('swiper2', { static: false }) stepperForm: SwiperComponent;

  @Input() status: string = null;
  @Input() formInProgress: any = null;

  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onResize: EventEmitter<any> = new EventEmitter();

  public formInProgressPFisicaFormat: any = null;

  private initialSlide = 0;
  public EMBEBEDSLIDEOPTS = {
    speed: 400,
    direction: "horizontal",
    autoHeight: true,
    allowTouchMove: false,
    initialSlide: this.initialSlide,
  };

  public steps = [
    this.translate.instant('CLIENT_DATA'),
    this.translate.instant('ECONOMIC_ACTIVITY'),
    this.translate.instant('BENEFICIARIES'),
    this.translate.instant('REFERENCES'),
    this.translate.instant('ADDITIONAL_DATA'),
    this.translate.instant('ADDITIONAL_HEALTH_DATA'),
  ];
  public stepsValidation: boolean[];
  public embebedForms:any[] = [];
  public addressFormGroupFromGeneralData: FormGroup;

  public currentStep: string;

  public stepperOptions = {
    mode: 0,
    subform: 4,
  }

  constructor(
    private injector: Injector,
  ) {
    super(injector);

    this.currentStep = this.steps[this.initialSlide];
    
    this.stepsValidation = [];
    for (let i = 0; i < this.steps.length; i++) {
      this.stepsValidation.push(false);
    }
  }

  ngOnInit() {
    console.log('this.formInProgress');
    console.log(this.formInProgress);
    if( this.formInProgress?.legalRepresentative ) {
      this.formInProgressPFisicaFormat = {
        attributes: Object.assign({}, this.formInProgress.legalRepresentative)
      };
    }
    console.log(this.formInProgressPFisicaFormat);
  }

  enableMenuSwipe(): boolean {
    return true;
  }

  public sendForm() {
    let areAllStepsValidated = this.stepsValidation.reduce( (result, stepValue) => result && stepValue );

    let data = this.convertFormsDataToBackendFormat();
    console.log(data)

    this.onValidation.emit({
      'validationResult': areAllStepsValidated,
      'form': data,
    });
  }

  public previousStep() {
    this.onPreviousStep.emit();
  }

  public validateEmbebedForm( res: any ) {
    this.stepsValidation[this.steps.indexOf(this.currentStep)] = res.validationResult;

    if( res.validationResult ) {
      if(this.indexOfCurrentStep === 0) this.addressFormGroupFromGeneralData = res.generalForm.get('address');
      
      this.embebedForms[this.indexOfCurrentStep] = res.form;
      this.sendForm();

      // Next steps isn't called when it's the Last Embed Form
      if( this.indexOfCurrentStep == this.steps.length - 1 )  return;

      return this.nextStepEmbebedForm();
    }
  }

  public changeEmbedSlide(index: number) {
    this.currentStep = this.steps[index];
    this.stepperForm.swiperRef.slideTo(index);
    this.resize();
  }

  public nextStepEmbebedForm() {
    this.changeEmbedSlide( this.steps.indexOf(this.currentStep) + 1 );
  }

  public previousStepEmbebedForm() {
    this.changeEmbedSlide( this.steps.indexOf(this.currentStep) - 1 );
  }

  public resize() {
    setTimeout( () => {
        this.stepperForm.swiperRef.updateAutoHeight()
        this.onResize.emit();
      },
        200
    );
  }

  public get indexOfCurrentStep() {
    return this.steps.indexOf(this.currentStep);
  }

  public convertFormsDataToBackendFormat(): any {
    return Object.assign(
      {},
      this.embebedForms[0],
      { "income_profile": this.embebedForms[1] },
      { "beneficiaries": this.embebedForms[2] },
      { "references": this.embebedForms[3] },
      { "additionalData": this.embebedForms[4] },
      { "healthData": this.embebedForms[5] },
      { "documents": this.embebedForms[6] }
    );
  }

}
