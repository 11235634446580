import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/pages/base-page/base-page';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from "@ionic/angular";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss'],
  providers: [SocialSharing],
})
export class PdfComponent extends BasePage implements OnInit {
  enableMenuSwipe(): boolean {
    throw new Error('Method not implemented.');
  }
  pdf;
  pdfName;
  pdfClean
  isPhone: boolean = false;
  constructor(
    injector:Injector,
    private sanitizer: DomSanitizer,
    private plt: Platform,
    private socialSharing: SocialSharing
    ) {
    super(injector);
  }

  ngOnInit() {
    this.pdfClean=this.sanitizer.bypassSecurityTrustResourceUrl(this.pdf);
    this.isPhone = this.plt.is("cordova");
  }
  public dismiss(){
    this.modalCtrl.dismiss()
  }

  downloadPDF(): void{
    this.socialSharing.share(null, null, this.pdfClean.changingThisBreaksApplicationSecurity, null);
  }

}
