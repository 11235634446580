import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import CustomValidators from "src/app/utils/validators/custom-validators";
import FormUtils from "src/app/utils/forms/form-utils";

import { BasePage } from "src/app/pages/base-page/base-page";
import { PostalCodeService } from "src/app/services/postal-code.service";
import { HirDataService } from "src/app/services/hir-data.service";
@Component({
  selector: "general-data",
  templateUrl: "./general-data.component.html",
  styleUrls: ["./general-data.component.scss"],
})
export class GeneralDataComponent extends BasePage implements OnInit {
  @Input() formInProgress: any = null;

  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onResize: EventEmitter<any> = new EventEmitter();

  public generalDataForm: FormGroup;
  public formUtils = FormUtils;
  regimenList = "";
  public suburbs: any[];
  public showSuburbsForm: boolean = false;

  enableMenuSwipe(): boolean {
    return true;
  }

  constructor(
    private formBuilder: FormBuilder,
    private postalCodeService: PostalCodeService,
    private injector: Injector,
    private HirDataService: HirDataService
  ) {
    super(injector);
    this.buildForm();
  }

  ngOnInit() {
    this.HirDataService.LogIn().subscribe((response) => {
      console.log(response);
      localStorage.setItem("token", response);
    });

    this.HirDataService.GetRegimen(1).subscribe((data) => {
      console.log(data);
      this.regimenList = data;
    });
    // this.getPostalCodeInformation( 98615 );

    // Load form's data when is provided
    if (this.formInProgress !== null) this.fillForm();
  }

  private buildForm() {
    this.generalDataForm = this.formBuilder.group(
      {
        name: ["", [Validators.required]],
        rfc: ["", [Validators.required, CustomValidators.rfcValidator]],
        placeConstitution: ["", [Validators.required]],
        dateConstitution: ["", [Validators.required]],
        dateOperationsBegin: ["", [Validators.required]],
        regimen: ["", [Validators.required]],
        firstNameMainShareholder: ["", [Validators.required]],
        firstLastNameMainShareholder: ["", [Validators.required]],
        secondLastNameMainShareholder: ["", [Validators.required]],
        denominacionFiscal:["", [Validators.required]],
        regimenCapitalFiscal:["", [Validators.required]],
        nombreComercialFiscal:["", [Validators.required]],
        legalRepresentative: this.formBuilder.group({
          firstName: ["", [Validators.required]],
          firstLastName: ["", [Validators.required]],
          secondLastName: ["", []],
        }),
        address: this.formBuilder.group({
          street: ["", [Validators.required]],
          streetNumber: ["", []],
          zipCode: [
            "",
            [
              Validators.required,
              Validators.pattern(/^[0-9]+$/),
              Validators.minLength(5),
              Validators.maxLength(5),
            ],
          ],
          suburb: ["", [Validators.required]],
          municipality: [{ value: "", disabled: true }, [Validators.required]],
          state: [{ value: "", disabled: true }, [Validators.required]],
        }),
      },
      {
        validators: CustomValidators.validateEntityRFCWithDate(
          "rfc",
          "dateConstitution",
          "rfcdate"
        ),
      }
    );
  }

  private fillForm(): void {
    for (const [key, control] of Object.entries(
      this.generalDataForm.controls
    )) {
      // Embebed cases
      if (key === "legalRepresentative") {
        // Especial case for legalRepresentative, because in backend is saved in "legalRepresentativeSummary"
        for (const [key2, control2] of Object.entries(
          (control as FormGroup).controls
        )) {
          control2.setValue(this.formInProgress[`${key}Summary`][key2]);
        }

        continue;
      } else if (key === "address") {
        // Embed case
        for (const [key2, control2] of Object.entries(
          (control as FormGroup).controls
        )) {
          control2.setValue(this.formInProgress[key][key2]);
        }

        continue;
      }
      // Normal fill
      control.setValue(this.formInProgress[key]);
    }

    // Get and set PostalCode information
    this.getPostalCodeInformation(this.formInProgress["address"]["zipCode"]);
    this.generalDataForm
      .get("address.suburb")!
      .setValue(this.formInProgress["address"]["suburb"]);
    this.generalDataForm
      .get("address.externalNumber")!
      .setValue(this.formInProgress["address"]["externalNumber"]);
    this.generalDataForm
      .get("address.streetNumber")!
      .setValue(this.formInProgress["address"]["streetNumber"]);
  }

  public validateForm() {
    this.generalDataForm.markAllAsTouched();
    let validationResult = this.generalDataForm.valid;

    this.onValidation.emit({
      validationResult: validationResult,
      form: this.generalDataForm.getRawValue(),
    });
  }

  public getPostalCodeInformation(zipCode: string | number) {
    // Clean options and selected option
    this.suburbs = [];
    this.generalDataForm.get("address.suburb")!.setValue("");
    this.generalDataForm.get("address.municipality")!.setValue("");
    this.generalDataForm.get("address.state")!.setValue("");

    if (this.generalDataForm.get("address.zipCode")!.valid) {
      return this.HirDataService.Postal(zipCode).subscribe({
        next: (result) => {
          // if( result.mensaje !== "OK" ) return this.generalDataForm.get('address.zipCode')!.setErrors( {mexicanzip: true} );

          this.suburbs = result[0].colonias;
          this.generalDataForm
            .get("address.municipality")!
            .setValue(result[0].ciudad);
          this.generalDataForm.get("address.state")!.setValue(result[0].estado);
          this.showSuburbsForm = true;
          this.onResize.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  public get legalRepresentativeFormGroup(): FormGroup {
    return this.generalDataForm.get("legalRepresentative") as FormGroup;
  }

  public get addressFormGroup(): FormGroup {
    return this.generalDataForm.get("address") as FormGroup;
  }
}
