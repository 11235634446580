import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import * as Parse from "parse";
import { Postal } from "./interfaces/Postal.interface";
import { Profesion } from "./interfaces/Profesion.interface";
import { Paises } from "./interfaces/Paises.interface";
@Injectable({
  providedIn: "root",
})
export class HirDataService {
  constructor(private http: HttpClient) {}

  GetProfesiones(): Observable<Profesion[]> {
    return this.http.get<any>(
      `${environment.serverHost}/externalRequest/getprofesion`
    );
  }
  GetGiro(): Observable<any> {
    return this.http.get<any>(
      `${environment.serverHost}/externalRequest/getGiro`
    );
  }
  GetPuestos(): Observable<any> {
    return this.http.get<any>(
      `${environment.serverHost}/externalRequest/getPuestos`
    );
  }

  GetRegimen(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.serverHost}/externalRequest/getRegimen`,
      { persona: data }
    );
  }
  GetPaises(): Observable<Paises[]> {
    return this.http.get<any>(
      `${environment.serverHost}/externalRequest/getPaises`
    );
  }

  Postal(data: any): Observable<Postal> {
    console.log("codigo postal", typeof data);
    return this.http
      .post<any>(`${environment.serverHost}/externalRequest/getPostal`, {
        postal: data,
      })
      .pipe(
        retry(3), // Intentará la solicitud hasta 3 veces en caso de error
        catchError((error) => {
          console.error("Se alcanzó el máximo número de intentos.", error);
          throw error; // Puedes manejar el error de otra manera si es necesario
        })
      );
  }

  LogIn(): Observable<any> {
    return this.http
      .post<any>(
        `${environment.serverHost}/externalRequest/Login`,
        environment.credencialesLogin
      )
      .pipe(
        retry(3), // Intentará la solicitud hasta 3 veces en caso de error
        catchError((error: any) => {
          console.error("Se alcanzó el máximo número de intentos.", error);
          throw error; // Puedes manejar el error de otra manera si es necesario
        })
      );
  }
}
