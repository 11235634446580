import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

import { BasePage } from "src/app/pages/base-page/base-page";
import { seguro } from "src/app/utils/statics/dl-expediente";
import {
  questions1,
  questions1FormGroups,
  step1Form,
  step1FormExtraQuestions,
} from "src/app/utils/statics/dl-expediente";
import CustomValidators from "src/app/utils/validators/custom-validators";
import { getMaxDate, getMinDate } from "src/app/utils/validators/validators";
@Component({
  selector: "step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["./step1.component.scss"],
})
export class Step1Component extends BasePage implements OnInit {
  public step1Form: FormGroup;
  public diseasesTable: any;
  public arrayseguro = seguro;
  public maxDate: string = getMaxDate();
  public minDate: string = getMinDate();
  @Input() form: any;
  @Input() status: string;
  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onResize = new EventEmitter<any>();
  @Output() onPreviousStepUpper: EventEmitter<any> = new EventEmitter();
  public questions1: string[] = questions1;
  questions1FormGroups = questions1FormGroups;

  constructor(public fb: FormBuilder, private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.step1Form = this.fb.group({
      Question_A: ["", [Validators.required]],
      Question_A_E: [""],
      Question_B: [false],
      Question_C: [false],
      Question_D: [false],
      Question_E: [false],
      Question_F: [false],
      Question_G: [false],
      Question_H: [false],
      Question_I: [false],
      Question_J: [false],
      Question_K: [false],
      otherDisease: [""],
      dateDisease: [""],
      medicine: [""],
      dose: [""],
      height: ["", [Validators.required]],
      weight: ["", [Validators.required]],
      Question_L: ["", [Validators.required]],
      kg_less: [""],
      kg_max: [""],
      cause: [""],
      Question_M: [""],
      Question_O: [""], //Deporte
      Question_P: [""], //frecuencia
      Question_Q: [""], //tipo
      Employment: ["", [Validators.required]],

      job: ["", [Validators.required]],
      job_description: [""],
      high_risk_activity: ["", [Validators.required]],
      insurance: ["", [Validators.required]],
      insurance_name: [""],
      alcoholic_drinks: ["", [Validators.required]],
      alcoholic_type: [""],
      alcoholic_quantity: [""],
      frecuency: [""],
      smoke: ["", [Validators.required]],
      smoke_type: [""],
      smoke_Quantity: [""],
      smoke_Frecuency: [""],
      smoke_Quit_Date: [""],
      smoke_Quit_Reason: [""],
      travel: ["", [Validators.required]],
      travel_Frecuency: [""],
      diseasesTable: this.fb.array([]),
    });

    this.step1Form.get("Question_O")?.valueChanges.subscribe((newValue: string) => {
      if (newValue === null || newValue === undefined || newValue === "") {
        this.step1Form.get("Question_P")?.clearValidators();
        this.step1Form.get("Question_Q")?.clearValidators();
      } else {
        this.step1Form.get("Question_P")?.setValidators([Validators.required]);
        this.step1Form.get("Question_Q")?.setValidators([Validators.required]);
      }

      // Actualiza los campos de forma reactiva
      this.step1Form.get("Question_P")?.updateValueAndValidity();
      this.step1Form.get("Question_Q")?.updateValueAndValidity();
    });

    this.step1Form.get("insurance")?.valueChanges.subscribe((newValue) => {
      newValue == "Si"
        ? this.step1Form
          .get("insurance_name")
          ?.setValidators([Validators.required])
        : this.step1Form.get("insurance_name")?.clearValidators();

      this.step1Form.get("insurance_name")?.updateValueAndValidity();
    });

    this.diseasesTable = this.step1Form.get("diseasesTable") as FormArray;

    if (
      this.status == "inProgress" &&
      this.form?.attributes?.healthData?.step1
    ) {
      this.setStep1Form();
    }
  }

  enableMenuSwipe(): boolean {
    return true;
  }

  //add healt details's question
  public addDisease( index: number ):void {

    const rows = this.fb.group({
        question_N: [ index + 1, [Validators.required] ],
        question_Name: [ "", [Validators.required] ],
        question_date: [ "", [Validators.required] ],
        question_duration: [ "", [Validators.required] ],
        question_currentState: [ "", [Validators.required] ],
    });

    this.diseasesTable.push( rows );
    
    if ( this.diseasesTable.length > 1 ) {
      
      this.diseasesTable.controls.sort( ( firstElement, secElement ) => {
        const questionN_A = firstElement.get('question_N').value;
        const questionN_B = secElement.get('question_N').value;
    
        // order by question_N value
        if ( questionN_A < questionN_B ) {
          return -1;
        } else if ( questionN_A > questionN_B ) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    this.onResize.emit();
  }

  public removeDisease( questionN: number ): void {

    /*
      search inside diseasesTable the element whwere ('question_N') value
      is equals to question_N
      delete a elemt from diseasesTable by field ('question_N') value
    */
    const index = this.diseasesTable.controls.findIndex(
      ( control ) => control.get('question_N').value === ( questionN + 1 )
    );

    if ( index !== -1 ) {
      this.diseasesTable.removeAt( index );
    }

    this.onResize.emit();
  }

  public changeToggle( indexE: number ): void {
    //go through all the questions to find the one that was deleted and mark it "No" or "false".
    
    this.questions1FormGroups.forEach( 
      ( question: string, index: number ) => {

        if ( indexE === ( index + 1 ) ) {
          this.step1Form.get( question ).setValue( false );
        }
    });
  }

  public changeQuestion( event: CustomEvent, index: number): void {
    
    //if the toogle chanche to "true" add to diseasesTable 
    //else toogle is "false" romove from diseasesTable
    if ( event.detail.checked ) {
      this.addDisease( index );
    } else {
      this.removeDisease( index );
    }

    this.updateSize();
  }

  public seter(): void {
    if (this.step1Form.get("Question_O")?.value == "") {
      this.step1Form.get("Question_P")?.setValue("");
      this.step1Form.get("Question_O")?.setValue("");
      this.step1Form.get("Question_Q")?.setValue("");
    }

    if (this.step1Form.get("insurance")!.value == "No") {
      this.step1Form.get("insurance_name")!.setValue("");
    }
  }

  public updateSize(): void {
    // update DOM
    this.onResize.emit();
  }

  //next step (step2)
  public validateForm(): void {
    this.step1Form.markAllAsTouched();
    this.onResize.emit();
    let validationResult = this.step1Form.valid;
    this.onValidation.emit({
      index: 0,
      validationResult: validationResult,
      form: this.step1Form.value,
    });
    this.onResize.emit();
  }

  public isFieldInvalid(field: string): boolean {
    return (
      this.step1Form.get(field)?.touched && this.step1Form.get(field)?.invalid
    );
  }

  //check of the question are valids
  public isQuestionValid( field: string, index: number ): boolean {
    return (
      this.diseasesTable.controls.at( index ).get( field ).touched &&
      this.diseasesTable.controls.at( index ).get( field ).invalid
    );
  }

  // come back previous step
  public previousStepUpper(): void {
    this.onPreviousStepUpper.emit();
    this.onResize.emit();
  }

  // hiden´s field validators
  public CHANGE_HELD_POSITIONS(selection: CustomEvent): void {
    
    if (selection.detail.value === 'yes') {
      this.step1Form.get("Question_A_E")?.setValidators([
        Validators.required,
        CustomValidators.namesStreetValidator
      ]);
      
      this.step1Form.get("Question_A_E")?.setValue('');
    } else {
      this.resetFiled("Question_A_E");
    }

    this.step1Form.updateValueAndValidity();
    this.updateSize(); 
  }

  public CHANGE_ALCOHOLICS_DRINKS( selection: CustomEvent ): void {
    if (selection.detail.value === 'yes') {
      this.step1Form.get("alcoholic_type")?.setValidators([
        Validators.required, 
        CustomValidators.namesStreetValidator
      ]);
      this.step1Form.get("alcoholic_quantity")?.setValidators([Validators.required]);
      this.step1Form.get("frecuency")?.setValidators([Validators.required]);
      
      
    } else {
      this.resetFiled("alcoholic_type");
      this.resetFiled("alcoholic_quantity");
      this.resetFiled("frecuency");
    }

    this.step1Form.updateValueAndValidity();
    this.updateSize();
  }

  public CHANGE_QYESTION_L( selection: CustomEvent ): void {

    if (selection.detail.value === 'yes') {
      this.step1Form.get("kg_less")?.setValidators([Validators.required]);
      this.step1Form.get("kg_max")?.setValidators([Validators.required,]);
      this.step1Form.get("cause")?.setValidators([
        Validators.required, 
        CustomValidators.namesStreetValidator
      ]);
      this.step1Form.get("Question_M")?.setValidators([
        Validators.required, 
        CustomValidators.namesStreetValidator
      ]);
    } else {
      this.resetFiled("kg_less");
      this.resetFiled("kg_max");
      this.resetFiled("cause");
      this.resetFiled("Question_M");
    }
    this.step1Form.updateValueAndValidity();
    this.updateSize();
  }

  public CHANGE_SMOKE( selection: CustomEvent ): void {

    if (selection.detail.value === 'yes') {
      this.step1Form.get("smoke_type")?.setValidators([
        Validators.required, 
        CustomValidators.namesStreetValidator
      ]);
      this.step1Form.get("smoke_Quantity")?.setValidators([Validators.required]);
      this.step1Form.get("smoke_Frecuency")?.setValidators([Validators.required,]);
    } else {
      this.resetFiled("smoke_type");
      this.resetFiled("smoke_Quantity");
      this.resetFiled("smoke_Frecuency");
    }
    this.step1Form.updateValueAndValidity();
    this.updateSize();
  }

  public CHANGE_TRAVEL( selection: CustomEvent ): void {

    if (selection.detail.value === 'yes') {
      this.step1Form.get("travel_Frecuency")?.setValidators([Validators.required]);
    } else {
      this.resetFiled("travel_Frecuency");
    }
    this.step1Form.updateValueAndValidity();
    this.updateSize();
  }

  private resetFiled(filed: string): void {
    this.step1Form.get(filed)?.clearValidators();
    this.step1Form.get(filed)?.setValue(null);
  }

  private setStep1Form(): void {
    for (let i = 0; i < step1Form.length; i++) {
      if (this.step1Form.controls[step1Form[i]] != undefined) {
        this.step1Form.controls[step1Form[i]].setValue(
          this.form.attributes.healthData.step1[step1Form[i]]
        );
      }
    }
    this.getQuestionDetails();
  }

  private getQuestionDetails(): void {

    //chargue from db
    const questions = this.form?.attributes?.healthData.step1.diseasesTable;

    //scrolls through the health details questions and adds them to 
    // the list automatically with validators and values.
    if ( questions ) {
      questions.forEach( 
        ( question ) => {
          
          const rows = this.fb.group({
            question_N: [ question.question_N, [Validators.required] ], // here refers to the above question number
            question_Name: [ question.question_Name, [Validators.required] ],
            question_date: [ question.question_date, [Validators.required] ],
            question_duration: [ question.question_duration, [Validators.required] ],
            question_currentState: [ question.question_currentState, [Validators.required] ],
          });

          this.diseasesTable.push( rows );
      });
    }
  }
  
}
