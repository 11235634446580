import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  Injector,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { User } from "src/app/services/user-service";
import FormUtils from "src/app/utils/forms/form-utils";
import { BasePage } from "src/app/pages/base-page/base-page";
import { ParseFile } from "src/app/services/parse-file-service";
import { DocumentModel } from "src/app/models/document.model";
import { DataFile } from "../upload-file/data.interface";
import { PopoverController } from "@ionic/angular";
import { PopoverErrorComponent } from "src/app/components/popover-error/popover-error.component";
import { FileType } from "../upload-file/upload-file.component";
import Swal from "sweetalert2";

/* STATUS FOR DOCUMENTS
  0.- No Uploaded
  1.- Uploaded
  2.- Rejected
  3.- Done
*/

@Component({
  selector: "documentation-form",
  templateUrl: "./documentation-form.component.html",
  styleUrls: ["./documentation-form.component.scss"],
})
export class DocumentationFormComponent extends BasePage implements OnInit, OnChanges {
  @Input() documentsMap: Map<string, DocumentModel[]>;
  @Input() status: String;
  @Input() documentsInProgress: DocumentModel[] = null;
  @Input() form: any;

  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onResize = new EventEmitter<any>();
  @Output() scrollTop = new EventEmitter<any>();
  // Constant for fileType of the files to upload
  public fileTypeForInputs: FileType = FileType.BOTH;
  public uploadedFiles: ParseFile[] = [];
  public formUtils = FormUtils;
  public user: User;
  public statusCodes = ["no-uploaded", "uploaded", "rejected", "accepted"];
  public cont = 0;
  public optionsFormGroup = new FormGroup({});

  constructor(
    private injector: Injector,
    private popoverController: PopoverController
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.status != "Rejected") {
      this.buildOptionsFormGroup();
    }
  }

  ngOnInit() {
    this.buildOptionsFormGroup();

    console.log(this.documentsInProgress);
    console.log(this.documentsMap);
    // Load form's data when is provided
    if (this.documentsInProgress) {
      this.loadSavedDocuments();
      this.fillDocuments();
    }
  }

  private loadSavedDocuments() : void {
    const documentsInProgressWithProperty: DocumentModel[] = this.documentsInProgress.map(doc => {
      if (doc.name == "IDENTIFICATION_DOCUMENTS") {
        return {
          ...doc,
          options: ["INE", "PASSPORT"],
          description: "",
        };
      } else if (doc.name == "INCOME_STATEMENTS") {
        return {
          ...doc,
          options: [
            "PAYROLL_RECEIPTS",
            "PENSION_RECEIPTS",
            "PROFIT_SHARE_ADVANCE_RECEIPTS",
            "RECEIPT_SALARIES_ASSIMILATED",
            "FEE_RECEIPTS",
            "LABOR_PROOF",
            "PROOF_RETIREMENT",
            "LABOR_CONTRACT",
            "SERVICE_CONTRACT",
            "SAT_ANNUAL_STATEMENT",
            "PROVISIONAL_TAX_RETURN_SAT",
            "US_CANADIAN_TAX_RETURN",
            "OPINION_COMPLIANCE_SAT",
            "INCOME_INVOICES",
            "LEASE_AGREEMENT",
            "TAX_RECEIPTS_LEASE",
            "DEED_LEASED_PROPERTY",
            "CONCESSION_TITLE_PASSENGER_TRANSPORTATION",
            "CAB_CONCESSION",
            "BANK_STATEMENTS",
            "ACCOUNTANTS_LETTER",
            "LETTER_UNDER_PROTESTATION_OF_TRUTH",
            "BUSINESS_LICENSE",
            "MERCHANTS_CERTIFICATE",
            "EXTERNAL_SOCIOECONOMIC_STUDY",
            "BANK_STATEMENT",
          ],
          description: "",
        };
      } else if (doc.name == "CREDIT_BUREAU") {
        return {
          ...doc,
          options: ["Buro_credito"],
          description: "",
        };
      }
    });
    
    this.documentsMap.set('', documentsInProgressWithProperty);
  }
 
  enableMenuSwipe(): boolean {
    return true;
  }

  public validateForm() {
    this.optionsFormGroup.markAllAsTouched();
    this.onResize.emit();

    let validationResult =
      this.optionsFormGroup.valid && this.areAllDocumentsUploaded;
    let result = this.convertFormsDataToBackendFormat();

    if (!validationResult)
      this.focusInputWithError("upload-file-container no-uploaded-file");

    this.onValidation.emit({
      validationResult: validationResult,
      form: result,
    });
  }

  public previousStep() {
    this.onPreviousStep.emit();
  }

  public fileAction(map: string, index: number, file: DataFile) {
    // Is Deleted
    if (file.parseFile === null)
      return (this.documentsMap.get(map)[index].status = 0);
    // Is Uploaded
    this.onResize.emit();
    console.log(this.cont);
    console.log(file.bandera);
    if (!file.bandera) {
      const newDocument: DocumentModel = {
        name: this.documentsMap.get(map)[index].name,
        parseFile: file.parseFile,
        status: 1,
        selectedType: this.documentsMap.get(map)[index].selectedType,
        options: this.documentsMap.get(map)[index].options,
        comment: "",
        description: "",
        Permission: this.documentsMap.get(map)[index].Permission,
      };

      this.documentsMap.get(map).push(newDocument);
      this.cont += 1;
    } else {
      console.log("map", this.documentsMap.get(map));
      this.documentsMap.get(map)[index].parseFile = file.parseFile;
      this.documentsMap.get(map)[index].status = 1;
      this.cont += 1;
    }
  }
  public getDocumentStatus(DocumentModel: DocumentModel): string {
    switch (DocumentModel.status) {
      case 0:
        return "FILE_NOT_UPLOADED";
      case 1:
        return "FILE_UPLOADED_2";
      case 2:
        return "FILE_REJECTED";
      case 3:
        return "FILE_ACCEPTED";
      default:
        return "FILE_NOT_UPLOADED";
    }
  }

  private convertFormsDataToBackendFormat() {
    let res: DocumentModel[] = [];

    for (let DocumentModelArray of this.documentsMap.values()) {
      DocumentModelArray.forEach((DocumentModel) => {
        res.push(Object.assign({}, DocumentModel));

        delete res[res.length - 1].options;
        delete res[res.length - 1].description;
      });
    }

    return res;
  }

  private buildOptionsFormGroup() {
    for (let documentModelArray of this.documentsMap.values()) {
      documentModelArray.forEach((documentModel, index) => {
        if (documentModel.options.length > 0) {
          console.log(documentModel);
          if (documentModel.options.length > 0) {
            if (documentModel.name == "CREDIT_BUREAU") {
              this.optionsFormGroup.addControl(
                documentModel.name,
                new FormControl(documentModel.selectedType!)
              );
            }
            this.optionsFormGroup.addControl(
              documentModel.name,
              new FormControl(documentModel.selectedType!, Validators.required)
            );
          }
          // this.optionsFormGroup.addControl(
          //   documentModel.name,
          //   new FormControl(documentModel.selectedType, Validators.required)
          // );
          // index > 2
          //   ? this.optionsFormGroup.addControl(
          //       `{documentModel.name}`,
          //       new FormControl(documentModel.selectedType, Validators.required)
          //     )
          //   : this.optionsFormGroup.addControl(
          //       documentModel.name,
          //       new FormControl(documentModel.selectedType, Validators.required)
          //     );
        }
      });
    }
  }

  public fillDocuments(): void {
    for (const documentInProgress of this.documentsInProgress) {
      let documentFound = this.getDocumentByName(
        documentInProgress.name,
        this.documentsMap
      );

      if (documentFound == null) continue;

      documentFound.parseFile = documentInProgress.parseFile;
      documentFound.status = documentInProgress.status;
      documentFound.comment = documentInProgress.comment;
      documentFound.selectedType = documentInProgress.selectedType;
      console.log("---------------------------------");
      console.log(documentInProgress);
      this.optionsFormGroup
        .get(documentFound.name)
        ?.setValue(documentFound.selectedType ?? "");
    }
  }

  private getDocumentByName(
    name: string,
    documentsMap: Map<string, DocumentModel[]>
  ): DocumentModel {
    for (const documentMap of documentsMap.values()) {
      for (const document of documentMap) {
        if (name == document.name) return document;
      }
    }

    return null;
  }

  private buildFormGroupwithValues() {
    for (let documentModelArray of this.documentsMap.values()) {
      documentModelArray.forEach((documentModel) => {
        console.log(documentModel);
        if (documentModel.options.length > 0) {
          if (documentModel.name == "CREDIT_BUREAU") {
            this.optionsFormGroup.addControl(
              documentModel.name,
              new FormControl(documentModel.selectedType!)
            );
          }
          this.optionsFormGroup.addControl(
            documentModel.name,
            new FormControl(documentModel.selectedType!, Validators.required)
          );
        }
      });
    }
  }

  public optionSelected(map: string, index: number, optionSelected: string) {
    this.documentsMap.get(map)[index].selectedType = optionSelected;
  }

  public get areAllDocumentsUploaded(): boolean {
    for (let [_, DocumentModelArray] of this.documentsMap) {
      for (let DocumentModel of DocumentModelArray) {
        console.log(DocumentModel);
        if (DocumentModel.status !== 1) {
          if (DocumentModel.Permission == true) {
            return true;
          }
          console.log("entre");
          return false;
        }
      }
    }

    return true;
  }

  public focusInputWithError(classErrorName: string): void {
    // Asynchronous due to the delay of the function markAllAsTouched().
    setTimeout(() => {
      let errors = document.getElementsByClassName(classErrorName);

      if (errors.length > 0) document.getElementById(errors.item(0).id).focus();
    }, 50);
  }

  async presentPopover(ev: any, comment: string) {
    const popover = await this.popoverController.create({
      component: PopoverErrorComponent,
      event: ev,
      translucent: true,
      keyboardClose: true,
      componentProps: {
        title: this.translate.instant("WHY_DOCUMENT_REJECTED"),
        comment: comment,
      },
    });
    return await popover.present();
  }
}
