import {
  Component,
  DoCheck,
  OnChanges,
  Injector,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { Entity } from "src/app/services/entity_type.service";
import Swal from "sweetalert2";
import { User } from "src/app/services/user-service";
import { BasePage } from "src/app/pages/base-page/base-page";
declare function app(parameter1: any, parameter2: any): any;
declare function getImageVar(): any;

@Component({
  selector: "incode",
  templateUrl: "./incode.component.html",
  styleUrls: ["./incode.component.scss"],
})
export class IncodeComponent extends BasePage implements OnInit {
  enableMenuSwipe(): boolean {
    throw new Error("Method not implemented.");
  }
  public container;
  public canDismiss = false;
  public imageID;
  public user: User;
  public languaje;
  public ruta: any;
  constructor(
    injector: Injector,
    public modalCtrl: ModalController,
    private entity: Entity
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.container = document.getElementById("camera-container") as HTMLElement;
    this.languaje = localStorage.getItem("language");
    this.ruta = this.languaje == "en" ? "/en/" : "/";
    const session = await app(this.container, environment.envIncode);
    console.log(session);

    const checkDataIncode = setInterval(() => {
      this.imageID = getImageVar();

      if (this.imageID !== undefined) {
        if (this.imageID == "NOT_IDENTICAL") {
          clearInterval(checkDataIncode);
          this.ErrorAlert();
        } else {
          this.user = User.getCurrent();
          console.log(this.user);
          var idUser = this.user.id;
          console.log(this.user.id);
          console.log(idUser);
          this.entity.setImagesIndividual(this.imageID, idUser, session.token);
          clearInterval(checkDataIncode);
          this.sweetAlert();
        }
      }
    }, 1000);
  }

  public sweetAlert() {
    Swal.fire({
      title: this.translate.instant("IMAGES_SEND_TITLE"),
      text: this.translate.instant("IMAGES_SEND_TEXT"),
      icon: "success",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true,
      heightAuto: false,
      customClass: {
        popup: "fade-in",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.modalCtrl.dismiss();
        this.navigateTo(`.${this.ruta}`).then(() => {
          window.location.reload();
        });
      }
    });
  }

  public ErrorAlert() {
    Swal.fire({
      title: this.translate.instant("MATCH_FACE_ERROR"),
      text: this.translate.instant("MATCH_FACE_ERROR"),
      icon: "error",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true,
      heightAuto: false,
      customClass: {
        popup: "fade-in",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.modalCtrl.dismiss();
        this.navigateTo(`.${this.ruta}mi-credito/`).then(() => {
          window.location.reload();
        });
      }
    });
  }
}
