import { NgModule } from "@angular/core";
import {
  HttpClient,
  HttpClientModule,
  HttpClientXsrfModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { Camera } from "@ionic-native/camera/ngx";
import { Device } from "@ionic-native/device/ngx";
import { Facebook } from "@ionic-native/facebook/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { NativeAudio } from "@ionic-native/native-audio/ngx";
import { SignInWithApple } from "@ionic-native/sign-in-with-apple/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { HeaderColor } from "@ionic-native/header-color/ngx";
import { SafariViewController } from "@ionic-native/safari-view-controller/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { AdMob } from "@admob-plus/ionic";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";

const icons = [faFacebookF, faTwitter, faWhatsapp];
library.add(...icons);

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { IonicStorageModule } from "@ionic/storage";
import { ImgFallbackModule } from "ngx-img-fallback";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { WalkthroughPageModule } from "./pages/walkthrough/walkthrough.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

import { NgxStripeModule } from "ngx-stripe";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  FacebookLoginProvider,
} from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";

import localeAr from "@angular/common/locales/ar";
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";

import { FileOpener } from "@ionic-native/file-opener/ngx";
import { SharedModule } from "./shared.module";
import { ContactPageModule } from "./pages/contact/contact.module";
import { CookiesBannerComponent } from "src/app/pages/cookies-banner/cookies-banner.component";
import { CookiesPoliciesComponent } from "src/app/pages/cookies-policies/cookies-policies.component";
import { AuthInterceptor } from "./interceptors/authHicasa.interceptor";
import { GoogleTagManagerModule } from "angular-google-tag-manager";

registerLocaleData(localeEs, "es");
registerLocaleData(localeAr, "en");
registerLocaleData(localeAr, "ar");

@NgModule({
  declarations: [
    AppComponent,
    CookiesBannerComponent,
    CookiesPoliciesComponent,
  ],
  entryComponents: [],
  imports: [
    SharedModule,
    ContactPageModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientXsrfModule,
    IonicModule.forRoot({
      backButtonText: "",
    }),
    GoogleTagManagerModule.forRoot({
      id: "GTM-TXCGSVNC",
    }),
    AppRoutingModule,
    ImgFallbackModule,
    WalkthroughPageModule,
    SocialLoginModule,
    NgxStripeModule.forRoot(environment.stripePublicKey),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    AdMob,
    Camera,
    Device,
    NativeAudio,
    SignInWithApple,
    Facebook,
    HeaderColor,
    InAppBrowser,
    Geolocation,
    StatusBar,
    SocialSharing,
    SplashScreen,
    SafariViewController,
    AppVersion,
    OneSignal,
    GooglePlus,
    Diagnostic,
    FileOpener,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.fbId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
