import { Injectable } from "@angular/core";
import { User } from "./user-service";
import * as Parse from "parse";

@Injectable({
  providedIn: "root",
})
export class Users extends Parse.User {
  public user: User;

  constructor() {
    super();
  }

  static getInstance() {
    return this;
  }

  loadAll(): Promise<Parse.User[]> {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query(Parse.User);
      query.find().then(
        (data: Parse.User[]) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  loadVendors2(): Promise<Parse.User[]> {
    return new Promise((resolve, reject) => {
      this.user = User.getCurrent();
      if (this.user) {
        let query = new Parse.Query(Parse.User);
        query.equalTo("profile", "vendor");
        query.equalTo("reference", this.user.id);
        query.find().then(
          (data: Parse.User[]) => resolve(data),
          (error) => reject(error)
        );
      } else {
        let query = new Parse.Query(Parse.User);
        query.equalTo("profile", "vendor");
        query.find().then(
          (data: Parse.User[]) => resolve(data),
          (error) => reject(error)
        );
      }
    });
  }

  loadVendors(): Promise<Parse.User[]> {
    this.user = User.getCurrent();
    let params = {
      profile: "vendor",
      reference: "",
    };
    if (this.user) {
      params.reference = this.user.id;
    }
    return Parse.Cloud.run("getUsersMarketplace", params);
  }

  loadCustomers(params: any = {}): Promise<Parse.User[]> {
    this.user = User.getCurrent();
    params.profile = "customer";
    if (!params.ref) {
      params.reference = this.user.id;
    } else {
      params.reference = params.ref;
    }
    console.log("parametros de loadcustomer", params);
    return Parse.Cloud.run("getUsersMarketplace", params);
  }

  loadOne(params: any = {}): Promise<Parse.User[]> {
    return Parse.Cloud.run("loadOne", params);
    // return new Promise((resolve, reject) => {
    //   let query = new Parse.Query(Parse.User);
    //   if (params.ref) {
    //     query.equalTo("objectId", params.ref);
    //   }
    //   if (params.id) {
    //     query.equalTo("objectId", params.id);
    //   }
    //   if (params.email) {
    //     query.equalTo("email", params.email);
    //   }
    //   query.find().then(
    //     (data: Parse.User[]) => resolve(data),
    //     (error) => reject(error)
    //   );
    // });
  }

  loadOneCloud(params: any = {}): Promise<Parse.User[]> {
    return Parse.Cloud.run("getOneUser", params);
  }

  getAllDevelopmentsByEntity(params: any = {}): Promise<Parse.User[]> {
    console.log("params in the service", params);

    return Parse.Cloud.run("getAllDevelopmentsByEntity", params);
  }

  get name(): string {
    return this.get("name");
  }

  set name(val) {
    this.set("name", val);
  }

  get email(): string {
    return this.get("email");
  }

  set email(val) {
    this.set("email", val);
  }

  get username(): string {
    return this.get("username");
  }

  set username(val) {
    this.set("username", val);
  }

  get status(): string {
    return this.get("status");
  }

  set status(val) {
    this.set("status", val);
  }

  get type(): string {
    return this.get("type");
  }

  set type(val) {
    this.set("type", val);
  }

  get password(): string {
    return this.get("password");
  }

  set password(val) {
    this.set("password", val);
  }

  get reference(): any {
    return this.get("reference");
  }

  set reference(val) {
    this.set("reference", val);
  }

  get reference2(): any {
    return this.get("reference2");
  }

  set reference2(val) {
    this.set("reference2", val);
  }

  get phone(): string {
    return this.get("phone");
  }

  set phone(val) {
    this.set("phone", val);
  }

  get photo(): any {
    return this.get("photo");
  }

  set photo(val) {
    this.set("photo", val);
  }

  get profile(): any {
    return this.get("profile");
  }

  set profile(val) {
    this.set("profile", val);
  }

  get entity(): any {
    return this.get("entity");
  }

  set entity(val) {
    this.set("entity", val);
  }

  get key(): any {
    return this.get("key");
  }

  set key(val) {
    this.set("key", val);
  }
  get lastname1(): any {
    return this.get("lastname1");
  }

  set lastname1(val) {
    this.set("lastname1", val);
  }

  get lastname2(): any {
    return this.get("lastname2");
  }

  set lastname2(val) {
    this.set("lastname2", val);
  }

  get authData(): any {
    return this.get("authData");
  }

  set authData(val) {
    this.set("authData", val);
  }

  get iddevelopment(): any {
    return this.get("iddevelopment");
  }

  set iddevelopment(val) {
    this.set("iddevelopment", val);
  }

  get developmentName(): any {
    return this.get("developmentName");
  }

  set developmentName(val) {
    this.set("developmentName", val);
  }

  get businessname(): any {
    return this.get("businessname");
  }

  set businessname(val) {
    this.set("businessname", val);
  }

  get cellphone(): any {
    return this.get("cellphone");
  }

  set cellphone(val) {
    this.set("cellphone", val);
  }

  get tradename(): any {
    return this.get("tradename");
  }

  set tradename(val) {
    this.set("tradename", val);
  }

  get progress(): any {
    return this.get("progress");
  }

  set progress(val) {
    this.set("progress", val);
  }

  get property_cost(): any {
    return this.get("property_cost");
  }

  set property_cost(val) {
    this.set("property_cost", val);
  }

  get users(): any {
    return this.get("users");
  }

  set users(val) {
    this.set("users", val);
  }

  get total(): any {
    return this.get("total");
  }

  set total(val) {
    this.set("total", val);
  }
}

Parse.Object.registerSubclass("Users", Parse.User);
