import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { BasePage } from "src/app/pages/base-page/base-page";
import {
  questions3,
  questions3Formgroup,
  heritage,
  heritageMoney,
  step3Form,
} from "src/app/utils/statics/dl-expediente";
import { Entity } from "src/app/services/entity_type.service";

@Component({
  selector: "step3",
  templateUrl: "./step3.component.html",
  styleUrls: ["./step3.component.scss"],
})
export class Step3Component extends BasePage implements OnInit, OnChanges {
  enableMenuSwipe(): boolean {
    throw new Error("Method not implemented.");
  }
  public step3Form: FormGroup;
  @Input() form: any;
  @Input() status: string;
  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onResize = new EventEmitter<any>();
  public total = "0";
  questions3 = questions3;
  questions3Formgroup = questions3Formgroup;
  heritage = heritage;
  heritageMoney = heritageMoney;
  constructor(
    injector: Injector,
    public fb: FormBuilder,
    private entity: Entity
  ) {
    super(injector);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onResize.emit();
    this.step3Form = this.fb.group({
      Question_A: ["", [Validators.required]],
      Question_B: [{ value: "", disabled: true }],
      Question_C: ["", [Validators.required]],
      Question_D: ["", [Validators.required]],
      Question_E: ["$0"],
      Question_F: ["$0"],
      Question_G: ["", [Validators.required]],
      Question_H: [
        this.form?.attributes?.income_profile == undefined
          ? ""
          : this.form?.attributes?.income_profile?.number_depends,
        [Validators.required],
      ],
      Question_I: [{ value: "", disabled: true }],
      Question_I_A: ["", [Validators.required]],
      Question_I_B: ["", [Validators.required, this.minLoan(), this.maxLoan()]],
      Question_I_C: [
        { value: "180 Meses", disabled: true },
        [Validators.required],
      ],
      Question_I_D: [
        { value: "BIENES PROGRAMADOS, S.A. DE C.V.", disabled: true },
        [Validators.required],
      ],
      Question_I_E: [
        { value: "BIENES PROGRAMADOS, S.A. DE C.V.", disabled: true },
        [Validators.required],
      ],
      Question_I_F: [{ value: "", disabled: true }, [Validators.required]],
      Question_I_G: ["", [Validators.required]],
      heri_A: [{ value: "", disabled: true }],
      heri_B: [{ value: "", disabled: true }],
      heri_C: [{ value: "", disabled: true }],
      heri_D: [{ value: "", disabled: true }],
      heri_E: [{ value: "", disabled: true }],
      heri_F: [{ value: "", disabled: true }],
      heri_G: [{ value: "", disabled: true }],
      total: [{ value: "No aplica", disabled: true }, [Validators.required]],
    });

    if (
      this.status == "inProgress" &&
      this.form?.attributes?.healthData?.step3
    ) {
      this.setStep3Form();
    } else {
      this.getContractAmount();
    }
  }
  ngOnInit() {}

  public async getContractAmount(): Promise<any> {
    if (this.form != undefined) {
      let stu_so = await this.entity.get1Study(this.form.attributes.email);

      if (stu_so != undefined) {
        if (
          stu_so.get("property_cost") != undefined &&
          stu_so.get("property_cost") != ""
        ) {
          this.step3Form
            .get("Question_I_B")
            ?.setValue(
              this.changeInputFormatToMoneyFormatString(
                stu_so.get("property_cost").toString()
              )
            );
        }
      }
    }
  }

  private minLoan(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = parseFloat(control.value.replace(/[^\d.-]/g, ""));
      const minLoan = 250000;

      if (!isNaN(value) && value < minLoan) {
        return { minLoan: { value } };
      }
      return null;
    };
  }
  private maxLoan(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = parseFloat(control.value.replace(/[^\d.-]/g, ""));
      const maxLoan = 10000000;

      if (!isNaN(value) && value > maxLoan) {
        return { maxLoan: { value } };
      }
      return null;
    };
  }

  public previousStep() {
    this.onPreviousStep.emit({
      index: 2,
    });
    this.onResize.emit();
  }

  public validateFormUpper() {
    console.log(this.step3Form);
    this.step3Form.markAllAsTouched();
    this.onResize.emit();
    let validationResult = this.step3Form.valid;
    this.onValidation.emit({
      index: 2,
      validationResult: validationResult,
      form: this.step3Form.getRawValue(),
    });
    this.onResize.emit();
  }

  public isFieldInvalid(field: string) {
    
    return (
      this.step3Form.get(field)?.touched && this.step3Form.get(field)?.invalid
    );
  }

  public getValue(question: string) {
    if (question === "DURATION_AND_REPAYMENT_TERMS") {
      return "180 Meses";
    }
    if (question === "NAME_OF_LENDER" || question === "NAME_OF_BORROWER") {
      return "BIENES PROGRAMADOS, S.A. DE C.V.";
    }
  }

  public setStep3Form() {
    for (let j = 0; j < step3Form.length; j++) {
      this.step3Form
        .get([step3Form[j]])
        .setValue(this.form.attributes.healthData.step3[step3Form[j]]);
    }
  }

  public sum() {
    var totalAux = 0;
    for (var i = 0; i < heritageMoney.length; i++) {
      var amount = this.step3Form
        .get(heritageMoney[i])
        .value.replace(/[$ | \, ]/g, "");
      var inputValue: number = Number(amount);
      totalAux += inputValue;
    }
    this.total = this.changeInputFormatToMoneyFormatString(totalAux.toString());
    this.step3Form.get("total").setValue(this.total);
  }
}
