import {
  Component,
  EventEmitter,
  Injector,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";
import CustomValidators from "src/app/utils/validators/custom-validators";
import { BasePage } from "src/app/pages/base-page/base-page";
import { PostalCodeService } from "src/app/services/postal-code.service";
import { Entity } from "src/app/services/entity_type.service";
import { TypeDocuments } from "src/app/utils/statics/dl-expediente";
import { HirDataService } from "src/app/services/hir-data.service";
import { log } from "console";
import { Paises } from "src/app/services/interfaces/Paises.interface";
import { PopoverController } from "@ionic/angular";
import { PopoverErrorComponent } from "src/app/components/popover-error/popover-error.component";

@Component({
  selector: "app-general-data",
  templateUrl: "./general-data.component.html",
  styleUrls: ["./general-data.component.scss"],
})
export class PFGeneralDataComponent extends BasePage implements OnInit {
  enableMenuSwipe(): boolean {
    return true;
  }
  @Input() showPreviousButton: boolean = false;
  @Input() status: string;
  @Input() form: any;
  @Output() spouseFormEvent = new EventEmitter<any>();
  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onResize = new EventEmitter<any>();
  @Output() onPreviousStep = new EventEmitter<any>();
  @Input() user: any;
  idiomaSeleccionado = "";
  public generalDataForm: FormGroup;
  public spouseForm: any;
  public addressForm: any;
  public showD: boolean = false;
  public display = false;
  public serviceType: any;
  public propertyType: any;
  arrayPaises: Paises[];
  public finishedLoading = false;
  public suburbs: any[];
  public showSuburbsForm: boolean = false;
  public typePerson: any;
  public todayDate = new Date();
  public todayDateFormat: string = '';
  TypeDocuments = TypeDocuments;
  constructor(
    public alertController: AlertController,
    public fb: FormBuilder,
    public injector: Injector,
    private postalCodeService: PostalCodeService,
    private entity: Entity,
    private HirDataService: HirDataService,
    private TranslateService: TranslateService,
    private popoverController: PopoverController
  ) {
    super(injector);
  }

  ngOnInit() {
    this.todayDateFormat = this.getFormattedDate(this.todayDate);
    console.log(this.todayDate);
    this.typePerson = this.getQueryParams().person;
    this.typePerson = this.typePerson ?? this.form.attributes.type;
    this.typePerson == "4"
      ? (this.TypeDocuments = ["PASAPORTE", "DOCUMENTO_EXTRANJERO"])
      : null;
    // this.HirDataService.LogIn().subscribe((response) => {
    //   console.log(response);
    //   localStorage.setItem("token", response);
    // });

    this.idiomaSeleccionado = this.TranslateService.currentLang;
    this.HirDataService.GetPaises().subscribe((data) => {
      console.log(data);
      this.arrayPaises = data;
      this.finishedLoading = true;
    });
    console.log(this.user);

    this.generalDataForm = this.fb.group(
      {
        property_Type: [""],
        service_Type: [""],
        names: [
          { value: this.user.name, disabled: true },
          [Validators.required],
        ],
        last_name_p: [
          { value: this.user.lastname1, disabled: true },
          [Validators.required],
        ],
        last_name_m: [
          { value: this.user.lastname2, disabled: true },
          [Validators.required],
        ],
        age: ["", 
                [
                  Validators.required, 
                  CustomValidators.numberValidator
                ]
              ],
        rfc: [
          "",
          this.typePerson == "4"
            ? [CustomValidators.rfcValidatorFI]
            : [Validators.required, CustomValidators.rfcValidatorFI],
        ],
        birthday: ["", Validators.required],
        nacionality: [
          this.typePerson == "1" ? { value: "154", disabled: true } : "",
          Validators.required,
        ],
        birthplace: ["", Validators.required],
        gender: ["", Validators.required],
        curp: [
          "",
          this.typePerson == "4"
            ? []
            : [Validators.required, CustomValidators.curpValidator],
        ],
        id_type_of: ["", Validators.required],
        id_num_of: ["", Validators.required],
        civil_state: ["", Validators.required],
        address: this.fb.group({
          street: ["", [Validators.required]],
          streetNumber: ["", [Validators.required]],
          intStreetNumber: [""],
          zip: [
            "",
            [
              Validators.required,
              Validators.pattern(/^([0-9][0-9][0-9][0-9][0-9])$/),
              Validators.minLength(5),
              Validators.maxLength(5),
            ],
          ],
          state: [{ value: "", disabled: true }, Validators.required],
          municipality: [{ value: "", disabled: true }, Validators.required],
          colony: ["", Validators.required],
        }),
        movilPhone: [
          { value: this.user.cellphone, disabled: true },
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        phoneNumber: [
          { value: this.user.phone, disabled: true },
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        email: [
          { value: this.user.email, disabled: true },
          [Validators.required],
        ],
        spouseForm: this.fb.group([]),
      },
      {
        // Quitar validacion con fecha si es ext
        validators:
          this.typePerson != 4
            ? CustomValidators.validateIndividualRFCWithDate(
              "rfc",
              "birthday",
              "rfcdate"
            )
            : null,
      }
    );

    this.spouseForm = this.generalDataForm.get("spouseForm") as FormArray;

    if (this.status != "inProgress") {
      this.serviceTypeAlert();
      this.propertiesTypeAlert();
    } else {
      if (
        !this.form?.attributes?.property_Type ||
        !this.form?.attributes?.service_Type
      ) {
        this.serviceTypeAlert();
        this.propertiesTypeAlert();
      } else {
        this.setGeneralForm(this.form);
      }
    }
    this.finishedLoading = false;
  }

  addValidationRfc($event: any): void {
    // Si es extranjero y escribe rfc que agrege validacion con fecha
    if (this.typePerson == 4) {
      let value: string = $event.target.value;

      if (value.trim() != "") {
        this.generalDataForm.setValidators(
          CustomValidators.validateIndividualRFCWithDate(
            "rfc",
            "birthday",
            "rfcdate"
          )
        );
      } else {
        this.generalDataForm.clearValidators();
      }
      this.generalDataForm.updateValueAndValidity();
    }
  }

  async showPopover(event: Event): Promise<void> {
    const popover: any = await this.popoverController.create({
      component: PopoverErrorComponent,
      event: event,
      translucent: true,
      keyboardClose: true,
      componentProps: {
        title: this.translate.instant("NO_RFC"),
        comment: this.translate.instant("WEBSITE_RFC"),
        url: "https://consisa.com.mx/rfc "
      },
    });
    return await popover.present();
  }

  public addSpouseForm($event) {
    const value = $event.target.value;
    if (
      value == "SOLTERO" ||
      value == "DIVORCIADO" ||
      value == "UNIÓN_LIBRE " ||
      value == "VIUDO"
    ) {
      this.display = false;
      this.removeSpouseFormsElements();
    } else {
      this.display = true;
      this.addSpouseFormElements();
    }
    this.onResize.emit();
    this.spouseFormEvent.emit();
  }

  public addSpouseFormElements() {
    (this.generalDataForm.get("spouseForm") as FormGroup).addControl(
      "regime",
      new FormControl("", [Validators.required])
    ),
      (this.generalDataForm.get("spouseForm") as FormGroup).addControl(
        "spouse_name",
        new FormControl("", [Validators.required, CustomValidators.namesValidator])
      ),
      (this.generalDataForm.get("spouseForm") as FormGroup).addControl(
        "spouse_tel_home",
        new FormControl("", [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ])
      ),
      (this.generalDataForm.get("spouseForm") as FormGroup).addControl(
        "spouse_cel",
        new FormControl("", [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ])
      ),
      (this.generalDataForm.get("spouseForm") as FormGroup).addControl(
        "spouse_email",
        new FormControl("", [Validators.required, Validators.email])
      );
  }

  public removeSpouseFormsElements() {
    (this.generalDataForm.get("spouseForm") as FormGroup).removeControl(
      "regime"
    ),
      (this.generalDataForm.get("spouseForm") as FormGroup).removeControl(
        "spouse_name"
      ),
      (this.generalDataForm.get("spouseForm") as FormGroup).removeControl(
        "spouse_tel_home"
      ),
      (this.generalDataForm.get("spouseForm") as FormGroup).removeControl(
        "spouse_cel"
      ),
      (this.generalDataForm.get("spouseForm") as FormGroup).removeControl(
        "spouse_email"
      );
  }

  async serviceTypeAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translate.instant("SERVICE_TYPE_Q"),
      backdropDismiss: false,
      inputs: [
        {
          name: this.translate.instant("ACQUISITION"),
          type: "radio",
          label: this.translate.instant("ACQUISITION"),
          value: "ADQUISICIÓN",
          handler: () => { },
          checked: true,
        },

        {
          name: this.translate.instant("CONSTRUCTION2"),
          type: "radio",
          label: this.translate.instant("CONSTRUCTION2"),
          value: "CONSTRUCCIÓN",
          handler: () => { },
        },
        {
          name: this.translate.instant("REMODELING"),
          type: "radio",
          label: this.translate.instant("REMODELING"),
          value: "REMODELACIÓN",
          handler: () => { },
        },
        {
          name: this.translate.instant("EXPANSION"),
          type: "radio",
          label: this.translate.instant("EXPANSION"),
          value: "AMPLIACIÓN",
          handler: () => { },
        },
      ],
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: (data: any) => {
            this.serviceType = data;
            this.generalDataForm.get("service_Type").setValue(this.serviceType);
            this.confirmationAmountForContrating();
          },
        },
      ],
    });

    await alert.present();
  }

  async propertiesTypeAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translate.instant("PROPERTY_TYPE_Q"),
      backdropDismiss: false,
      inputs: [
        {
          name: this.translate.instant("HOUSE_APARTMENT"),
          type: "radio",
          label: this.translate.instant("HOUSE_APARTMENT"),
          value: "CASA_DEPARTAMENTO",
          handler: () => { },
          checked: true,
        },

        {
          name: this.translate.instant("OFFICE_COMMERCE"),
          type: "radio",
          label: this.translate.instant("OFFICE_COMMERCE"),
          value: "OFICINA_COMERCIO",
          handler: () => { },
        },

        {
          name: this.translate.instant("Local comercial"),
          type: "radio",
          label: this.translate.instant("Local comercial"),
          value: "LOCAL_COMERCIAL",
          handler: () => { },
        },
        {
          name: this.translate.instant("Terreno"),
          type: "radio",
          label: this.translate.instant("Terreno"),
          value: "TERRENO",
          handler: () => { },
        },
        {
          name: this.translate.instant("NOT_DEFINED"),
          type: "radio",
          label: this.translate.instant("NOT_DEFINED"),
          value: "NO_DEFINIDO ",
          handler: () => { },
        },
      ],
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: (data: any) => {
            this.propertyType = data;
            this.generalDataForm
              .get("property_Type")
              .setValue(this.propertyType);
          },
        },
      ],
    });

    await alert.present();
  }

  async confirmationAmountForContrating(): Promise<void> {
    if (this.user.email != undefined) {
      let stu_so: any = await this.entity.get1Study(this.user.email);

      if (stu_so != undefined && stu_so.get("property_cost") != ""  && stu_so.get("property_cost") != undefined) {
        const alert = await this.alertController.create({
          cssClass: "my-custom-class",
          header: this.translate.instant("VERIFY_AMOUNT"),
          message: this.translate.instant("AMOUNT_TO_CONTRACT"),
          backdropDismiss: false,
          inputs: [
            {
              placeholder: '$250,000',
              value: this.changeInputFormatToMoneyFormatString(stu_so.get("property_cost").toString()),
            },
          ],
          buttons: [
            {
              text: this.translate.instant("OK"),
              handler: (data: string) => {
                // Verificamos si no tiene formato incorrecto
                if (/^\$[0-9,]+$/.test(data[0])) {
                  // Seteamos el formato para que lo vea el usuario
                  alert.inputs = [
                    {
                      placeholder: '$250,000',
                      value: this.changeInputFormatToMoneyFormatString(data[0]),
                    },
                  ]

                  const numericAmount: number = parseInt(data[0].replace(/\D/g, ''));

                  // Verificamos si es minimo el monto
                  if (numericAmount > 250000) {
                    this.entity.updatePropetyCost(this.user.email, numericAmount);
                    return true;
                  } else {
                    alert.message = this.translate.instant("ERROR_AMOUNT");
                    return false;
                  }
                } else {
                  alert.message = this.translate.instant("ERROR_FORMAT");
                  return false;
                }
              },
            },
          ],
        });

        await alert.present();
      }
    }
  }

  public validateForm() {
    if (
      this.typePerson == "4" &&
      this.generalDataForm?.get("rfc")?.value.trim() == ""
    ) {
      this.generalDataForm?.get("rfc")?.setValue("");
    }
    console.log(this.generalDataForm);
    this.generalDataForm.markAllAsTouched();
    this.onResize.emit();
    let validationResult = this.generalDataForm.valid;

    this.onValidation.emit({
      index: 0,
      validationResult: validationResult,
      form: this.generalDataForm.getRawValue(),
      generalForm: this.generalDataForm,
    });
  }

  public isFieldInvalid(field: string) {
    return (
      this.generalDataForm.get(field)?.touched &&
      this.generalDataForm.get(field).invalid
    );
  }

  public isSpouseInvalid(field: string) {
    return (
      this.spouseForm.get(field)?.touched && this.spouseForm.get(field).invalid
    );
  }
  public isAddressInvalid(field: string) {
    return (
      this.generalDataForm.get("address." + field)?.touched &&
      this.generalDataForm.get("address." + field).invalid
    );
  }

  public setValidators() {
    if (this.generalDataForm.get("id_num_of").validator) {
      this.generalDataForm.get("id_num_of").clearValidators;
    }
    if (this.generalDataForm.get("id_type_of").value === "INE") {
      this.generalDataForm.get("id_num_of").setValue("");
      this.generalDataForm
        .get("id_num_of")
        .setValidators([Validators.required, CustomValidators.INEValidator]);
    }
    if (this.generalDataForm.get("id_type_of").value === "PASAPORTE") {
      this.generalDataForm.get("id_num_of").setValue("");
      this.generalDataForm
        .get("id_num_of")
        .setValidators([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^([A-Z|0-9]{10})$/),
        ]);
    }
    if (this.generalDataForm.get("id_type_of").value === "CEDULA") {
      this.generalDataForm.get("id_num_of").setValue("");
      this.generalDataForm
        .get("id_num_of")
        .setValidators([Validators.required]);
    }
  }

  // public getPostalCodeInformation(zipCode: string | number) {
  //   // Clean options and selected option
  //   this.suburbs = [];
  //   this.generalDataForm.get("address.colony").setValue("");

  //   this.generalDataForm.get("address.zip").setErrors({
  //     mexicanzip: null,
  //   });

  //   this.generalDataForm.get("address.zip").updateValueAndValidity();
  //   if (this.generalDataForm.get("address.zip").valid) {
  //     return this.postalCodeService
  //       .getPostalCodeInformation("" + zipCode)
  //       .subscribe({
  //         next: (result) => {
  //           if (result.mensaje != "OK") {
  //             this.generalDataForm.get("address.zip").setErrors({
  //               mexicanzip: true,
  //             });
  //             console.log(this.generalDataForm.get("address.zip"));
  //             return;
  //           }
  //           this.suburbs = result.respuesta.codigos_postales;
  //           this.generalDataForm
  //             .get("address.municipality")
  //             .setValue(this.suburbs[0].municipio);
  //           this.generalDataForm
  //             .get("address.state")
  //             .setValue(this.suburbs[0].estado);
  //           this.showSuburbsForm = true;
  //           this.onResize.emit();
  //         },
  //         error: (error) => {
  //           console.error(error);
  //         },
  //       });
  //   }
  // }
  public getPostalCodeInformation(zipCode: string | number) {
    // Clean options and selected option
    this.suburbs = [];
    this.generalDataForm.get("address.colony").setValue("");

    this.generalDataForm.get("address.zip").setErrors({
      mexicanzip: null,
    });

    this.generalDataForm.get("address.zip").updateValueAndValidity();
    if (this.generalDataForm.get("address.zip").valid) {
      return this.HirDataService.Postal(zipCode).subscribe({
        next: (result) => {
          console.log(result);
          // if (result.mensaje != "OK") {
          //   this.generalDataForm.get("address.zip").setErrors({
          //     mexicanzip: true,
          //   });
          //   console.log(this.generalDataForm.get("address.zip"));
          //   return;
          // }
          this.suburbs = result[0].colonias;
          this.generalDataForm
            .get("address.municipality")
            .setValue(result[0].ciudad);
          this.generalDataForm.get("address.state").setValue(result[0].estado);
          this.showSuburbsForm = true;
          this.onResize.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  public previousStep() {
    this.onPreviousStep.emit();
  }

  public setGeneralForm(form: any) {
    this.generalDataForm
      .get("property_Type")
      .setValue(form.attributes.property_Type);
    this.generalDataForm
      .get("service_Type")
      .setValue(form.attributes.service_Type);
    this.generalDataForm.get("names").setValue(form.attributes.names);
    this.generalDataForm
      .get("last_name_p")
      .setValue(form.attributes.last_name_p);
    this.generalDataForm
      .get("last_name_m")
      .setValue(form.attributes.last_name_m);
    this.generalDataForm.get("age").setValue(form.attributes.age);
    this.generalDataForm.get("rfc").setValue(form.attributes.rfc);
    this.generalDataForm.get("birthday").setValue(form.attributes.birthday);
    this.generalDataForm
      .get("nacionality")
      .setValue(form.attributes.nacionality);
    this.generalDataForm.get("nacionality").enable();

    this.generalDataForm.get("birthplace").setValue(form.attributes.birthplace);
    this.generalDataForm.get("gender").setValue(form.attributes.gender);
    this.generalDataForm.get("curp").setValue(form.attributes.curp);
    this.generalDataForm.get("id_type_of").setValue(form.attributes.id_type_of);
    this.generalDataForm.get("id_num_of").setValue(form.attributes.id_num_of);
    this.generalDataForm
      .get("civil_state")
      .setValue(form.attributes.civil_state);
    //Address

    this.generalDataForm
      .get("address.zip")
      .setValue(form.attributes.address.zip);
    this.generalDataForm
      .get("address.street")
      .setValue(form.attributes.address.street);
    this.generalDataForm
      .get("address.streetNumber")
      .setValue(form.attributes.address.streetNumber);
    this.generalDataForm
      .get("address.intStreetNumber")!
      .setValue(form.attributes.address.intStreetNumber);
    this.getPostalCodeInformation(form.attributes.address.zip);
    this.generalDataForm
      .get("address.colony")
      .setValue(form.attributes.address.colony);
    this.showSuburbsForm = true;
    //
    this.generalDataForm.get("movilPhone").setValue(form.attributes.movilPhone);
    this.generalDataForm
      .get("phoneNumber")
      .setValue(form.attributes.phoneNumber);
    this.generalDataForm.get("email").setValue(form.attributes.email);
    console.log(form.attributes.civil_state);

    //Spouse Form
    if (
      form.attributes.civil_state == "SOLTERO" ||
      form.attributes.civil_state == "DIVORCIADO" ||
      form.attributes.civil_state == "UNIÓN_LIBRE " ||
      form.attributes.civil_state == "VIUDO"
    ) {
    } else {
      this.addSpouseFormElements();
      this.generalDataForm
        .get("spouseForm.regime")
        .setValue(form.attributes.spouseForm.regime);
      this.generalDataForm
        .get("spouseForm.spouse_name")
        .setValue(form.attributes.spouseForm.spouse_name);
      this.generalDataForm
        .get("spouseForm.spouse_tel_home")
        .setValue(form.attributes.spouseForm.spouse_tel_home);
      this.generalDataForm
        .get("spouseForm.spouse_cel")
        .setValue(form.attributes.spouseForm.spouse_cel);
      this.generalDataForm
        .get("spouseForm.spouse_email")
        .setValue(form.attributes.spouseForm.spouse_email);
      this.display = true;
    }
  }

  getFormattedDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
