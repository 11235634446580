import { Injectable } from "@angular/core";
import * as Parse from "parse";

@Injectable({
  providedIn: "root",
})
export class Category extends Parse.Object {
  constructor() {
    super("Category");
  }

  static getInstance() {
    return this;
  }

  load(): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query(Category);
      query.equalTo("status", "Active");
      query.ascending("sort");
      query.doesNotExist("deletedAt");
      query.find().then(
        (data: Category[]) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  getCategories(params: any = {}): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query(Category);
      query.equalTo("status", "Active");
      if (params.entity == "" || params.entity == "cat") {
        query.equalTo("type", "1");
      } else if (params.entity == "dev") {
        query.equalTo("type", "2");
      } else {
        query.equalTo("type", "2");
        query.equalTo("idDeveloper", params.entity);
      }
      query.ascending("sort");
      query.doesNotExist("deletedAt");

      query.find().then(
        (data: Category[]) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  getCategory(params: any = {}): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query(Category);
      query.equalTo("objectId", params.id);
      query.find().then(
        (data: Category[]) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  getCategoryBySlug(slug: string): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query(Category);
      query.equalTo("slugSale", slug);
      query.find().then(
        (data: Category[]) => resolve(data),
        (error) => reject(error)
      );
    });
  }
  get metaDescription() {
    return this.get("metaDescription");
  }
  get metaTitle() {
    return this.get("metaTitle");
  }
  get metaH1() {
    return this.get("metaH1");
  }
  get title(): string {
    return this.get("title");
  }

  get description(): string {
    return this.get("description");
  }

  get icon() {
    return this.get("icon");
  }

  get image() {
    return this.get("image");
  }

  get imageThumb() {
    return this.get("imageThumb");
  }

  get placeCount() {
    return this.get("placeCount");
  }

  get slug() {
    return this.get("slug");
  }

  get geolocation() {
    return this.get("geolocation");
  }

  get start_date() {
    return this.get("start_date");
  }

  get finish_date() {
    return this.get("finish_date");
  }

  get idDeveloper() {
    return this.get("idDeveloper");
  }

  get gallery() {
    return this.get("gallery");
  }

  get status() {
    return this.get("status");
  }

  get canonical() {
    return this.get("canonical");
  }

  get address() {
    return this.get("address");
  }

  get units_sale() {
    return this.get("units_sale");
  }

  get units_allowed() {
    return this.get("units_allowed");
  }

  get isFeatured() {
    return this.get("isFeatured");
  }

  get type() {
    return this.get("type");
  }

  get video() {
    return this.get("video");
  }

  get key() {
    return this.get("key");
  }

  get amenities() {
    return this.get("amenities");
  }

  get stateMX() {
    return this.get("stateMX");
  }
  get slugSale() {
    return this.get("slugSale");
  }

  toString(): string {
    return this.title;
  }
}

Parse.Object.registerSubclass("Category", Category);
