import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { studiesArray } from "src/app/utils/statics/dl-expediente";
import { BasePage } from "src/app/pages/base-page/base-page";
import { typeHomeArray } from "src/app/utils/statics/dl-expediente";
import { commercialActivityArray } from "src/app/utils/statics/dl-expediente";
import { incomeTypeArray } from "src/app/utils/statics/dl-expediente";
import { actual_chargeArray } from "src/app/utils/statics/dl-expediente";
import { company_typeArray } from "src/app/utils/statics/dl-expediente";
import { incomeProfileAddress } from "src/app/utils/statics/dl-expediente";
import { PostalCodeService } from "src/app/services/postal-code.service";
import { Console } from "console";
import { HirDataService } from "src/app/services/hir-data.service";
import CustomValidators from "src/app/utils/validators/custom-validators";
import { HttpClient } from "@angular/common/http";
import * as pdfMake from "pdfmake/build/pdfmake";
import { Platform } from "@ionic/angular";
import { Profesion } from "src/app/services/interfaces/Profesion.interface";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: "app-income-profile-form",
  templateUrl: "./income-profile-form.component.html",
  styleUrls: ["./income-profile-form.component.scss"],
  providers: [SocialSharing],
})
export class IncomeProfileFormComponent extends BasePage implements OnInit {
  enableMenuSwipe(): boolean {
    throw new Error("Method not implemented.");
  }
  @Input() status: string;
  @Input() form: any;
  @Input() generalForm: any;
  @Input() user: any;
  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() addActivityEvent = new EventEmitter<any>();
  @Output() onResize = new EventEmitter<any>();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();

  public incomesForm: FormGroup;
  public validateFormTest: FormGroup;
  public activities: FormArray;
  public disable = false;
  idiomaSeleccionado = "";
  ncomProfileForm: any;
  profesionesArray: Profesion[];
  giro = "";
  studiesArray = studiesArray;
  typeHomeArray = typeHomeArray;
  commercialActivityArray = commercialActivityArray;
  incomeTypeArray = incomeTypeArray;
  incomeProfileAddress = incomeProfileAddress;
  actual_chargeArray = "";
  company_typeArray = "";
  logoData: any = null;
  pdfObj: any = null;

  public suburbs: any[];
  public showSuburbsForm: boolean = false;
  constructor(
    injector: Injector,
    public fb: FormBuilder,
    private postalCodeService: PostalCodeService,
    private HirDataService: HirDataService,
    private TranslateService: TranslateService,
    private http: HttpClient,
    private plt: Platform,
    private socialSharing: SocialSharing
  ) {
    super(injector);
  }

  ngOnInit() {
    this.idiomaSeleccionado = this.TranslateService.currentLang;

    this.HirDataService.GetProfesiones().subscribe((data) => {
      console.log(data);
      this.profesionesArray = data;
    });
    this.HirDataService.GetGiro().subscribe((data) => {
      console.log(data);
      this.company_typeArray = data;
    });
    this.HirDataService.GetPuestos().subscribe((data) => {
      console.log(data);
      this.actual_chargeArray = data;
    });

    this.incomesForm = this.fb.group({
      profesion: ["", [Validators.required]],
      studies: ["", [Validators.required]],
      mensual_incomes: [
        "",
        [Validators.required, CustomValidators.maxTenMillionValidator()],
      ],
      mensual_expenses: [
        "",
        [Validators.required, CustomValidators.maxTenMillionValidator()],
      ],
      number_depends: ["", [Validators.required, CustomValidators.maxFifteenValidator()]],
      typeHome: ["", [Validators.required]],
      commercialActivity: ["", [Validators.required]],
      incomeType: ["", [Validators.required]],

      activities: this.fb.array([]),
    });
    this.activities = this.incomesForm.get("activities") as FormArray;
    this.addActivity();

    // Nos traemos el cel del primer form
    // this.activities.controls[0]
    //   .get("company_cel")
    //   .setValue(this.form.attributes.movilPhone);
    this.loadLocalAssetToBase64();

    if (this.status == "inProgress" && this.form?.attributes?.income_profile) {
      this.setIncomeForm();
    }
  }

  public addActivity() {
    if (this.activities.length < 3 || this.disable == false) {
      const activityForm = this.fb.group({
        job_type: ["", [Validators.required]],
        income_type: ["", [Validators.required]],
        company: ["", [Validators.required, ]],
        actual_charge: ["", [Validators.required]],
        antique: ["", [Validators.required]],
        department: ["", [Validators.required, Validators.minLength(3)]],
        company_type: ["", [Validators.required]],
        boss_name: ["", [Validators.required]],
        ingresos: ["", [Validators.required, Validators.max(10000000)]],
        address: this.fb.group({
          company_address: ["", [Validators.required]],
          company_postal: [
            "",
            [
              Validators.pattern(/^([0-9][0-9][0-9][0-9][0-9])$/),
              Validators.minLength(5),
              Validators.maxLength(5),
            ],
          ],
          company_town: ["", [Validators.required]],
          company_colony: ["", [Validators.required]],
          company_state: [""],
          company_ext: ["", [Validators.required, CustomValidators.streetNumberValidator]],
          company_int: ["", CustomValidators.streetNumberValidator],
        }),
        company_tel: ["", [Validators.minLength(10), Validators.maxLength(10)]],
        company_switch: ["", [Validators.maxLength(10)]],

        company_email: ["", [Validators.email]],
        company_cel: ["", [Validators.minLength(10), Validators.maxLength(10)]],
      });
      this.activities.push(activityForm);
      this.onResize.emit();
    } else {
      this.disable = true;
      this.onResize.emit();
    }
  }

  public removeActivity(index: number) {
    this.activities.removeAt(index);

    if (this.disable == true && this.activities.length < 3) {
      this.disable = false;
    }
  }

  public firstForm(index: number): boolean {
    if (index != 0) {
      return true;
    }
    return false;
  }

  public previousStep() {
    this.onPreviousStep.emit();
  }

  loadLocalAssetToBase64(): void {
    this.http
      .get("./assets/img/header.png", { responseType: "blob" })
      .subscribe((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.logoData = reader.result;
        };
        reader.readAsDataURL(res);
      });
  }

  async getColony(zip: number, colonyToSearch: string): Promise<string> {
    // Hacemos petición de tomar la colonia
    try {
      const result = await this.HirDataService.Postal(zip).toPromise();
      const colony = result[0]?.colonias.find(
        (colony) => colony.coloniaId == colonyToSearch
      );

      return colony.descripcion;
    } catch (error) {
      console.error(error);
    }
  }

  public isAddressAvaliable(activity: any): boolean {
    // Desactivams el boton si la direccion no esta completa
    return (
      activity.value.address.company_address != "" &&
      activity.value.address.company_ext != "" &&
      activity.value.address.company_postal != "" &&
      activity.value.address.company_colony != ""
    );
  }

  public async downloadSE(activity: any): Promise<void> {
    let logo = { image: this.logoData, width: 100 };
    var Valor: any;

    let res: any = {};
    let addressData: any;

    if (this.form == undefined) {
      addressData = this.generalForm.value;
    } else {
      addressData = this.form.attributes;
    }

    res.client_name = this.user.name;
    res.last_name = this.user.lastname1;
    res.last_name2 = this.user.lastname2;
    res.street = addressData.address.street;
    res.no_outsite_inside = addressData.address.streetNumber;
    res.suburb = await this.getColony(
      addressData.address.zip,
      addressData.address.colony
    );
    res.postal_code = addressData.address.zip;
    res.cellphone = this.user.attributes.cellphone;
    res.phone = this.user.phone;
    res.company_street = activity.value.address.company_address;
    res.company_no_street = activity.value.address.company_ext;
    res.company_no_street = activity.value.address.company_int;
    res.company_suburb = await this.getColony(
      activity.value.address.company_postal,
      activity.value.address.company_colony
    );
    res.company_zip = activity.value.address.company_postal;

    console.log(
      "RESPONSE: ",
      res.client_name,
      res.last_name,
      res.last_name2,
      res.street,
      res.no_outsite_inside,
      res.suburb,
      res.postal_code,
      res.cellphone,
      res.phone
    );
    Valor = res;

    const docDefinition = {
      content: [
        {
          columns: [
            logo,
            {
              text: new Date().toLocaleDateString("es-ES"),
              alignment: "right",
            },
          ],
        },
        {
          text: "Formato para Solicitud de Estudio Socioeconómico",
          style: "header",
          fontSize: "15",
          alignment: "center",
        },

        {
          columns: [
            {
              width: "35%",
              text: "Nombre del cliente:",
              style: "subheader",
            },
            {
              table: {
                widths: ["85%"],

                body: [
                  [
                    {
                      text:
                        Valor.client_name +
                        " " +
                        Valor.last_name +
                        " " +
                        Valor.last_name2,
                    },
                  ],
                  [" "],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 0 : 0.6;
                },
                vLineWidth: function (i, node) {
                  return 0;
                },
              },
            },
          ],
          // optional space between columns
          columnGap: 5,
        },

        {
          columns: [
            {
              width: "35%",
              text: "Domicilio particular:",
              style: "subheader",
            },
            {
              table: {
                widths: ["85%"],
                body: [
                  [
                    {
                      text:
                        Valor.street +
                        " " +
                        Valor.no_outsite_inside +
                        ", " +
                        Valor.suburb +
                        ", " +
                        Valor.postal_code,
                    },
                  ],
                  [" "],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 0 : 0.6;
                },
                vLineWidth: function (i, node) {
                  return 0;
                },
              },
            },
          ],
          // optional space between columns
          columnGap: 5,
        },

        {
          columns: [
            {
              width: "35%",
              text: "Domicilio Empresa:",
              style: "subheader",
            },
            {
              table: {
                widths: ["85%"],
                body: [
                  [
                    {
                      text:
                        Valor.company_street +
                        " " +
                        Valor.company_no_street +
                        ", " +
                        Valor.company_suburb +
                        ", " +
                        Valor.company_zip,
                    },
                  ],
                  [" "],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 0 : 0.6;
                },
                vLineWidth: function (i, node) {
                  return 0;
                },
              },
            },
          ],
          // optional space between columns
          columnGap: 5,
        },

        {
          columns: [
            {
              width: "35%",
              text: "Teléfono móvil:",
              style: "subheader",
            },
            {
              table: {
                widths: ["85%"],
                body: [[{ text: Valor.cellphone }], [" "]],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 0 : 0.6;
                },
                vLineWidth: function (i, node) {
                  return 0;
                },
              },
            },
          ],
          // optional space between columns
          columnGap: 5,
        },

        {
          columns: [
            {
              width: "35%",
              text: "Teléfono particular:",
              style: "subheader",
            },
            {
              table: {
                widths: ["85%"],
                body: [[{ text: Valor.phone }], [" "]],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 0 : 0.6;
                },
                vLineWidth: function (i, node) {
                  return 0;
                },
              },
            },
          ],
          // optional space between columns
          columnGap: 5,
        },

        {
          columns: [
            {
              width: "35%",
              text: "Sucursal:",
              style: "subheader",
            },
            {
              table: {
                widths: ["85%"],
                body: [[" "], [" "]],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 0 : 0.6;
                },
                vLineWidth: function (i, node) {
                  return 0;
                },
              },
            },
          ],
          // optional space between columns
          columnGap: 5,
        },

        {
          columns: [
            {
              text: "Nombre y cuenta para el pago del Estudio Socioeconómico:",
              fontSize: 10,
              width: "70%",
              margin: [0, 15, 0, 10],
              bold: true,
            },
            {
              text: "Costo del estudio:",
              fontSize: 10,
              width: "30%",
              margin: [0, 15, 0, 10],
              bold: true,
            },
          ],
        },
        {
          columns: [
            {
              text: "Banco HSBC a nombre de Beatriz Ortiz Osorio ",
              fontSize: 10,
              width: "70%",
              // margin: [0, 5, 0, 5]
            },
            {
              text: "$1,400.00 pesos sin iva",
              fontSize: 10,
              width: "70%",
              //margin: [0, 5, 0, 5]
            },
            {
              text: "$1,400.00 pesos sin iva",
              fontSize: 10,
              width: "30%",
              // margin: [0, 5, 0, 5]
            },
          ],
        },
        {
          columns: [
            {
              text: "Transferencia: 021180040349249850 ",
              fontSize: 10,
              width: "70%",
              //margin: [0, 5, 0, 5]
            },
            {
              text: "$1,624.00 pesos con iva",
              fontSize: 10,
              width: "30%",
              // margin: [0, 5, 0, 5]
            },
          ],
        },
        {
          columns: [
            {
              text: "Depósito: 4034924985",
              fontSize: 10,
              width: "70%",
              // margin: [0, 5, 0, 5]
            },
          ],
        },
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 35, 0, 35],
        },
        subheader: {
          fontSize: 11,
          bold: true,
          margin: [40, 8, 0, 3],
        },
        text: {
          fontSize: 8,
        },
      },
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);

    console.log("1");

    if (this.plt.is("cordova")) {
      console.log("2a");
      this.pdfObj.getBase64((data: string) => {
        this.downloadFileOnCellphone(data, "application/pdf");
      });
    } else {
      console.log("2b");
      this.pdfObj.download();
    }
    console.log("3");
  }

  downloadFileOnCellphone(base64Data: string, mimeType: string) {
    // Construir la URL de datos para el archivo base64
    const dataUrl = `data:${mimeType};base64,${base64Data}`;

    // Compartir la URL para abrir en el navegador del cel
    this.socialSharing.share(null, null, dataUrl, null);
  }

  public validateForm() {
    console.log(this.incomesForm);

    this.incomesForm.markAllAsTouched();
    this.onResize.emit();
    let validationResult = this.incomesForm.valid;

    this.onValidation.emit({
      index: 1,
      validationResult: validationResult,
      form: this.incomesForm.value,
    });
  }

  public isFieldInvalid(field: string) {
    return (
      this.incomesForm.get(field)?.touched &&
      this.incomesForm.get(field).invalid
    );
  }

  public isActivitiesInvalid(activity: AbstractControl, field: string) {
    return activity.get(field)?.touched && activity.get(field).invalid;
  }

  public getPostalCodeInformation(
    activity: AbstractControl,
    zipCode: string | number
  ) {
    // Clean options and selected option
    this.suburbs = [];
    activity.get("address.company_postal").setErrors({
      mexicanzip: null,
    });

    activity.get("address.company_postal").updateValueAndValidity();
    if (activity.get("address.company_postal").valid) {
      return this.HirDataService.Postal(zipCode).subscribe({
        next: (result) => {
          // if (result.mensaje != "OK") {
          //   activity.get("address.company_postal").setErrors({
          //     mexicanzip: true,
          //   });
          //   return;
          // }
          this.suburbs = result[0].colonias;
          activity.get("address.company_town").setValue(result[0].ciudad);
          activity.get("address.company_state").setValue(result[0].estado);
          this.showSuburbsForm = true;
          this.onResize.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }
  public setIncomeForm() {
    var incomeProfile = this.form.attributes.income_profile;
    this.incomesForm.get("profesion").setValue(incomeProfile.profesion);
    this.incomesForm
      .get("commercialActivity")
      .setValue(incomeProfile.commercialActivity);
    this.incomesForm.get("incomeType").setValue(incomeProfile.incomeType);
    this.incomesForm
      .get("mensual_expenses")
      .setValue(incomeProfile.mensual_expenses);
    this.incomesForm
      .get("mensual_incomes")
      .setValue(incomeProfile.mensual_incomes);
    this.incomesForm
      .get("number_depends")
      .setValue(incomeProfile.number_depends);
    
    this.incomesForm.get("studies").setValue(incomeProfile.studies);
    this.incomesForm.get("typeHome").setValue(incomeProfile.typeHome);

    for (let i = 0; i < incomeProfile.activities.length; i++) {
      if (incomeProfile.activities.length > 1) {
        this.addActivity();
      }
      for (let index = 0; index < this.incomeProfileAddress.length; index++) {
        console.log(
          "datos",
          this.form.attributes.income_profile.activities[i][
          incomeProfileAddress[index]
          ]
        );
        console.log("data", incomeProfileAddress[index]);

        this.activities.controls[i]
          .get(incomeProfileAddress[index])
          .setValue(
            this.form.attributes.income_profile.activities[i][
            incomeProfileAddress[index]
            ]
          );
      }
      this.activities.controls[i]
        .get("address")
        .get("company_address")
        .setValue(
          this.form.attributes.income_profile.activities[i]["address"][
          "company_address"
          ]
        );
      this.activities.controls[i]
        .get("address")
        .get("company_ext")
        .setValue(
          this.form.attributes.income_profile.activities[i]["address"][
          "company_ext"
          ]
        );
      this.activities.controls[i]
        .get("address")
        .get("company_int")
        .setValue(
          this.form.attributes.income_profile.activities[i]["address"][
          "company_int"
          ]
        );
      this.activities.controls[i]
        .get("address")
        .get("company_postal")
        .setValue(
          this.form.attributes.income_profile.activities[i]["address"][
          "company_postal"
          ]
        );
      this.activities.controls[i]
        .get("address")
        .get("company_town")
        .setValue(
          this.form.attributes.income_profile.activities[i]["address"][
          "company_town"
          ]
        );
      this.activities.controls[i]
        .get("address")
        .get("company_colony")
        .setValue(
          this.form.attributes.income_profile.activities[i]["address"][
          "company_colony"
          ]
        );
      this.activities.controls[i]
        .get("address")
        .get("company_state")
        .setValue(
          this.form.attributes.income_profile.activities[i]["address"][
          "company_state"
          ]
        );
      this.getPostalCodeInformation(
        this.activities.controls[i],
        this.form.attributes.income_profile.activities[i]["address"][
        "company_postal"
        ]
      );
    }
  }
}
