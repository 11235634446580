import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BasePage } from "src/app/pages/base-page/base-page";
import CustomValidators from "src/app/utils/validators/custom-validators";
import FormUtils from "src/app/utils/forms/form-utils";
import { HirDataService } from "src/app/services/hir-data.service";
import { Paises } from "src/app/services/interfaces/Paises.interface";
@Component({
  selector: "aditional-info-form",
  templateUrl: "./aditional-info-form.component.html",
  styleUrls: ["./aditional-info-form.component.scss"],
})
export class AditionalInfoFormComponent extends BasePage implements OnInit {
  @Input() formInProgress: any = null;

  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onResize: EventEmitter<any> = new EventEmitter();
  arrayPaises: Paises[];
  public aditionalInfoForm: FormGroup;
  public formUtils = FormUtils;
  idiomaSeleccionado = "";
  company_typeArray = "";
  enableMenuSwipe(): boolean {
    return true;
  }

  constructor(
    private formBuilder: FormBuilder,
    private injector: Injector,
    private HirDataService: HirDataService,
    private TranslateService: TranslateService
  ) {
    super(injector);
    this.buildForm();
  }

  ngOnInit() {
    this.idiomaSeleccionado = this.TranslateService.currentLang;
    this.HirDataService.LogIn().subscribe((response) => {
      // console.log(response);
      localStorage.setItem("token", response);
    });
    this.HirDataService.GetGiro().subscribe((data) => {
      console.log(data);
      this.company_typeArray = data;
    });
    this.HirDataService.GetPaises().subscribe((data) => {
      console.log(data);
      this.arrayPaises = data;
    });

    // Load form's data when is provided
    if (
      this.formInProgress !== null &&
      this.formInProgress["mainActivity"] !== undefined
    )
      this.fillForm();
  }

  private buildForm() {
    this.aditionalInfoForm = this.formBuilder.group({
      legalRepresentative: this.formBuilder.group({
        phone: [
          "",
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        cellphone: [
          "",
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        email: ["", [Validators.required, Validators.email]],
      }),
      phone: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      other: ["", []],
      email: ["", [Validators.required, Validators.email]],
      residenceTime: ["", [Validators.required]],
      affiliates: this.formBuilder.array([this.createAffiliate()]),
      businessLine: ["", [Validators.required]],
      mainActivity: ["", [Validators.required]],
      members: this.formBuilder.array(
        [this.createMember()],
        [
          Validators.required,
          CustomValidators.validatePercentagesInFormArray(
            100,
            "participation",
            "totalassociatepercentage"
          ),
        ]
      ),
    });
  }

  private fillForm() {
    // Erase default empty controls for formArrays
    this.affiliateArray.clear();
    this.membersArray.clear();

    for (const [key, control] of Object.entries(
      this.aditionalInfoForm.controls
    )) {
      // Embed and especial cases
      if (key === "legalRepresentative") {
        // Especial case for legalRepresentative, because in backend is saved in "legalRepresentativeSummary"
        for (const [key2, control2] of Object.entries(
          (control as FormGroup).controls
        )) {
          control2.setValue(this.formInProgress[`${key}Summary`][key2]);
        }

        continue;
      } else if (key === "affiliates") {
        // Array case
        for (const affiliate of this.formInProgress[key]) {
          this.addAffiliate({
            name: affiliate["name"],
            location: affiliate["location"],
          });
        }

        continue;
      } else if (key === "members") {
        // Array case
        for (const member of this.formInProgress[key]) {
          this.addAssociate({
            name: member["name"],
            position: member["position"],
            participation: member["participation"],
          });
        }

        continue;
      }
      // Normal fill
      control.setValue(this.formInProgress[key]);
    }
  }

  public validateForm() {
    this.aditionalInfoForm.markAllAsTouched();
    let validationResult = this.aditionalInfoForm.valid;

    this.onValidation.emit({
      validationResult: validationResult,
      form: this.aditionalInfoForm.value,
    });
  }

  public previousStep() {
    this.onPreviousStep.emit();
  }

  public addAssociate(defaultData?: {
    name: string;
    position: string;
    participation: number;
  }) {
    this.membersArray.push(this.createMember(defaultData));
    this.onResize.emit();
  }

  public removeAssociate(index: number) {
    if (this.membersArray.length <= 1) return;

    this.membersArray.removeAt(index);
    this.onResize.emit();
  }

  private createMember(defaultData?: {
    name: string;
    position: string;
    participation: number;
  }): FormGroup {
    return this.formBuilder.group({
      name: [
        defaultData != null ? defaultData.name : "",
        [Validators.required],
      ],
      position: [
        defaultData != null ? defaultData.position : "",
        [Validators.required],
      ],
      participation: [
        defaultData != null ? defaultData.participation : 1,
        [Validators.required, Validators.min(1), Validators.max(100)],
      ],
    });
  }

  public get membersArray(): FormArray {
    return this.aditionalInfoForm.get("members") as FormArray;
  }

  /* BEGIN: Methods for Subsidiaries FormArray */
  private createAffiliate(defaultData?: { name: string; location: string }) {
    return this.formBuilder.group({
      name: [
        defaultData != null ? defaultData.name : "",
        [Validators.required],
      ],
      location: [
        defaultData != null ? defaultData.location : "",
        [Validators.required],
      ],
    });
  }

  public get affiliateArray() {
    return this.aditionalInfoForm.get("affiliates") as FormArray;
  }

  public addAffiliate(defaultData?: { name: string; location: string }) {
    this.affiliateArray.push(this.createAffiliate(defaultData));
    this.onResize.emit();
  }

  public removeAffiliate(index: number) {
    this.affiliateArray.removeAt(index);
    this.onResize.emit();
  }
  /* END: Methods for Subsidiaries FormArray */

  public get legalRepresentativeFormGroup() {
    return this.aditionalInfoForm.get("legalRepresentative") as FormGroup;
  }
}
