import {
  FormArray,
  FormGroup,
  ValidationErrors,
  Validators,
  AbstractControl,
} from "@angular/forms";

export default abstract class CustomValidators {
  static rfcValidator = Validators.pattern(
    /^([A-Z]{3})([0-9]{6})([A-Z0-9]{3})$/
  );
  static rfcValidatorFI = Validators.pattern(
    /^([A-Z]{4})([0-9]{6})([A-Z0-9]{3})$/
  );
  static curpValidator = Validators.pattern(
    /^([A-Z]{4})([0-9]{6})([H|M])([A-Z]{5})([0-9A-Z]{1})([0-9]{1})$/
  );
  static INEValidator = Validators.pattern(
    /^([A-Z]{6})([0-9]{8})([A-Z]{1})([0-9]{3})$/
  );

  static numberValidator = Validators.pattern(
    /^\b([1-9]|[1-9][0-9]|120)\b$/
  );

  static namesValidator = Validators.pattern(
    /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)*$/
  );

  static namesStreetValidator = Validators.pattern(
    /^[A-Za-zÀ-ÖØ-öø-ÿ0-9]+(?: [A-Za-zÀ-ÖØ-öø-ÿ0-9]+)*$/
  );

  static namesCompanyValidator = Validators.pattern(
    /^[A-Za-zÀ-ÖØ-öø-ÿ0-9]+(?: [A-Za-zÀ-ÖØ-öø-ÿ0-9]+)*$/
  );

  static streetNumberValidator = Validators.pattern(
    /^(\d+)([a-zA-Z]*)$/
  );

  static validateOnliText = Validators.pattern(
    /^[a-zA-Z0-9;,.?]+$/g
  );

  /**
   * Validates that the sum of the percentages contained in the `FormGroup`s that are contained in a `FormArray` equals 100%.
   *
   * ### Example
   * ```typescript
   * this.aditionalInfoForm = this.formBuilder.group({
   *      myArray: this.formBuilder.array(
   *          [],
   *          CustomValidators.validatePercentagesInFormArray( 100, 'percentage_field', 'total_percentage_error' )
   *      )
   * });
   * ```
   */
  static validatePercentagesInFormArray(
    quantity: number,
    controlName: string,
    errorName: string
  ) {
    return (formArray: FormArray): ValidationErrors => {
      let totalPercentage = 0;

      formArray.controls.forEach((control) => {
        totalPercentage += control.get(controlName).value;
      });

      if (totalPercentage != quantity) return { [errorName]: true };

      return null;
    };
  }

  /**
   * Validates that the sum of the percentages contained in a `FormGroup` equals 100%.
   *
   * ### Example
   * ```typescript
   *  this.aditionalInfoForm = this.formBuilder.group({
   *      percentage_sales_in_cash:       [ '', [Validators.required, Validators.min(0), Validators.max(100)] ],
   *      percentage_government_sales:    [ '', [Validators.required, Validators.min(0), Validators.max(100)] ],
   *      percentage_cash_sales:          [ '', [Validators.required, Validators.min(0), Validators.max(100)] ],
   *      percentage_intercompany_sales:  [ '', [Validators.required, Validators.min(0), Validators.max(100)] ],
   *      percentage_credit_sales:        [ '', [Validators.required, Validators.min(0), Validators.max(100)] ],
   *      percentage_sales_untracked:     [ '', [Validators.required, Validators.min(0), Validators.max(100)] ],
   *      percentage_export_sales:        [ '', [Validators.required, Validators.min(0), Validators.max(100)] ],
   *  }, { validator: CustomValidators.validatePercentagesInFormGroup(100, 'totalpercentageerror') }),
   * ```
   */
  static validatePercentagesInFormGroup(quantity: number, errorName: string) {
    return (formGroup: FormGroup): ValidationErrors => {
      let totalPercentage = 0;

      for (const key in formGroup.controls) {
        totalPercentage += formGroup.controls[key].value;
      }

      if (totalPercentage != quantity) return { [errorName]: true };

      return null;
    };
  }

  /**
   * Validates that a validated RFC field and a validated date field matches on the date.
   *
   * ### Example
   * ```typescript
   * private buildForm() {
   *      this.generalDataForm = this.formBuilder.group({
   *              name_company: [ '', [Validators.required] ],
   *              rfc: [ '', [Validators.required, this.rfcValidator ] ],
   *              place_constitution: [ '', [Validators.required] ],
   *              date_constitution: [ '', [Validators.required] ],
   *          }, { validators: CustomValidators.validateEntityRFCWithDate('rfc', 'date_constitution', 'rfcdateerror') });
   *      }
   * ```
   *
   */
  static validateEntityRFCWithDate(
    rfcControlName: string,
    dateControlName: string,
    errorName: string
  ) {
    return (control: FormGroup): ValidationErrors => {
      if (control.get(rfcControlName).invalid) return null;
      if (control.get(dateControlName).invalid) return null;

      let rfcRawDate = (control.get(rfcControlName).value as string).slice(
        3,
        9
      );
      // RFC format is => "XXXAAMMDDXXX", X is other digit.
      // Date is => "MM-DD-AA"
      const rfcYear = parseInt(rfcRawDate.slice(0, 2), 10);
      const rfcMonth = parseInt(rfcRawDate.slice(2, 4), 10) - 1;
      const rfcDay = parseInt(rfcRawDate.slice(4, 6), 10);

      // Crear un objeto Date con los componentes de fecha
      let rfcDate = new Date();
      rfcDate.setFullYear(1900 + rfcYear, rfcMonth, rfcDay);
      let dateConstitution = new Date(
        (control.get(dateControlName).value as string) + "T00:00"
      );

      if (rfcDate.toDateString() !== dateConstitution.toDateString())
        return { [errorName]: true };

      return null;
    };
  }

  /**
   * Validates that a validated RFC field and a validated date field matches on the date.
   *
   * ### Example
   * ```typescript
   * private buildForm() {
   *      this.generalDataForm = this.formBuilder.group({
   *              name_company: [ '', [Validators.required] ],
   *              rfc: [ '', [Validators.required, this.rfcValidator ] ],
   *              place_constitution: [ '', [Validators.required] ],
   *              date_constitution: [ '', [Validators.required] ],
   *          }, { validators: CustomValidators.validateEntityRFCWithDate('rfc', 'date_constitution', 'rfcdateerror') });
   *      }
   * ```
   *
   */
  static validateIndividualRFCWithDate(
    rfcControlName: string,
    dateControlName: string,
    errorName: string
  ) {
    return (control: FormGroup): ValidationErrors => {
      if (control.get(rfcControlName).invalid) return null;
      if (control.get(dateControlName).invalid) return null;

      let rfcRawDate = (control.get(rfcControlName).value as string).slice(
        4,
        10
      );
      // RFC format is => "XXXXAAMMDDXXX", X is other digit.
      // Date is => "MM-DD-AA"
      // Obtener los componentes de fecha del RFC
      const rfcYear = parseInt(rfcRawDate.slice(0, 2), 10);
      const rfcMonth = parseInt(rfcRawDate.slice(2, 4), 10) - 1;
      const rfcDay = parseInt(rfcRawDate.slice(4, 6), 10);

      // Crear un objeto Date con los componentes de fecha
      let rfcDate = new Date();
      rfcDate.setFullYear(1900 + rfcYear, rfcMonth, rfcDay);

      let dateConstitution = new Date(
        (control.get(dateControlName).value as string) + "T00:00"
      );
      // console.log(dateConstitution);
      if (rfcDate.toDateString() !== dateConstitution.toDateString())
        return { [errorName]: true };

      return null;

    };
  }

  static maxTenMillionValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const rawValue = control.value;
      const numericValue = parseFloat(rawValue.replace(/[^\d.-]/g, ""));
      const maxLimit = 10_000_000; // 10 millones
      const minLimit = 0.1;

      if (
        isNaN(numericValue) ||
        numericValue < minLimit ||
        numericValue > maxLimit
      ) {
        return {
          maxTenMillion: {
            valid: false,
            value: numericValue,
          },
        };
      }

      return null;
    };
  }

  static maxFifteenValidator(): unknown {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const maxLimit = 15;
  
      if (isNaN(value) || value > maxLimit) {
        return {
          maxFifteen: {
            valid: false,
            value: value,
          },
        };
      }
  
      return null;
    };
  }
}
