import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BasePage } from "src/app/pages/base-page/base-page";
import CustomValidators from "src/app/utils/validators/custom-validators";
import FormUtils from "src/app/utils/forms/form-utils";
import { HirDataService } from "src/app/services/hir-data.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "preanalysis-form",
  templateUrl: "./preanalysis-form.component.html",
  styleUrls: ["./preanalysis-form.component.scss"],
})
export class PreanalysisFormComponent extends BasePage implements OnInit {
  @Input() formInProgress: any = null;

  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onResize: EventEmitter<any> = new EventEmitter();

  public preanalysisForm: FormGroup;
  public formUtils = FormUtils;
  idiomaSeleccionado = "";
  company_typeArray = "";
  constructor(
    private formBuilder: FormBuilder,
    private injector: Injector,
    private HirDataService: HirDataService,
    private TranslateService: TranslateService
  ) {
    super(injector);
    this.buildForm();
  }

  ngOnInit() {
    this.idiomaSeleccionado = this.TranslateService.currentLang;
    this.HirDataService.LogIn().subscribe((response) => {
      // console.log(response);
      localStorage.setItem("token", response);
    });
    this.HirDataService.GetGiro().subscribe((data) => {
      console.log(data);
      this.company_typeArray = data;
    });
    // Load form's data when is provided
    if (
      this.formInProgress &&
      this.formInProgress["specificBusinessLine"] !== undefined
    )
      this.fillForm();
  }

  enableMenuSwipe(): boolean {
    return true;
  }

  private buildForm() {
    this.preanalysisForm = this.formBuilder.group({
      specificBusinessLine: ["", [Validators.required]],
      yearsOperation: ["", [Validators.required]],
      geographicParticipation: ["", [Validators.required]],
      mainCustomers: this.formBuilder.array(
        [this.createMainCustomer()],
        [
          Validators.required,
          CustomValidators.validatePercentagesInFormArray(
            100,
            "percentage",
            "maincustomerspercentage"
          ),
        ]
      ),
      quantity: ["", [Validators.required, Validators.min(1)]],
      using: ["", [Validators.required]],
      destinyResources: ["", [Validators.required]],
      percentageGroup: this.formBuilder.group(
        {
          percentageSalesInCash: [
            "",
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percentageGovernmentSales: [
            "",
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percentageCashSales: [
            "",
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percentageIntercompanySales: [
            "",
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percentageCreditSales: [
            "",
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percentageUnaccountedSales: [
            "",
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percentageExportSales: [
            "",
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
        },
        {
          validator: CustomValidators.validatePercentagesInFormGroup(
            100,
            "totalpercentage"
          ),
        }
      ),
      extraIncomePartners: ["", []],
      incomeOrigin: ["", []],
      buroPartners: [false, [Validators.required]],
      buroBackgroundPartners: ["", [Validators.required]],
      buroProblemOrigin: ["", []],
    });
  }

  private fillForm() {
    // Erase default empty controls for formArrays
    this.mainCustomersFormArray.clear();

    for (const [key, control] of Object.entries(
      this.preanalysisForm.controls
    )) {
      // Embed and array cases
      if (key === "percentageGroup") {
        // Special case for percentageGroup, because in backend this data isn't embebed
        for (const [key2, control2] of Object.entries(
          (control as FormGroup).controls
        )) {
          control2.setValue(this.formInProgress[key2]);
        }

        continue;
      } else if (key === "mainCustomers") {
        // Array case
        for (const mainCustomer of this.formInProgress[key]) {
          this.addMainCustomer({
            name: mainCustomer["name"],
            product: mainCustomer["product"],
            percentage: mainCustomer["percentage"],
          });
        }

        continue;
      }
      // Normal fill
      control.setValue(this.formInProgress[key]);
    }
  }

  public validateForm() {
    this.preanalysisForm.markAllAsTouched();
    let validationResult = this.preanalysisForm.valid;

    this.onValidation.emit({
      validationResult: validationResult,
      form: this.preanalysisForm.value,
    });
  }

  public previousStep() {
    this.onPreviousStep.emit();
  }

  /* BEGIN: Methods for Main Customer FormArray */

  private createMainCustomer(defaultData?: {
    name: string;
    product: string;
    percentage: number;
  }) {
    return this.formBuilder.group({
      name: [
        defaultData != null ? defaultData.name : "",
        [Validators.required],
      ],
      product: [
        defaultData != null ? defaultData.product : "",
        [Validators.required],
      ],
      percentage: [
        defaultData != null ? defaultData.percentage : 1,
        [Validators.required, Validators.min(1), Validators.max(100)],
      ],
    });
  }

  public addMainCustomer(defaultData?: {
    name: string;
    product: string;
    percentage: number;
  }) {
    this.mainCustomersFormArray.push(this.createMainCustomer(defaultData));
    this.onResize.emit();
  }

  public removeMainCustomer(index: number) {
    if (this.mainCustomersFormArray.length <= 1) return;

    this.mainCustomersFormArray.removeAt(index);
    this.onResize.emit();
  }

  public get mainCustomersFormArray(): FormArray {
    return this.preanalysisForm.get("mainCustomers") as FormArray;
  }

  /* END: Methods for Main Customer FormArray */
}
