import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";


const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
  },

  {
    path: "en",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
    path: "pass-reset",
    loadChildren: () =>
      import("./pages/pass-reset-page/pass-reset.module").then(
        (m) => m.PassResetPageModule
      ),
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./pages/privacy/privacy.module").then((m) => m.PrivacyPageModule),
  },
  {
    path: "conocenos",
    loadChildren: () =>
      import("./pages/conocenos/conocenos.module").then(
        (m) => m.ConocenosPageModule
      ),
  },
  {
    path: "vendors-prospect",
    loadChildren: () =>
      import("./pages/vendors-prospect/vendors-prospect.module").then(
        (m) => m.VendorsProspectPageModule
      ),
  },
  {
    path: "p-fisica",
    loadChildren: () =>
      import("./pages/forms/p-fisica/p-fisica.module").then(
        (m) => m.PFisicaPageModule
      ),
  },
  {
    path: "p-moral",
    loadChildren: () =>
      import("./pages/forms/p-moral/p-moral.module").then(
        (m) => m.PMoralPageModule
      ),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("./pages/payment/payment.module").then((m) => m.PaymentPageModule),
  },
  {
    path: "contracting",
    loadChildren: () =>
      import("./pages/contracting/contracting.module").then(
        (m) => m.ContractingPageModule
      ),
  },
  {
    path: "blog-detail",
    loadChildren: () =>
      import("./pages/blog-detail/blog-detail.module").then(
        (m) => m.BlogDetailPageModule
      ),
  },
  {
    path: 'warranty-integration',
    loadChildren: () => import('./pages/warranty-integration/warranty-integration.module').then( m => m.WarrantyIntegrationPageModule)
  },
  {
    path: 'validacion-enganche',
    loadChildren: () => import('./pages/validacion-enganche/validacion-enganche.module').then( m => m.ValidacionEnganchePageModule)
  },
  {
    path: 'validation-deuda',
    loadChildren: () => import('./pages/validation-deuda/validation-deuda.module').then( m => m.ValidationDeudaPageModule)
  },


  // {
  //   path: 'blog',
  //   loadChildren: () => import('./pages/blog/blog.module').then( m => m.BlogPageModule)
  // },

  // {
  //   path: 'message-calculator',
  //   loadChildren: () => import('./pages/message-calculator/message-calculator.module').then( m => m.MessageCalculatorPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "corrected",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
