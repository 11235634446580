import { Injectable } from "@angular/core";
import * as Parse from "parse";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// Interface para los datos obtenidos de la API Copomex
import { Datacopomex } from "src/app/services/interfaces/estudio-se-service-interfaces";

@Injectable({
  providedIn: "root",
})
export class SocioEconomicStudy extends Parse.Object {
  // Variables para la consulta a la API de Addres Copomex
  private urlPart1Copomex: string =
    "https://api.copomex.com/query/info_cp_geocoding/";
  private urlPart2Copomex: string = "?type=simplified&token=";
  // La key usada para la consulta en este caso es la que hice para las pruebas
  keyCopomex = "0fd6ae7e-3944-48e4-8dd6-46268cf7f05f";

  // Es otra API para codigos postales https://github.com/acrogenesis/API-Codigos-Postales   https://forcsec.com/api-codigos-postales

  // variable para tomar las respuesta de la consulta
  dataAddresFromPostalCode: Datacopomex;

  constructor(private http: HttpClient) {
    super("SocioEconomicStudy");
  }

  static getInstance() {
    return this;
  }

  createInCloud(payload: any = {}): Promise<any> {
    return Parse.Cloud.run("createStudy", payload);
  }

  load(params: any = {}): Promise<SocioEconomicStudy[]> {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query(SocioEconomicStudy);

      if (params.id) {
        query.equalTo("user", params.id);
      }
      if (params.email) {
        query.equalTo("email", params.email);
      }

      query.find().then(
        (data: SocioEconomicStudy[]) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  async getConfirmationContract (id: string): Promise<any> {
    const result = await Parse.Cloud.run("getConfirmation", { id });
    return !result;
  }

  // **************************************
  //    Funcion para Tomar los datos del user desde la base de datos
  // **************************************
  getDataUser(payload: any = {}): Promise<any> {
    return Parse.Cloud.run("get1Study", payload);
  }
  getStatusProcess(payload: any = {}): Promise<any> {
    return Parse.Cloud.run("getStatusProcess", payload);
  }
  verifySocioEconomicStudyEmail(payload: any = {}): Promise<any> {
    // console.log(payload);

    return Parse.Cloud.run("verifySocioEconomicStudyEmail", payload);
  }

  // getDataUserFromDB
  getDataUserBeneficiaries(payload: any = {}): Promise<any> {
    // console.log(payload);

    return Parse.Cloud.run("getDataUserFromDB", payload);
  }

  updateInCloud(payload): Promise<any> {
    return Parse.Cloud.run("updateStudy", payload);
  }

  updateByUserInCloud(payload): Promise<any> {
    return Parse.Cloud.run("updateStudyByUser", payload);
  }

  updateByEmailInCloud(payload): Promise<any> {
    return Parse.Cloud.run("updateStudyByEmail", payload);
  }

  // Funcion para buscarun juego y devolver el resultado de la busqueda
  copomexSearchAdress(postalCode: string): Observable<Datacopomex> {
    return this.http
      .get<Datacopomex>(
        `${this.urlPart1Copomex}${postalCode}${this.urlPart2Copomex}${this.keyCopomex}`
      )
      .pipe(map((results) => results));
  }

  get user() {
    return this.get("user");
  }

  set user(val) {
    this.set("user", val);
  }

  get canonical() {
    return this.get("canonical");
  }

  set canonical(val) {
    this.set("canonical", val);
  }

  get client_name() {
    return this.get("client_name");
  }

  set client_name(val) {
    this.set("client_name", val);
  }

  get last_name() {
    return this.get("last_name");
  }

  set last_name(val) {
    this.set("last_name", val);
  }

  get last_name2() {
    return this.get("last_name2");
  }

  set last_name2(val) {
    this.set("last_name2", val);
  }

  get birth() {
    return this.get("birth");
  }

  set birth(val) {
    this.set("birth", val);
  }

  get curp() {
    return this.get("curp");
  }

  set curp(val) {
    this.set("curp", val);
  }

  get gender() {
    return this.get("gender");
  }

  set gender(val) {
    this.set("gender", val);
  }

  get place_of_birth() {
    return this.get("place_of_birth");
  }

  set place_of_birth(val) {
    this.set("place_of_birth", val);
  }

  get client_nationality() {
    return this.get("client_nationality");
  }

  set client_nationality(val) {
    this.set("client_nationality", val);
  }

  get service() {
    return this.get("service");
  }

  set service(val) {
    this.set("service", val);
  }

  get value() {
    return this.get("value");
  }

  set value(val) {
    this.set("value", val);
  }

  get destiny() {
    return this.get("destiny");
  }

  set destiny(val) {
    this.set("destiny", val);
  }

  get id_type() {
    return this.get("id_type");
  }

  set id_type(val) {
    this.set("id_type", val);
  }

  get id_number() {
    return this.get("id_number");
  }

  set id_number(val) {
    this.set("id_number", val);
  }

  get id_type_file() {
    return this.get("id_type_file");
  }

  set id_type_file(val) {
    this.set("id_type_file", val);
  }

  get marital_status() {
    return this.get("marital_status");
  }

  set marital_status(val) {
    this.set("marital_status", val);
  }

  get marital_regime() {
    return this.get("marital_regime");
  }

  set marital_regime(val) {
    this.set("marital_regime", val);
  }

  get spouse_name() {
    return this.get("spouse_name");
  }

  set spouse_name(val) {
    this.set("spouse_name", val);
  }

  get spouselast_name() {
    return this.get("spouselast_name");
  }

  set spouselast_name(val) {
    this.set("spouselast_name", val);
  }

  get spouselast_name2() {
    return this.get("spouselast_name2");
  }

  set spouselast_name2(val) {
    this.set("spouselast_name2", val);
  }

  get spousebirth() {
    return this.get("spousebirth");
  }

  set spousebirth(val) {
    this.set("spousebirth", val);
  }

  get spousephone() {
    return this.get("spousephone");
  }

  set spousephone(val) {
    this.set("spousephone", val);
  }

  get spouseemail() {
    return this.get("spouseemail");
  }

  set spouseemail(val) {
    this.set("spouseemail", val);
  }

  get current_home() {
    return this.get("current_home");
  }

  set current_home(val) {
    this.set("current_home", val);
  }

  get current_home_price() {
    return this.get("current_home_price");
  }

  set current_home_price(val) {
    this.set("current_home_price", val);
  }

  get street() {
    return this.get("street");
  }

  set street(val) {
    this.set("street", val);
  }

  get no_outsite_inside() {
    return this.get("no_outsite_inside");
  }

  set no_outsite_inside(val) {
    this.set("no_outsite_inside", val);
  }

  get no_inside() {
    return this.get("no_inside");
  }

  set no_inside(val) {
    this.set("no_inside", val);
  }

  get postal_code() {
    return this.get("postal_code");
  }

  set postal_code(val) {
    this.set("postal_code", val);
  }
  /*
  get iddevelopment() {
    return this.get("postal_code");
  }

  set iddevelopment(val) {
    this.set("postal_code", val);
  }

  get iduser() {
    return this.get("postal_code");
  }

  set iduser(val) {
    this.set("postal_code", val);
  }
*/
  get suburb() {
    return this.get("suburb");
  }

  set suburb(val) {
    this.set("suburb", val);
  }

  get municipality() {
    return this.get("municipality");
  }

  set municipality(val) {
    this.set("municipality", val);
  }

  get state() {
    return this.get("state");
  }

  set state(val) {
    this.set("state", val);
  }

  get phone() {
    return this.get("phone");
  }

  set phone(val) {
    this.set("phone", val);
  }

  get cellphone() {
    return this.get("cellphone");
  }

  set cellphone(val) {
    this.set("cellphone", val);
  }

  get email() {
    return this.get("email");
  }

  set email(val) {
    this.set("email", val);
  }

  get invoice() {
    return this.get("invoice");
  }

  set invoice(val) {
    this.set("invoice", val);
  }

  get profesion() {
    return this.get("profesion");
  }

  set profesion(val) {
    this.set("profesion", val);
  }

  get current_occupation() {
    return this.get("current_occupation");
  }

  set current_occupation(val) {
    this.set("current_occupation", val);
  }

  get income() {
    return this.get("income");
  }

  set income(val) {
    this.set("income", val);
  }

  get expenses() {
    return this.get("expenses");
  }

  set expenses(val) {
    this.set("expenses", val);
  }

  get remainder() {
    return this.get("remainder");
  }

  set remainder(val) {
    this.set("remainder", val);
  }

  get type_of_income() {
    return this.get("type_of_income");
  }

  set type_of_income(val) {
    this.set("type_of_income", val);
  }

  get economic_dependents() {
    return this.get("economic_dependents");
  }

  set economic_dependents(val) {
    this.set("economic_dependents", val);
  }

  get comprobante() {
    return this.get("comprobante");
  }

  set comprobante(val) {
    this.set("comprobante", val);
  }

  get doctype() {
    return this.get("doctype");
  }

  set doctype(val) {
    this.set("doctype", val);
  }

  get esedatetime() {
    return this.get("esedatetime");
  }

  set esedatetime(val) {
    this.set("esedatetime", val);
  }

  get company_name() {
    return this.get("company_name");
  }

  set company_name(val) {
    this.set("company_name", val);
  }

  get turn_of_the_company() {
    return this.get("turn_of_the_company");
  }

  set turn_of_the_company(val) {
    this.set("turn_of_the_company", val);
  }

  get company_address() {
    return this.get("company_address");
  }

  set company_address(val) {
    this.set("company_address", val);
  }

  get puesto() {
    return this.get("puesto");
  }

  set puesto(val) {
    this.set("puesto", val);
  }

  get department() {
    return this.get("department");
  }

  set department(val) {
    this.set("department", val);
  }

  get position() {
    return this.get("position");
  }

  set position(val) {
    this.set("position", val);
  }

  get antiquity() {
    return this.get("antiquity");
  }

  set antiquity(val) {
    this.set("antiquity", val);
  }

  get antiquityyears() {
    return this.get("antiquityyears");
  }

  set antiquityyears(val) {
    this.set("antiquityyears", val);
  }

  get antiquitymonths() {
    return this.get("antiquitymonths");
  }

  set antiquitymonths(val) {
    this.set("antiquitymonths", val);
  }

  get direct_phone() {
    return this.get("direct_phone");
  }

  set direct_phone(val) {
    this.set("direct_phone", val);
  }

  get commutator() {
    return this.get("commutator");
  }

  set commutator(val) {
    this.set("commutator", val);
  }

  get email_work_usr() {
    return this.get("email_work_usr");
  }

  set email_work_usr(val) {
    this.set("email_work_usr", val);
  }

  get name_beneficiaries() {
    return this.get("name_beneficiaries");
  }

  set name_beneficiaries(val) {
    this.set("name_beneficiaries", val);
  }

  get last_name_beneficiaries() {
    return this.get("last_name_beneficiaries");
  }

  set last_name_beneficiaries(val) {
    this.set("last_name_beneficiaries", val);
  }

  get last_name2_beneficiaries() {
    return this.get("last_name2_beneficiaries");
  }

  set last_name2_beneficiaries(val) {
    this.set("last_name2_beneficiaries", val);
  }

  get percentage() {
    return this.get("percentage");
  }

  set percentage(val) {
    this.set("percentage", val);
  }

  get relationship() {
    return this.get("relationship");
  }

  set relationship(val) {
    this.set("relationship", val);
  }

  get phone_beneficiaries() {
    return this.get("phone_beneficiaries");
  }

  set phone_beneficiaries(val) {
    this.set("phone_beneficiaries", val);
  }

  get array_beneficiaries() {
    return this.get("array_beneficiaries");
  }

  set array_beneficiaries(val) {
    this.set("array_beneficiaries", val);
  }

  get reference_name1() {
    return this.get("reference_name1");
  }

  set reference_name1(val) {
    this.set("reference_name1", val);
  }

  get reference_last_name1() {
    return this.get("reference_last_name1");
  }

  set reference_last_name1(val) {
    this.set("reference_last_name1", val);
  }

  get reference_last_name2() {
    return this.get("reference_last_name2");
  }

  set reference_last_name2(val) {
    this.set("reference_last_name2", val);
  }

  get reference_relationship() {
    return this.get("reference_relationship");
  }

  set reference_relationship(val) {
    this.set("reference_relationship", val);
  }

  get reference_years_of_knowing() {
    return this.get("reference_years_of_knowing");
  }

  set reference_years_of_knowing(val) {
    this.set("reference_years_of_knowing", val);
  }

  get reference_phone1() {
    return this.get("reference_phone1");
  }

  set reference_phone1(val) {
    this.set("reference_phone1", val);
  }

  get reference_phone2() {
    return this.get("reference_phone2");
  }

  set reference_phone2(val) {
    this.set("reference_phone2", val);
  }

  get reference_email() {
    return this.get("reference_email");
  }

  set reference_email(val) {
    this.set("reference_email", val);
  }

  get reference2_name() {
    return this.get("reference2_name");
  }

  set reference2_name(val) {
    this.set("reference2_name", val);
  }

  get reference2_last_name() {
    return this.get("reference2_last_name");
  }

  set reference2_last_name(val) {
    this.set("reference2_last_name", val);
  }

  get reference2_last_name2() {
    return this.get("reference2_last_name2");
  }

  set reference2_last_name2(val) {
    this.set("reference2_last_name2", val);
  }

  get reference2_relationship() {
    return this.get("reference2_relationship");
  }

  set reference2_relationship(val) {
    this.set("reference2_relationship", val);
  }

  get reference2_years_of_knowing() {
    return this.get("reference2_years_of_knowing");
  }

  set reference2_years_of_knowing(val) {
    this.set("reference2_years_of_knowing", val);
  }

  get reference2_phone1() {
    return this.get("reference2_phone1");
  }

  set reference2_phone1(val) {
    this.set("reference2_phone1", val);
  }

  get reference2_phone2() {
    return this.get("reference2_phone2");
  }

  set reference2_phone2(val) {
    this.set("reference2_phone2", val);
  }

  get reference2_email() {
    return this.get("reference2_email");
  }

  set reference2_email(val) {
    this.set("reference2_email", val);
  }

  get pay() {
    return this.get("pay");
  }

  set pay(val) {
    this.set("pay", val);
  }

  get data_to_third_parties() {
    return this.get("data_to_third_parties");
  }

  set data_to_third_parties(val) {
    this.set("data_to_third_parties", val);
  }

  get advertising() {
    return this.get("advertising");
  }

  set advertising(val) {
    this.set("advertising", val);
  }

  get income2() {
    return this.get("income2");
  }

  set income2(val) {
    this.set("income2", val);
  }

  get current_mortgage_credit() {
    return this.get("current_mortgage_credit");
  }

  set current_mortgage_credit(val) {
    this.set("current_mortgage_credit", val);
  }

  get hitch() {
    return this.get("hitch");
  }

  set hitch(val) {
    this.set("hitch", val);
  }

  get childrens() {
    return this.get("childrens");
  }

  set childrens(val) {
    this.set("childrens", val);
  }

  get num_of_childrens() {
    return this.get("num_of_childrens");
  }

  set num_of_childrens(val) {
    this.set("num_of_childrens", val);
  }

  get documents() {
    return this.get("documents");
  }

  set documents(val) {
    this.set("documents", val);
  }

  get financing_by() {
    return this.get("financing_by");
  }

  set financing_by(val) {
    this.set("financing_by", val);
  }

  get type_financing() {
    return this.get("type_financing");
  }

  set type_financing(val) {
    this.set("type_financing", val);
  }

  get state_calc() {
    return this.get("state_calc");
  }

  set state_calc(val) {
    this.set("state_calc", val);
  }

  get financing_reason() {
    return this.get("financing_reason");
  }

  set financing_reason(val) {
    this.set("financing_reason", val);
  }

  get property_cost() {
    return this.get("property_cost");
  }

  set property_cost(val) {
    this.set("property_cost", val);
  }

  get possible_housing() {
    return this.get("possible_housing");
  }

  set possible_housing(val) {
    this.set("possible_housing", val);
  }

  get hitch_amount() {
    return this.get("hitch_amount");
  }

  set hitch_amount(val) {
    this.set("hitch_amount", val);
  }

  get amount() {
    return this.get("amount");
  }

  set amount(val) {
    this.set("amount", val);
  }

  get opening() {
    return this.get("opening");
  }

  set opening(val) {
    this.set("opening", val);
  }

  get taxes() {
    return this.get("taxes");
  }

  set taxes(val) {
    this.set("taxes", val);
  }

  get fee() {
    return this.get("fee");
  }

  set fee(val) {
    this.set("fee", val);
  }

  get appraisal() {
    return this.get("appraisal");
  }

  set appraisal(val) {
    this.set("appraisal", val);
  }

  get origination_expenses() {
    return this.get("origination_expenses");
  }

  set origination_expenses(val) {
    this.set("origination_expenses", val);
  }

  get client_investment() {
    return this.get("client_investment");
  }

  set client_investment(val) {
    this.set("client_investment", val);
  }

  get initial_monthly() {
    return this.get("initial_monthly");
  }

  set initial_monthly(val) {
    this.set("initial_monthly", val);
  }

  get credit_opening() {
    return this.get("credit_opening");
  }

  set credit_opening(val) {
    this.set("credit_opening", val);
  }

  get percentage_income() {
    return this.get("percentage_income");
  }

  set percentage_income(val) {
    this.set("percentage_income", val);
  }

  get income_co_owner() {
    return this.get("income_co_owner");
  }

  set income_co_owner(val) {
    this.set("income_co_owner", val);
  }

  get progress() {
    return this.get("progress");
  }

  set progress(val) {
    this.set("progress", val);
  }

  get current() {
    return this.get("current");
  }

  set current(val) {
    this.set("current", val);
  }

  get term() {
    return this.get("term");
  }

  set term(val) {
    this.set("term", val);
  }

  get prototype_ubication() {
    return this.get("prototype_ubication");
  }

  set prototype_ubication(val) {
    this.set("prototype_ubication", val);
  }

  get buy() {
    return this.get("buy");
  }

  set buy(val) {
    this.set("buy", val);
  }

  get writing_deadline() {
    return this.get("writing_deadline");
  }

  set writing_deadline(val) {
    this.set("writing_deadline", val);
  }

  get hitch_for() {
    return this.get("hitch_for");
  }

  set hitch_for(val) {
    this.set("hitch_for", val);
  }

  get hitch_hipotk() {
    return this.get("hitch_hipotk");
  }

  set hitch_hipotk(val) {
    this.set("hitch_hipotk", val);
  }

  get hitch_developer() {
    return this.get("hitch_developer");
  }

  set hitch_developer(val) {
    this.set("hitch_developer", val);
  }

  get hitch_hipotk_amount() {
    return this.get("hitch_hipotk_amount");
  }

  set hitch_hipotk_amount(val) {
    this.set("hitch_hipotk_amount", val);
  }

  get hitch_developer_amount() {
    return this.get("hitch_developer_amount");
  }

  set hitch_developer_amount(val) {
    this.set("hitch_developer_amount", val);
  }

  get hitch_hipotk_time() {
    return this.get("hitch_hipotk_time");
  }

  set hitch_hipotk_time(val) {
    this.set("hitch_hipotk_time", val);
  }

  get hitch_developer_time() {
    return this.get("hitch_developer_time");
  }

  set hitch_developer_time(val) {
    this.set("hitch_developer_time", val);
  }

  get advance_payment() {
    return this.get("advance_payment");
  }

  set advance_payment(val) {
    this.set("advance_payment", val);
  }

  get adult() {
    return this.get("adult");
  }

  set adult(val) {
    this.set("adult", val);
  }

  get rfc() {
    return this.get("rfc");
  }

  set rfc(val) {
    this.set("rfc", val);
  }

  get rfc_accept() {
    return this.get("rfc_accept");
  }

  set rfc_accept(val) {
    this.set("rfc_accept", val);
  }

  get credit() {
    return this.get("credit");
  }

  set credit(val) {
    this.set("credit", val);
  }

  get check_income() {
    return this.get("check_income");
  }

  set check_income(val) {
    this.set("check_income", val);
  }

  get true_data() {
    return this.get("true_data");
  }

  set true_data(val) {
    this.set("true_data", val);
  }

  get authorization() {
    return this.get("authorization");
  }

  set authorization(val) {
    this.set("authorization", val);
  }

  get authorization_data() {
    return this.get("authorization_data");
  }

  set authorization_data(val) {
    this.set("authorization_data", val);
  }

  get contact_method() {
    return this.get("contact_method");
  }

  set contact_method(val) {
    this.set("contact_method", val);
  }

  get contact_time() {
    return this.get("contact_time");
  }

  set contact_time(val) {
    this.set("contact_time", val);
  }

  get pdfsimulador() {
    return this.get("pdfsimulador");
  }

  set pdfsimulador(val) {
    this.set("pdfsimulador", val);
  }

  get entitySocio() {
    return this.get("entitySocio");
  }

  set entitySocio(val) {
    this.set("entitySocio", val);
  }

  get developmentName() {
    return this.get("developmentName");
  }

  set developmentName(val) {
    this.set("developmentName", val);
  }

  get reference2() {
    return this.get("reference2");
  }

  set reference2(val) {
    this.set("reference2", val);
  }

  get iddevelopment() {
    return this.get("iddevelopment");
  }

  set iddevelopment(val) {
    this.set("iddevelopment", val);
  }

  get reference() {
    return this.get("reference");
  }

  set reference(val) {
    this.set("reference", val);
  }
}

Parse.Object.registerSubclass("SocioEconomicStudy", SocioEconomicStudy);
