import { AUTO_STYLE } from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { IonContent, IonSlides } from "@ionic/angular";
import { SwiperComponent } from "swiper/angular";

@Component({
  selector: "health-data",
  templateUrl: "./health-data.component.html",
  styleUrls: [
    "./health-data.component.scss",
    "../../../pages/forms/form-style.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class HealthDataComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() status: string;
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onNextStepUpper: EventEmitter<any> = new EventEmitter();
  @Output() onResize = new EventEmitter<any>();
  @Output() scrollTop = new EventEmitter<any>();
  @ViewChild(IonSlides, { static: false }) slide: IonSlides;
  @ViewChild("swiper", { static: false }) stepperForm: SwiperComponent;
  public index = 0;
  constructor(public fb: FormBuilder) {}

  public formEmbedded: FormArray = new FormArray([]);

  ngOnInit() {}

  public SLIDEOPTS = {
    initialSlide: 0,
    speed: 400,
    direction: "horizontal",
    autoHeight: true,
    allowTouchMove: false,
  };

  public updateSwiper() {
    setTimeout(() => {
      this.stepperForm.swiperRef.updateAutoHeight();
      this.onResize.emit();
    }, 100);
  }

  public nextStep(res: any) {
    this.changeSlide(res.index);
    this.onResize.emit();
    this.scrollTop.emit();
  }

  public validateForms(res: any) {
    if (res.validationResult) {
      this.formEmbedded[res.index] = res.form;
      console.log(this.formEmbedded[res.index]);

      this.nextStep(res);
    }

    this.updateSwiper();
  }

  public joinForms() {
    const step1 = this.formEmbedded[0];
    const step2 = this.formEmbedded[1];
    const step3 = this.formEmbedded[2];

    const joinedGroup = this.fb.group({
      step1: step1,
      step2: step2,
      step3: step3,
    });
    return joinedGroup.value;
  }
  public changeSlide(index: number) {
    this.stepperForm.swiperRef.slideTo(index + 1);
    this.updateSwiper();
  }

  previousStepUpper() {
    this.onPreviousStep.emit();
    this.onResize.emit();
  }

  public nextStepUpper(res: any) {
    if (res.validationResult) {
      this.formEmbedded[res.index] = res.form;
      this.onNextStepUpper.emit({
        index: 6,
        form: this.joinForms(),
        validationResult: true,
      });
    }
  }

  public previousStep(res: any) {
    this.stepperForm.swiperRef.slideTo(res.index - 1);
    this.onResize.emit();
  }
}
