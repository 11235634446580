import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-explore-modal',
  templateUrl: './explore-modal.component.html',
  styleUrls: ['./explore-modal.component.scss']
})
export class ExploreModalComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Output() close = new EventEmitter<void>();
  
  constructor(public router: Router) { }

  ngOnInit(): void {}

  closeIfOutside(event: MouseEvent, isOutside: boolean): void {
    if (this.isOpen && isOutside) 
      this.closeModal();
    
    event.stopPropagation();
  }

  closeModal(): void {
    this.isOpen = false;
    this.close.emit(); 
  }

  goToCalculator(): void {
    this.router.navigate([`./mi-credito/calculadora`]);
    this.closeModal();
  }

  goToRegister(): void {
    this.router.navigate([`./profile`]);
    this.closeModal();
  }
}
