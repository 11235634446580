import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BasePage } from "src/app/pages/base-page/base-page";
import { relationships } from "src/app/utils/statics/dl-expediente";
import CustomValidators from "src/app/utils/validators/custom-validators";
@Component({
  selector: "app-references",
  templateUrl: "./references.component.html",
  styleUrls: ["./references.component.scss"],
})
export class ReferencesComponent extends BasePage implements OnInit {
  enableMenuSwipe(): boolean {
    throw new Error("Method not implemented.");
  }
  public referencesForm: FormArray;
  relationships = relationships;
  @Input() status: string;
  @Input() form: any;
  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onResize = new EventEmitter<any>();

  constructor(injector: Injector, public fb: FormBuilder) {
    super(injector);
  }

  ngOnInit() {
    this.referencesForm = this.fb.array([
      this.fb.group({
        name_personal_reference: ["", [Validators.required]],
        lastnamep_personal_reference: ["", [Validators.required]],
        lastnamem_personal_reference: ["", [Validators.required]],
        relation_personal_reference: ["", Validators.required],
        phone: [
          "",
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.minLength(10),
          ],
        ],
        email: [null, [Validators.email]],
        months_met_personal_reference: ["", [Validators.required, Validators.min(0), Validators.max(100)]],
      }),
      this.fb.group({
        name_personal_reference: ["", [Validators.required]],
        lastnamep_personal_reference: ["", [Validators.required]],
        lastnamem_personal_reference: ["", [Validators.required]],
        relation_personal_reference: ["", [Validators.required]],
        phone: [
          "",
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.minLength(10),
          ],
        ],
        email: [null, [ Validators.email]],
        months_met_personal_reference: ["", [Validators.required, Validators.min(0), Validators.max(100)]],
      }),
    ]);
    if (this.status == "inProgress" && this.form?.attributes?.references) {
      this.setReferencesForm();
    }
  }

  public getContactLabel(name: string) {
    if (name == "tel") {
      return this.translate.instant("PHONE");
    }
    if (name == "email") {
      return this.translate.instant("EMAIL");
    }
  }

  validateEmail() {
    if (this.referencesForm.controls[0].get("email").value != null) 
      if(this.referencesForm.controls[0].get("email").value.trim() == "")
        this.referencesForm.controls[0].get("email").setValue(null);

    if (this.referencesForm.controls[1].get("email").value != null) 
      if(this.referencesForm.controls[1].get("email").value.trim() == "")
        this.referencesForm.controls[1].get("email").setValue(null);
  }

  public validateForm() {
    this.validateEmail();
    this.referencesForm.markAllAsTouched();
    this.onResize.emit();
    let validationResult = this.referencesForm.valid;

    this.onValidation.emit({
      index: 3,
      validationResult: validationResult,
      form: this.referencesForm.value,
    });
    console.log("se emite");
  }

  public contactOption(value: string, field: string) {
    this.referencesForm.get(field).setValue("");
    if (value == "tel") {
      this.referencesForm
        .get(field)
        .setValidators([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ]);
    }
    if (value == "email") {
      this.referencesForm
        .get(field)
        .setValidators([Validators.required, Validators.email]);
      console.log(this.referencesForm.get(field).validator);
    }
  }

  public previousStep() {
    this.onPreviousStep.emit();
    console.log("se emite previous");
  }

  public isFieldInvalid(field: string, index: number) {
    return (
      this.referencesForm.at(1).get(field)?.touched &&
      this.referencesForm.at(1).get(field).invalid
    );
  }
  public isFieldInvalidForm0(field: string) {
    return (
      this.referencesForm.at(0).get(field)?.touched &&
      this.referencesForm.at(0).get(field).invalid
    );
  }

  public setReferencesForm() {
    console.log(this.referencesForm.controls);

    for (let i = 0; i < this.referencesForm.controls.length; i++) {
      this.referencesForm.controls[i]
        .get("name_personal_reference")
        .setValue(
          this.form.attributes.references[i]["name_personal_reference"]
        );
      this.referencesForm.controls[i]
        .get("lastnamep_personal_reference")
        .setValue(
          this.form.attributes.references[i]["lastnamep_personal_reference"]
        );
      this.referencesForm.controls[i]
        .get("lastnamem_personal_reference")
        .setValue(
          this.form.attributes.references[i]["lastnamem_personal_reference"]
        );
      this.referencesForm.controls[i]
        .get("relation_personal_reference")
        .setValue(
          this.form.attributes.references[i]["relation_personal_reference"]
        );
      this.referencesForm.controls[i]
        .get("phone")
        .setValue(this.form.attributes.references[i]["phone"]);
      this.referencesForm.controls[i]
        .get("email")
        .setValue(this.form.attributes.references[i]["email"]);
      this.referencesForm.controls[i]
        .get("months_met_personal_reference")
        .setValue(
          this.form.attributes.references[i]["months_met_personal_reference"]
        );
    }
  }
}
