import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GlobalFunctios {
  constructor() { }
  
  getOS(){
    let agent = navigator.appVersion;
    
    if (agent.indexOf("Win") != -1)
        return "Windows";
    if (agent.indexOf("Mac") != -1)
        return "Mac";
    if (agent.indexOf("X11") != -1) 
        return "UNIX";
    if (agent.indexOf("Linux") != -1)
        return "Linux";
    
  }


}