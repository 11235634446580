import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Aquí puedes agregar lógica para modificar la solicitud antes de enviarla
    // Por ejemplo, puedes agregar encabezados de autenticación o token de acceso

    const modifiedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    // console.log(localStorage.getItem("token"));
    return next.handle(modifiedRequest);
  }
}
