import { transition, trigger, query } from "@angular/animations";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ParseFile } from "src/app/services/parse-file-service";
import Swal from "sweetalert2";
import { DataFile } from "./data.interface";
export enum FileType {
  PDF = "application/pdf",
  IMAGES = "image/png, image/jpg, image/jpeg",
  COMPRESSED = "application/zip, application/x-rar-compressed",
  BOTH = "application/pdf, image/png, image/jpg, image/jpeg",
}

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent implements OnInit {
  /** Hidden input of type File */
  @ViewChild("fileInput", { static: true }) input: ElementRef;

  /** Label displayed in UI */
  @Input() label: string;
  /** Permited file types, by default PDFs */
  @Input() allowedFileType: FileType = FileType.PDF;
  /** ParseFile to load if exists */
  @Input() defaultParseFile: DataFile;

  /**
   * When file is uploaded or deleted
   * - [**null**] if deleted
   * - [**parseFile**] if uploaded o modified
   */
  @Output() onFileAction: EventEmitter<ParseFile> =
    new EventEmitter<ParseFile>();

  public isFileUploading: boolean;
  private parseFile: ParseFile;
  private maxFileSize = 35000000; // 35MB

  constructor(
    private alertCtrl: AlertController,
    private translateService: TranslateService
  ) {
    this.isFileUploading = false;
  }

  ngOnInit() {
    if (this.defaultParseFile != null) this.parseFile = this.defaultParseFile;
  }

  public onClick() {
    this.input.nativeElement.click();
  }

  public async onFileInputInteraction(event: any = null) {
    if (!event.target.files || event.target.files.length === 0) return;

    const filesSelected = event.target.files as FileList;

    for (let i = 0; i < filesSelected.length; i++) {
      console.log("vuelta", i);
      const fileSelected = filesSelected[i];

      // Realiza las comprobaciones de tipo y tamaño para cada archivo
      if (!this.allowedFileType.toString().includes(fileSelected.type)) {
        // Manejo de tipo de archivo no permitido
        continue; // O muestra un mensaje de error y salta a la siguiente iteración
      }

      if (fileSelected.size > this.maxFileSize) {
        // Manejo de archivo demasiado grande
        continue; // O muestra un mensaje de error y salta a la siguiente iteración
      }

      // Sube el archivo y emite un evento para cada archivo
      if (i > 0)
        await this.uploadFile(fileSelected, false, fileSelected.type, false);
      else await this.uploadFile(fileSelected, false, fileSelected.type, true);
    }
  }

  public async onRemove() {
    const confirm = await this.showConfirmModal();

    if (confirm) {
      this.parseFile = null;
      this.isFileUploading = false;
      this.onFileAction.emit(null);
    }
  }

  private showConfirmModal(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const str = await this.translateService.get(["OK", "CANCEL"]).toPromise();

      const confirm = await this.alertCtrl.create({
        header: "",
        message: await this.translateService
          .get("CONFIRM_DELETE_IMAGE")
          .toPromise(),
        buttons: [
          {
            text: str.CANCEL,
            role: "cancel",
            handler: () => resolve(false),
          },
          {
            text: str.OK,
            handler: () => resolve(true),
          },
        ],
        cssClass: "buttons-alert",
      });

      confirm.present();
    });
  }

  private async uploadFile(
    fileOrBase64: any,
    isBase64: boolean,
    type: string,
    bandera: boolean
  ) {
    try {
      this.isFileUploading = true;
      console.log("tipo de documentos", type);
      if (type === "application/pdf") {
        console.log("entre");
        this.parseFile = await ParseFile.uploadPDF(fileOrBase64, isBase64);
      } else {
        console.log("entre");
        this.parseFile = await ParseFile.upload(fileOrBase64, isBase64);
      }
      if (!this.defaultParseFile) {
        this.defaultParseFile = {
          bandera: bandera,
          parseFile: this.parseFile,
        };
      }
      this.defaultParseFile.bandera = bandera;
      this.defaultParseFile.parseFile = this.parseFile;
      this.isFileUploading = false;
      this.onFileAction.emit(this.defaultParseFile);
      console.log(this.parseFile);
    } catch (error) {
      this.isFileUploading = false;

      console.warn(error.message);
    }
  }
}
