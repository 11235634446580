import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import Swal from "sweetalert2";
import { GlobalFunctios } from "../../services/global-functions";
import { Email } from "src/app/services/email-service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  public languaje;
  public ruta: any;
  constructor(
    private router: Router,
    public modalController: ModalController,
    public globalFunctios: GlobalFunctios,
    private emailService: Email,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.languaje = localStorage.getItem("language");
    this.ruta = this.languaje == "en" ? "/en/" : "/";
  }

  redirectToHome() {
    //(click)="redirectToHome()
    this.router.navigate(["/"]);
  }

  // goTo(route: string) {
  //   this.router.navigate([route]);
  // }

  async onDirectionsButtonTouched() {
    let url: any;

    if (this.globalFunctios.getOS() == "Mac") {
      url = `https://maps.apple.com/?q=Desierto de los Leones No.46, planta baja, Col. San Ángel, Álvaro Obregón, C.P. 01000, Ciudad de México.&address=19.3494523,-99.1887675`;
      window.location.href = encodeURI(url);
    } else {
      url = `https://maps.google.com/maps?q=19.3494523,-99.1887675`;
      window.open(url);
    }
  }

  // async presentModal() {
  // const modal = await this.modalController.create({
  //   component: NoticePrivacyComponent
  // });
  // return await modal.present();
  // }

  async subscribe() {
    let email = document.getElementById("emailSubscribe") as HTMLInputElement;

    // Provisional hasta saber que es lo que va a hacer
    if (
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email.value
      )
    ) {
      this.emailService
        .contactHomeFooterEmail({ emailContacto: email.value })
        .then(
          async () => {
            Swal.fire({
              icon: "success",
              title: await this.translate.instant("THANKS_FOR_CHOOSING"),
              text: await this.translate.instant("WILL_CONTACT_YOU_SOON"),
            });
            email.value = "";
          },
          async (error) => {
            Swal.fire({
              icon: "error",
              title: "Oh no!",
              text: await this.translate.instant("ERROR_UNKNOWN"),
            });
            console.log(error);
          }
        );
    } else {
      Swal.fire({
        icon: "error",
        title: "Oh no!",
        text: await this.translate.instant("EMAIL_INVALID"),
      });
    }
  }
}
