import { FormArray, ValidationErrors } from "@angular/forms";

function validBenefPercentage( formArray: FormArray, control_name: string ) : ValidationErrors {
    let totalPercentage = 0;
    console.log(formArray)
    console.log(control_name)
    formArray.controls.forEach(control => {
      totalPercentage += control.get(control_name).value;
    });

    if ( totalPercentage != 100 ) return { 'maincustomerspercentage': true };

    return null;
}

function getMaxDate(): string {
    let todayDate = new Date();
    const year = todayDate.getFullYear();
    const month = String(todayDate.getMonth() + 1).padStart(2, '0');
    const day = String(todayDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function getMinDate(): string {
    let todayDate = new Date();
    const year = todayDate.getFullYear() - 100;
    const month = String(todayDate.getMonth() + 1).padStart(2, '0');
    const day = String(todayDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}



    export{
        validBenefPercentage,
        getMaxDate,
        getMinDate
    }