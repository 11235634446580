import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { relationships } from "src/app/utils/statics/dl-expediente";
import { beneficiariesForm } from "src/app/utils/statics/dl-expediente";
import { BasePage } from "src/app/pages/base-page/base-page";
import CustomValidators from "src/app/utils/validators/custom-validators";

@Component({
  selector: "app-beneficiaries",
  templateUrl: "./beneficiaries.component.html",
  styleUrls: ["./beneficiaries.component.scss"],
})
export class BeneficiariesComponent extends BasePage implements OnInit {
  enableMenuSwipe(): boolean {
    throw new Error("Method not implemented.");
  }
  @Input() status: string;
  @Input() form: any;
  @Output() newBenefEvent = new EventEmitter<any>();
  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onPreviousStep: EventEmitter<any> = new EventEmitter();
  @Output() onResize = new EventEmitter<any>();

  public beneficiariesForm: FormGroup;
  public totalBeneficiariesForms: any;
  public totalBenef: number = 0;
  public maxForms: boolean = false;
  public disableReduceButton: boolean = true;
  public percentage: number = 100;
  public disablePercentage: boolean;
  public totalPercentage: number = 0;
  relationships = relationships;

  constructor(injector: Injector, public fb: FormBuilder) {
    super(injector);
  }

  ngOnInit() {
    this.beneficiariesForm = this.fb.group({
      totalBeneficiariesForms: this.fb.array([], this.validBenefPercentage),
    });
    this.totalBeneficiariesForms = this.beneficiariesForm.get(
      "totalBeneficiariesForms"
    ) as FormArray;
    if (this.status == "inProgress" && this.form?.attributes?.beneficiaries) {
      this.setBeneficiariesForm();
    } else {
      this.addBenef();
    }
  }

  public addBenef() {
    this.totalBenef++;
    if (this.totalBenef === 4) {
      this.maxForms = true;
    }
    if (this.totalBenef > 1) {
      this.disableReduceButton = false;
      this.percentage = 0;
    } else {
      this.percentage = 100;
    }
    const beneficiaryInfo = this.fb.group({
      beneficiaries_name: ["", [Validators.required, Validators.minLength(3)]],
      last_name_p_beneficiaries: [
        "",
        [Validators.required, Validators.minLength(3)],
      ],
      last_name_m_beneficiaries: [
        "",
        [Validators.required, Validators.minLength(3)],
      ],
      beneficiary_relationship: ["", Validators.required],
      beneficiary_percent: [
        this.percentage,
        [Validators.required, Validators.min(1), Validators.max(100)],
      ],
      beneficiary_tel: ["", 
        [
          Validators.required, 
          Validators.minLength(10), 
          Validators.maxLength(10)
        ]
      ],
    });
    this.totalBeneficiariesForms.push(beneficiaryInfo);
    this.newBenefEvent.emit();
    this.onResize.emit();
  }

  public getPercentage() {}
  public removeBenef(index) {
    this.totalBeneficiariesForms.removeAt(index);
    this.totalBenef--;
    if (this.totalBenef !== 4) {
      this.maxForms = false;
    }

    if (this.totalBenef == 1) {
      this.disableReduceButton = true;
    } else {
    }
    this.newBenefEvent.emit();
    this.onResize.emit();
  }

  public isFieldInvalid2(field: string) {
    return (
      this.beneficiariesForm.get(field).touched &&
      this.beneficiariesForm.get(field).invalid
    );
  }

  public isFieldInvalid(benef: AbstractControl, field: string) {
    return benef.get(field)?.touched && benef.get(field).invalid;
  }

  public previousStep() {
    this.onPreviousStep.emit();
  }

  public validateForm() {
    console.log(this.beneficiariesForm);
    this.beneficiariesForm.markAllAsTouched();
    let validationResult = this.beneficiariesForm.valid;

    this.onValidation.emit({
      index: 2,
      validationResult: validationResult,
      form: this.beneficiariesForm.value,
    });
  }

  public sumMainCustomersFormArrayPercentages() {
    let sum = 0;

    this.totalBeneficiariesForms.controls.forEach((control) => {
      sum += control.get("client_percentage").value;
    });

    this.totalPercentage = sum;
    console.log(this.totalPercentage);
  }

  public validBenefPercentage(formArray: FormArray): ValidationErrors {
    let totalPercentage = 0;

    formArray.controls.forEach((control) => {
      totalPercentage += control.get("beneficiary_percent").value;
    });

    if (totalPercentage != 100) return { maincustomerspercentage: true };

    return null;
  }

  public setBeneficiariesForm() {
    for (
      let i = 0;
      i < this.form.attributes.beneficiaries.totalBeneficiariesForms.length;
      i++
    ) {
      this.addBenef();

      for (let index = 0; index < 6; index++) {
        this.totalBeneficiariesForms.controls[i]
          .get(beneficiariesForm[index])
          .setValue(
            this.form.attributes.beneficiaries.totalBeneficiariesForms[i][
              beneficiariesForm[index]
            ]
          );
      }
    }
  }
}
